import { makeStyles } from "tss-react/mui";

import { getSelectedTheme } from "../../utils/helpers";

export default makeStyles<{ contentLoaded: boolean }>()((
  theme,
  { contentLoaded },
) => {
  const selectedTheme = getSelectedTheme(theme.palette.mode);

  return {
    stickyHeader: {
      position: "sticky",
      top: "68px",
      zIndex: 14,
      background: `${selectedTheme.color.secondaryBackground} !important`,
    },
    infoSection: {
      padding: theme.spacing(2, 2, 0, 2),
      zIndex: 6,
    },
    divider: {
      marginTop: theme.spacing(2),
    },
    children: {
      padding: theme.spacing(2),
      height: contentLoaded ? "auto" : "calc(100% - 137px)",
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
    },
    backButton: {
      display: "inline-flex",
      alignItems: "center",
      textDecoration: "none",
      color: selectedTheme.color.gray,
      paddingBottom: theme.spacing(1),
      "&:hover": {
        color: selectedTheme.color.secondary,
      },
    },
  };
});

import ProjectPagesLayout from "../../../../components/project-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import CreditSupportTableView from "../../../../views/project/cost/credit-support/list";
import {
  getProjectCreditSupport,
  addProjectCreditSupport,
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getProjectCostsScheduleDetail,
} from "../../../../apis/project/cost";

export default function ProjectCostCreditSupportPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <CreditSupportTableView
          getProjectCreditSupport={getProjectCreditSupport}
          addProjectCreditSupport={addProjectCreditSupport}
          editProjectCreditSupport={editProjectCreditSupport}
          deleteProjectCreditSupport={deleteProjectCreditSupport}
          getProjectCostsScheduleDetail={getProjectCostsScheduleDetail}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}

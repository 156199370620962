import { REPORT_API_ROUTES } from "../../constants";
import { addParamsToUrl, replaceParams } from "./axios.helpers";
import {
  OutputReportPeriodicityType,
  ReportPerspective,
  ReportTerm,
  ReportType,
} from "../../interfaces";

export const getDealReportingUrl = (
  dealId: number,
  reportPerspective: ReportPerspective,
  reportType: ReportType,
  reportTerm: ReportTerm,
  periodicity?: OutputReportPeriodicityType,
  reportingType?: string,
) => {
  return replaceParams(REPORT_API_ROUTES.deal.get, {
    dealId,
    reportPerspective,
    reportType,
    periodicity,
    reportTerm,
    reportingType,
  });
};

export const getSponsorEquityBOL_ReportSummaryUrl = (
  dealId: number,
  force_unlevered: boolean,
) => {
  return addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityBOL_ReportSummary,
    { dealId },
    force_unlevered ? { force_unlevered } : {},
  );
};

export const getDeveloperReportSummaryUrl = (dealId: number) => {
  return replaceParams(
    REPORT_API_ROUTES.deal.getSponsorEquityBOL_ReportSummary,
    {
      dealId,
    },
  );
};

export const getSponsorEquityReportSummaryUrl = (
  dealId: number,
  force_unlevered: boolean,
) => {
  return addParamsToUrl(
    REPORT_API_ROUTES.deal.getSponsorEquityReportSummary,
    { dealId },
    force_unlevered ? { force_unlevered } : {},
  );
};

export const getTaxEquityReportSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getTaxEquityReportSummary, {
    dealId,
  });
};

export const getDebtReportSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getDebtReportSummary, {
    dealId,
  });
};

export const getConstructionDebtReportSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getConstructionDebtSummary, {
    dealId,
  });
};

export const getPortfolioCostScheduleUrl = (dealId: number) => {
  return replaceParams(
    REPORT_API_ROUTES.deal.getDealOutputPortfolioCostSchedule,
    {
      dealId,
    },
  );
};

export const getPortfolioCostSummaryUrl = (dealId: number) => {
  return replaceParams(
    REPORT_API_ROUTES.deal.getDealOutputPortfolioCostSummary,
    {
      dealId,
    },
  );
};

export const getTransferReportSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getTransferReportSummary, {
    dealId,
  });
};

export const getOutputPartnershipSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getOutputPartnershipSummary, {
    dealId,
  });
};

export const getOutputPartnershipSplitUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getOutputPartnershipSplit, {
    dealId,
  });
};

export const getNonTransferCashChartUrl = (
  dealId: number,
  is_percent: string,
) => {
  return replaceParams(REPORT_API_ROUTES.deal.getNonTransferCashChart, {
    dealId,
    is_percent,
  });
};

export const getDealDebtServiceChartUrl = (
  dealId: number,
  is_percent: string,
) => {
  return replaceParams(REPORT_API_ROUTES.deal.getDealDebtServiceChart, {
    dealId,
    is_percent,
  });
};

export const getDealSponsorCashEquityChartUrl = (
  dealId: number,
  is_percent: string,
) => {
  return replaceParams(REPORT_API_ROUTES.deal.getDealSponsorCashEquityChart, {
    dealId,
    is_percent,
  });
};

export const getDealTaxEquityChartUrl = (
  dealId: number,
  is_percent: string,
) => {
  return replaceParams(REPORT_API_ROUTES.deal.getDealTaxEquityChart, {
    dealId,
    is_percent,
  });
};

export const getOutputLessorSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getOutputLessorSummary, {
    dealId,
  });
};

export const getOutputLesseeSummaryUrl = (dealId: number) => {
  return replaceParams(REPORT_API_ROUTES.deal.getOutputLesseeSummary, {
    dealId,
  });
};

export const getOutputPortfolioSourcesAndUsesConstructionUrl = (
  dealId: number,
) => {
  return replaceParams(
    REPORT_API_ROUTES.deal.getOutputPortfolioSourcesAndUsesConstruction,
    {
      dealId,
    },
  );
};

export const getOutputPortfolioSourcesAndUsesCODUrl = (dealId: number) => {
  return replaceParams(
    REPORT_API_ROUTES.deal.getOutputPortfolioSourcesAndUsesCod,
    {
      dealId,
    },
  );
};

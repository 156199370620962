import React from "react";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import LogsIcon from "@mui/icons-material/Restore";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import ReportTableV2 from "../../../../../components/report-table-v2";
import useStyles from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import DetailsCard from "../../../../../components/details-card";
import DateSchedule from "../../../../../components/date-schedule";
import ProjectExpensesFormModal from "../../../../../components/project-expenses-form-modal";
import CurveFieldLabel from "../../../../../components/curve-field-label";
import ConditionalProtect from "../../../../../components/conditional-protect";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { useAPI, useDrawer, useLogs } from "../../../../../utils/hooks";
import { cn, numberToUSD } from "../../../../../utils/helpers";
import {
  IProjectExpense,
  IProjectTiming,
  IProjectExpenseForm,
  IProjectExpenseFormErrors,
  ILogsConfiguration,
  IReportTable,
} from "../../../../../interfaces";
import {
  USER_PERMISSIONS,
  PROJECT_EXPENSE_TYPE,
  PROJECT_EXPENSE_UNIT_TYPE,
  PROJECT_EXPENSE_INPUT_TYPE,
  TERM_END_POINT_TYPES_OPTIONS,
  PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  PROJECT_EXPENSE_UNIT_PERCENTAGE_OPTIONS,
  ESCALATION_METHOD_TYPE,
} from "../../../../../constants";

interface IProps {
  getProjectTiming: (projectId: number) => Promise<IProjectTiming[]>;
  getExpenseDetails: (
    projectId: number,
    expenseId: number,
  ) => Promise<IProjectExpense | undefined>;
  updateProjectExpense: (
    projectId: number,
    expenseId: number,
    form: IProjectExpenseForm,
  ) => Promise<IProjectExpense>;
  deleteExpense: (projectId: number, expenseId: number) => Promise<boolean>;
  getProjectExpenseComponents: (
    projectId: number,
    expenseId: number,
  ) => Promise<IReportTable>;
  downloadExpenseComponents: (
    projectId: number,
    expenseId: number,
  ) => Promise<void>;
}

export default function ProjectExpenseDetailsView({
  getProjectTiming,
  getExpenseDetails,
  updateProjectExpense,
  deleteExpense,
  getProjectExpenseComponents,
  downloadExpenseComponents,
}: IProps): JSX.Element {
  const styles = useStyles();

  const dispatch = useDispatch();

  const navigate = useNavigate();
  const { projectId, expenseId } = useParams();

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  const [expenseCurveToggle, setExpenseCurveToggle] =
    React.useState<boolean>(false);
  const [projectTiming, setProjectTiming] = React.useState<IProjectTiming>();
  const [expense, setExpense] = React.useState<IProjectExpense>();
  const [updateModalOpen, setUpdateModalOpen] = React.useState<boolean>(false);
  const [form, setForm] = React.useState<IProjectExpenseForm>(
    PROJECT_EXPENSE_FORM_DEFAULT_STATE,
  );

  const [projectExpenseComponents, setProjectExpenseComponents] =
    React.useState<IReportTable>();

  React.useEffect(() => {
    getExpenseDetailsCallAPI(projectId, expenseId).then((response) => {
      setExpense(response);
    });
    getProjectTimingCallAPI(Number(projectId)).then((response) => {
      response && setProjectTiming(response[0]);
    });

    handleGetProjectExpenseComponents(Number(projectId), Number(expenseId));
  }, [projectId, expenseId]);

  const { callAPI: getProjectTimingCallAPI } = useAPI((projectId: number) =>
    getProjectTiming(projectId),
  );

  const {
    callAPI: getExpenseDetailsCallAPI,
    loading: loadingExpenseDetails,
    errored: getExpenseDetailsFailed,
  } = useAPI(
    (projectId, expenseId) => getExpenseDetails(projectId, expenseId),
    { initialLoading: true },
  );

  const {
    callAPI: updateProjectExpenseCallAPI,
    loading: loadingUpdateProjectExpense,
    fieldErrors: updateProjectExpenseFieldErrors,
    setFieldErrors: setUpdateProjectExpenseFieldErrors,
  } = useAPI<IProjectExpense, IProjectExpenseFormErrors>(
    (projectId, expenseId, form) =>
      updateProjectExpense(projectId, expenseId, form),
  );

  const { callAPI: deleteExpenseCallAPI } = useAPI(
    (projectId, expenseId) => deleteExpense(projectId, expenseId),
    { setConfirmModalLoading: true },
  );

  const { callAPI: downloadExpenseComponentsCallAPI } = useAPI(
    (projectId, expenseId) => downloadExpenseComponents(projectId, expenseId),
  );

  const handleCloseUpdateExpenseModal = () => {
    setUpdateModalOpen(false);
    setForm(PROJECT_EXPENSE_FORM_DEFAULT_STATE);
    setUpdateProjectExpenseFieldErrors({});
  };

  const handleExpenseUpdate = async (form: IProjectExpenseForm) => {
    const expense = await updateProjectExpenseCallAPI(
      projectId,
      expenseId,
      form,
    );
    if (expense) {
      await handleGetProjectExpenseComponents(
        Number(projectId),
        Number(expenseId),
      );
      setExpense(expense);
      handleCloseUpdateExpenseModal();
    }
    return expense;
  };

  const getDefaultBaseYear = (cod?: string) => {
    return !isNaN(Date.parse(cod || ""))
      ? new Date(cod || "").getFullYear()
      : "";
  };

  const handleOpenUpdateExpenseModal = () => {
    if (!expense) {
      return;
    }
    setForm({
      escalation_method: expense.escalation_method || "",
      base_year: expense?.base_year || getDefaultBaseYear(projectTiming?.cod),
      acres: expense?.acres || "",
      expense: expense?.expense || "",
      rate: expense?.rate || "",
      expense_strip: expense?.expense_strip?.length
        ? expense?.expense_strip
        : new Array(projectTiming?.date_schedule?.length || 0).fill(0),
      escalator: expense?.escalator,
      expense_input_type: expense?.expense_input_type || "",
      start_input_method: expense?.start_input_method || "",
      term_start_point: expense?.term_start_point || "",
      expense_unit_type: expense?.expense_unit_type || null,
      cash_basis_lag: expense?.cash_basis_lag,
      start_date: expense?.start_date || "",
      term_input_method: expense?.term_input_method || "",
      term_end_point: expense?.term_end_point || "",
      term: expense?.term || "",
      type: expense?.type || "",
      name: expense?.name || "",
      dollars_per_yr_percentage: "",
      monthly_allocation_type: expense?.monthly_allocation_type || "",
    });
    setUpdateModalOpen(true);
  };

  const {
    callAPI: getProjectExpenseComponentsCallAPI,
    loading: loadingProjectExpenseComponents,
    errored: getProjectExpenseComponentsFailed,
  } = useAPI((projectId, expenseId) =>
    getProjectExpenseComponents(projectId, expenseId),
  );

  const handleDeleteExpense = async () => {
    const expense = await deleteExpenseCallAPI(
      Number(projectId),
      Number(expenseId),
    );

    if (expense) {
      navigate(`/project/${projectId}/pro-forma/expenses/`);
    }
  };

  const handleOnDeleteClick = async () => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Expense",
        description: "Are you sure you want to delete this expense?",
        onConfirm: handleDeleteExpense,
      }),
    );
  };

  const projectExpenseLogConfiguration: ILogsConfiguration = {
    id: Number(expense?.id),
    type: "projectexpense",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectExpenseLogConfiguration.type,
      projectExpenseLogConfiguration.id,
    );
  };

  const handleGetProjectExpenseComponents = async (
    projectId: number,
    expenseId: number,
  ) => {
    const components = await getProjectExpenseComponentsCallAPI(
      projectId,
      expenseId,
    );

    if (components) {
      setProjectExpenseComponents(components);
    }
  };

  const handleDownloadExpenseComponents = async () => {
    await downloadExpenseComponentsCallAPI(
      Number(projectId),
      Number(expenseId),
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingExpenseDetails}
        error={getExpenseDetailsFailed}
      >
        <Box className={cn("flex justify-between gap-4 mb-4")}>
          <IconButton
            classes={{ root: styles.classes.iconButton }}
            onClick={() => window.history.back()}
          >
            <ArrowBackIcon />
          </IconButton>
          <Box className={cn("flex gap-4")}>
            <IconButton onClick={handleOnOpenLogs}>
              <LogsIcon />
            </IconButton>
            <ConditionalProtect
              type="project"
              permission={USER_PERMISSIONS.PROJECTS_CRUD}
            >
              <Button
                canOpenUpgrade
                startIcon={<EditIcon />}
                label="Edit"
                btnType="primary"
                onClick={handleOpenUpdateExpenseModal}
              />
              <Button
                canOpenUpgrade
                label="Delete"
                btnType="danger"
                onClick={handleOnDeleteClick}
              />
            </ConditionalProtect>
          </Box>
        </Box>

        <Box className={cn("grid md:grid-cols-2 gap-4")}>
          <DetailsCard
            heading=""
            dataPw="expense-details"
            autoHeight
            sections={[
              {
                fields: [
                  {
                    label: "Type",
                    value: {
                      text:
                        PROJECT_EXPENSE_TYPE[
                          expense?.type as keyof typeof PROJECT_EXPENSE_TYPE
                        ] || "",
                    },
                  },
                  {
                    label: "Name",
                    value: {
                      text: expense?.name || "",
                    },
                  },
                  {
                    label: "Input Type",
                    value: {
                      text:
                        PROJECT_EXPENSE_INPUT_TYPE[
                          expense?.expense_input_type as keyof typeof PROJECT_EXPENSE_INPUT_TYPE
                        ] || "",
                    },
                  },
                  ...(expense?.expense_input_type === "NUMBER"
                    ? [
                        {
                          label: "Unit Type",
                          value: {
                            text:
                              PROJECT_EXPENSE_UNIT_TYPE[
                                expense?.expense_unit_type as keyof typeof PROJECT_EXPENSE_UNIT_TYPE
                              ] || "",
                          },
                        },
                        // This will be shown only when, unit type is DOLPERACRE
                        ...(expense?.expense_unit_type === "DOLPERACRE"
                          ? [
                              {
                                label: "Acres",
                                value: {
                                  text: expense?.acres || "",
                                },
                              },
                            ]
                          : []),
                        {
                          label: "Input",
                          value: {
                            text: PROJECT_EXPENSE_UNIT_PERCENTAGE_OPTIONS.includes(
                              expense?.expense_unit_type || "",
                            )
                              ? (expense?.expense || "") + "%"
                              : numberToUSD.format(expense?.expense || 0),
                          },
                        },

                        {
                          label: "Rate",
                          value: { text: expense?.rate + "%" || "" },
                        },
                        ...(expense.escalator !== undefined &&
                        expense.escalator !== null
                          ? [
                              {
                                label: "Escalator",
                                value: {
                                  text:
                                    expense?.escalator !== undefined &&
                                    expense?.escalator !== null
                                      ? String(expense?.escalator) + "%"
                                      : "",
                                },
                              },
                              {
                                label: "Escalation Method",
                                value: {
                                  text:
                                    ESCALATION_METHOD_TYPE[
                                      expense?.escalation_method as keyof typeof ESCALATION_METHOD_TYPE
                                    ] || "",
                                },
                              },
                              {
                                label: "Escalator Base Year",
                                value: {
                                  text: expense?.base_year || null,
                                },
                              },
                            ]
                          : []),
                        {
                          label: "Start Date",
                          value: { text: expense?.start_date || "" },
                        },
                        expense.term_input_method === "YR"
                          ? {
                              label: "Term",
                              value: { text: expense.term || "" },
                            }
                          : {
                              label: "Term End Point",
                              value: {
                                text: TERM_END_POINT_TYPES_OPTIONS.find(
                                  (option) =>
                                    option.value === expense.term_end_point,
                                )?.label,
                              },
                            },

                        {
                          label: "End Date",
                          value: { text: expense?.end_date || "" },
                        },
                      ]
                    : []),
                  {
                    label: "Cash Lag (Months)",
                    value: {
                      text:
                        expense?.cash_basis_lag !== null
                          ? expense?.cash_basis_lag
                          : "N/A",
                    },
                  },
                  ...(expense?.expense_input_type === "STRIP"
                    ? [
                        {
                          label: (
                            <CurveFieldLabel
                              label="Schedule"
                              primaryAction={setExpenseCurveToggle}
                              value={expense?.expense_strip}
                              toggled={expenseCurveToggle}
                            />
                          ),
                          value: {
                            text: (
                              <DateSchedule
                                curve={expense?.expense_strip || []}
                                dateSchedule={
                                  projectTiming?.date_schedule || []
                                }
                                inUSD
                                showTotal={{ total: expense.schedule_total }}
                                toggle={expenseCurveToggle}
                                handleToggle={setExpenseCurveToggle}
                              />
                            ),
                          },
                        },
                      ]
                    : []),
                ],
              },
            ]}
          />
        </Box>
      </ViewWrapper>
      <ViewWrapper
        loading={loadingProjectExpenseComponents}
        error={getProjectExpenseComponentsFailed}
      >
        {projectExpenseComponents ? (
          <Box className={cn("mt-4")}>
            <ReportTableV2
              data={projectExpenseComponents}
              download={handleDownloadExpenseComponents}
            />
          </Box>
        ) : null}
      </ViewWrapper>
      <ProjectExpensesFormModal
        open={updateModalOpen}
        dateSchedule={projectTiming?.date_schedule || []}
        headerLabel="Update Expense"
        loading={loadingUpdateProjectExpense}
        formErrors={updateProjectExpenseFieldErrors}
        setFormErrors={setUpdateProjectExpenseFieldErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateExpenseModal}
        onConfirm={handleExpenseUpdate}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectExpenseLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectExpenseLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}

import DealPagesLayout from "../../../../components/deal-pages-layout";
import DealOutputPagesLayout from "../../../../components/deal-output-page-layout";
import DealOutputPortfolioSourcesAndUsesSummaryView from "../../../../views/deal/output/portfolio/sources-and-uses";
import {
  getOutputPortfolioSourcesAndUsesCOD,
  getOutputPortfolioSourcesAndUsesConstruction,
} from "../../../../apis/deal/portfolio/sources-and-uses";

export default function DealOutputPortfolioPage(): JSX.Element {
  return (
    <DealPagesLayout>
      <DealOutputPagesLayout>
        <DealOutputPortfolioSourcesAndUsesSummaryView
          getOutputPortfolioSourcesAndUsesConstruction={
            getOutputPortfolioSourcesAndUsesConstruction
          }
          getOutputPortfolioSourcesAndUsesCOD={
            getOutputPortfolioSourcesAndUsesCOD
          }
        />
      </DealOutputPagesLayout>
    </DealPagesLayout>
  );
}

import TabbedLayout from "../../../../components/tabbed-layout";
import ProjectPagesLayout from "../../../../components/project-pages-layout";
import ProjectCostInputDetailView from "../../../../views/project/cost/cost-inputs/details";
import {
  deleteProjectCostItem,
  editProjectCostItem,
  getProjectCostItemComponents,
  downloadProjectCostItemComponents,
  getProjectCostItem,
} from "../../../../apis/project/cost";

export default function ProjectCostDetailPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <ProjectCostInputDetailView
          getProjectCostItem={getProjectCostItem}
          editProjectCostItem={editProjectCostItem}
          deleteProjectCostItem={deleteProjectCostItem}
          getProjectCostItemComponents={getProjectCostItemComponents}
          downloadProjectCostItemComponents={downloadProjectCostItemComponents}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TableSortLabel from "@mui/material/TableSortLabel";
import LogsIcon from "@mui/icons-material/Restore";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useStyle from "./styles";
import Logs from "../../../../../components/logs";
import Button from "../../../../../components/button";
import IconButton from "../../../../../components/icon-button";
import LogsWrapper from "../../../../../components/logs-wrapper";
import ViewWrapper from "../../../../../components/view-wrapper";
import PopoverMenu from "../../../../../components/popover-menu";
import StickyTableCell from "../../../../../components/sticky-table-cell";
import ConditionalProtect from "../../../../../components/conditional-protect";
import ReportTableV2 from "../../../../../components/report-table-v2";
import ProjectCreditSupportFormModal from "../../../../../components/project-credit-support-form-modal";
import { setDeleteModalPropsAction } from "../../../../../utils/redux/slices";
import { downloadProjectCostScheduleDetail } from "../../../../../apis/project/cost";
import {
  useAPI,
  useAppSelector,
  useLogs,
  useDrawer,
} from "../../../../../utils/hooks";
import {
  cn,
  getTableColumnAccordingToStatus,
  numberToUSD,
  sortArrayOfObjects,
} from "../../../../../utils/helpers";
import {
  USER_PERMISSIONS,
  PROJECT_OBLICATION_TYPE,
  PROJECT_CREDIT_SUPPORT_COST_METHOD,
  DATE_SELECTION_TYPES,
  PROJECT_COST_SCHEDULE_REPORT_TYPE,
} from "../../../../../constants";
import {
  ITableColumn,
  ServerPaginatedResponse,
  ITableSort,
  IProjectCreditSupport,
  IAddProjectCreditSupportForm,
  IUpdateProjectCreditSupportForm,
  IAddProjectCreditSupportFormErrors,
  IUpdateProjectCreditSupportFormErrors,
  IReportTable,
  ILogsConfiguration,
} from "../../../../../interfaces";

export const CREDIT_SUPPORT_COLUMNS: ITableColumn[] = [
  { id: "obligation_type", label: "Type", minWidth: 80, align: "left" },
  { id: "name", label: "Name", minWidth: 80, align: "left" },
  {
    id: "credit_support_dollar_per_wac",
    label: "Amount ($/kW-ac)",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_dollar_amount",
    label: "Amount ($)",
    minWidth: 80,
    align: "left",
  },
  {
    id: "start_date_type",
    label: "Start Date Type",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_start_date",
    label: "Start Date",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_start_point",
    label: "Start Point",
    minWidth: 80,
    align: "left",
  },
  {
    id: "end_date_type",
    label: "End Date Type",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_end_date",
    label: "End Date",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_end_point",
    label: "End Point",
    minWidth: 80,
    align: "left",
  },
  {
    id: "credit_support_method",
    label: "Security Type",
    minWidth: 80,
    align: "left",
  },
  {
    id: "letter_of_credit_fee",
    label: "Fee (%)",
    minWidth: 80,
    align: "left",
  },
];

interface IProps {
  getProjectCreditSupport: (
    projectId: number,
  ) => Promise<ServerPaginatedResponse<IProjectCreditSupport[]>>;
  addProjectCreditSupport: (
    projectId: number,
    form: IAddProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport>;
  editProjectCreditSupport: (
    projectId: number,
    creditSupportId: number,
    form: IUpdateProjectCreditSupportForm,
  ) => Promise<IProjectCreditSupport>;
  deleteProjectCreditSupport: (
    projectId: number,
    creditSupportId: number,
  ) => Promise<boolean>;
  getProjectCostsScheduleDetail: (
    projectId: number,
    cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
  ) => Promise<IReportTable>;
}

export default function CreditSupportTableView({
  getProjectCreditSupport,
  addProjectCreditSupport,
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getProjectCostsScheduleDetail,
}: IProps): JSX.Element {
  const styles = useStyle();

  const dispatch = useDispatch();
  const { ctrlPressed } = useAppSelector((s) => s.common);
  const { currentProject } = useAppSelector((s) => s.project);

  const navigate = useNavigate();
  const { projectId } = useParams();

  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);
  const [projectCostScheduleDetail, setProjectCostScheduleDetail] =
    React.useState<IReportTable>();
  const [creditSupport, setCreditSupport] = React.useState<
    IProjectCreditSupport[]
  >([]);
  const [addCreditSupportModalOpen, setAddCreditSupportModalOpen] =
    React.useState<boolean>(false);
  const [updateCreditSupportModalOpen, setUpdateCreditSupportModalOpen] =
    React.useState<boolean>(false);
  const [selectedCreditSupportToEdit, setSelectedCreditSupportToEdit] =
    React.useState<number>();
  const [form, setForm] = React.useState<
    IAddProjectCreditSupportForm | IUpdateProjectCreditSupportForm
  >({
    obligation_type: "",
    name: "",
    credit_support_method: "",
    input_type: "",
    credit_support_amount: "",
    start_date_type: "",
    credit_support_start_date: null,
    credit_support_start_point: "",
    end_date_type: "",
    credit_support_end_date: null,
    credit_support_end_point: "",
    letter_of_credit_fee: "",
    yearfrac_convention: "",
  });
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const {
    loadMoreLogs,
    loadingLogs,
    logs,
    onCloseLogs,
    onOpenLogs,
    pagination,
  } = useLogs();

  const { handleCloseDrawer, handleOpenDrawer, isDrawerOpen } = useDrawer({
    onOpen: onOpenLogs,
    onClose: onCloseLogs,
  });

  React.useEffect(() => {
    getProjectCreditSupportCallAPI(Number(projectId)).then((response) => {
      response && setCreditSupport(response.results);
    });
  }, [projectId]);

  React.useEffect(() => {
    getProjectCostScheduleDetailCallAPI(
      Number(projectId),
      "credit_support",
    ).then((res) => {
      res && setProjectCostScheduleDetail(res);
    });
  }, [projectId, creditSupport]);

  const {
    callAPI: getProjectCreditSupportCallAPI,
    errored: getCreditSupportFailed,
    loading: loadingCreditSupport,
  } = useAPI((projectId: number) => getProjectCreditSupport(projectId), {
    initialLoading: true,
  });

  const {
    callAPI: getProjectCostScheduleDetailCallAPI,
    errored: getCostScheduleDetailFailed,
    loading: loadingCostScheduleDetail,
  } = useAPI(
    (
      projectId: number,
      cost_type: keyof typeof PROJECT_COST_SCHEDULE_REPORT_TYPE,
    ) => getProjectCostsScheduleDetail(projectId, cost_type),
  );

  const {
    callAPI: addProjectCreditSupportCallAPI,
    fieldErrors: addProjectCreditSupportFormErrors,
    setFieldErrors: setAddProjectCreditSupportFormErrors,
    loading: addProjectCreditSupportLoading,
  } = useAPI<IProjectCreditSupport, IAddProjectCreditSupportFormErrors>(
    (projectId: number, form: IAddProjectCreditSupportForm) =>
      addProjectCreditSupport(projectId, form),
  );

  const {
    callAPI: updateProjectCreditSupportCallAPI,
    fieldErrors: updateProjectCreditSupportFormErrors,
    setFieldErrors: setUpdateProjectCreditSupportFormErrors,
    loading: updateProjectCreditSupportLoading,
  } = useAPI<IProjectCreditSupport, IUpdateProjectCreditSupportFormErrors>(
    (
      projectId: number,
      creditSupportId: number,
      form: IUpdateProjectCreditSupportForm,
    ) => editProjectCreditSupport(projectId, creditSupportId, form),
  );

  const { callAPI: deleteProjectCreditSupportCallAPI } = useAPI(
    (projectId: number, creditSupportId: number) =>
      deleteProjectCreditSupport(projectId, creditSupportId),
  );

  const handleOnAddCreditSupport = async (
    form: IAddProjectCreditSupportForm,
  ) => {
    const credit_support = await addProjectCreditSupportCallAPI(
      Number(projectId),
      form,
    );
    if (credit_support) {
      getProjectCreditSupportCallAPI(Number(projectId)).then((response) => {
        response && setCreditSupport(response.results);
      });
    }
    return credit_support;
  };

  const handleOnUpdateCreditSupport = async (
    form: IUpdateProjectCreditSupportForm,
  ) => {
    const credit_support = await updateProjectCreditSupportCallAPI(
      Number(projectId),
      Number(selectedCreditSupportToEdit),
      form,
    );

    getProjectCreditSupportCallAPI(Number(projectId)).then((response) => {
      response && setCreditSupport(response.results);
    });

    return credit_support;
  };

  const handleOpenAddCreditSupportModal = () => {
    setForm((prevState) => ({
      ...prevState,
      obligation_type: "",
      credit_support_method: "",
      input_type: "",
      credit_support_amount: "",
      start_date_type: "",
      credit_support_start_date: null,
      credit_support_start_point: "",
      end_date_type: "MD",
      credit_support_end_date: null,
      credit_support_end_point: "COD",
      letter_of_credit_fee: "",
      yearfrac_convention: "",
    }));
    setAddCreditSupportModalOpen(true);
  };

  const handleCloseAddCreditSupportModal = () => {
    setAddCreditSupportModalOpen(false);
  };

  const handleOpenUpdateCreditSupportModal = (id: number) => {
    const selectedCreditSupport = creditSupport.find((e) => e.id === id);
    if (selectedCreditSupport) {
      const {
        obligation_type,
        name,
        credit_support_method,
        input_type,
        credit_support_amount,
        start_date_type,
        credit_support_start_date,
        credit_support_start_point,
        end_date_type,
        credit_support_end_date,
        credit_support_end_point,
        letter_of_credit_fee,
        yearfrac_convention,
      } = selectedCreditSupport;
      setSelectedCreditSupportToEdit(selectedCreditSupport.id);
      const formData: IUpdateProjectCreditSupportForm = {
        obligation_type,
        name,
        credit_support_method,
        input_type,
        credit_support_amount,
        start_date_type,
        credit_support_start_date,
        credit_support_start_point,
        end_date_type,
        credit_support_end_date,
        credit_support_end_point,
        letter_of_credit_fee,
        yearfrac_convention,
      };
      setForm(formData);
      setUpdateCreditSupportModalOpen(true);
    }
  };

  const handleDeleteCreditSupport = async (id: number) => {
    const credit_support = await deleteProjectCreditSupportCallAPI(
      Number(projectId),
      id,
    );

    if (credit_support) {
      setCreditSupport((prev) => prev.filter((e) => e.id !== id));
    }
  };

  const handleOnDeleteClick = async (id: number) => {
    dispatch(
      setDeleteModalPropsAction({
        open: true,
        title: "Delete Credit Support",
        description: "Are you sure you want to delete this credit support?",
        onConfirm: () => handleDeleteCreditSupport(id),
      }),
    );
  };

  const handleCloseUpdateCreditSupportModal = () => {
    setUpdateCreditSupportModalOpen(false);
  };

  const goToCreditSupport = (id: number) => {
    const pathToUse = `/project/${projectId}/cost-credit-support/${id}`;
    if (ctrlPressed) {
      window.open(pathToUse, "_blank");
      return;
    }
    navigate(pathToUse);
  };

  const sortRows = (orderBy: string) => {
    if (orderBy === sortTable.orderBy) {
      setSortTable({
        orderBy,
        order: sortTable.order === "asc" ? "desc" : "asc",
      });
    } else {
      setSortTable({
        orderBy,
        order: "asc",
      });
    }
  };

  const createTableData = (credit_supports: IProjectCreditSupport[]) => {
    return credit_supports.map((credit_support) => {
      const {
        id,
        name,
        obligation_type,
        credit_support_dollar_amount,
        credit_support_dollar_per_wac,
        start_date_type,
        credit_support_start_date,
        credit_support_start_point,
        end_date_type,
        credit_support_end_date,
        credit_support_end_point,
        credit_support_method,
        letter_of_credit_fee,
        input_type,
      } = credit_support;

      return {
        id,
        name,
        obligation_type,
        credit_support_dollar_amount,
        credit_support_dollar_per_wac,
        start_date_type,
        credit_support_start_date: credit_support_start_date ?? "N/A",
        credit_support_start_point:
          start_date_type === "SPD" ? "N/A" : credit_support_start_point,
        end_date_type,
        credit_support_end_date: credit_support_end_date ?? "N/A",
        credit_support_end_point:
          end_date_type === "SPD" ? "N/A" : credit_support_end_point,
        credit_support_method,
        letter_of_credit_fee,
        input_type,
      };
    });
  };

  const creditSupportTableData = React.useMemo(
    () => createTableData(creditSupport),
    [creditSupport],
  );

  const sortedRows = React.useMemo(
    () =>
      sortArrayOfObjects(
        creditSupportTableData,
        sortTable?.orderBy,
        sortTable?.order,
      ),
    [sortTable, creditSupportTableData],
  );

  const downloadCreditSupportCostReport = async () => {
    setIsDownloading(true);
    await downloadProjectCostScheduleDetail(
      Number(projectId),
      "credit_support",
    ).catch(() => null);
    setIsDownloading(false);
  };

  const projectLogConfiguration: ILogsConfiguration = {
    id: "",
    parentId: projectId,
    type: "projectcreditsupport",
  };

  const handleOnOpenLogs = () => {
    handleOpenDrawer(
      projectLogConfiguration.type,
      projectLogConfiguration.id,
      projectLogConfiguration.parentId,
    );
  };

  return (
    <>
      <ViewWrapper
        loading={loadingCreditSupport}
        error={getCreditSupportFailed}
      >
        <Box className={styles.classes.buttonContainer}>
          <IconButton title="Open Logs" onClick={handleOnOpenLogs}>
            <LogsIcon />
          </IconButton>
          <ConditionalProtect
            type="project"
            permission={USER_PERMISSIONS.PROJECTS_CRUD}
          >
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Credit Support"
              onClick={handleOpenAddCreditSupportModal}
            />
          </ConditionalProtect>
        </Box>

        <Box className={cn("flex flex-col gap-4")}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer classes={{ root: styles.classes.tableContainer }}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead className={styles.classes.header}>
                  <TableRow>
                    {getTableColumnAccordingToStatus(
                      CREDIT_SUPPORT_COLUMNS,
                      currentProject?.status as string,
                    ).map((column, idx) => {
                      if (column.id === "action") {
                        return (
                          <StickyTableCell
                            key={idx}
                            direction="right"
                            fixedColumnWidth={80}
                            align="center"
                            highZIndex
                          >
                            {column.label}
                          </StickyTableCell>
                        );
                      }
                      return (
                        <TableCell
                          key={idx}
                          align={column.align as "left"}
                          style={{ minWidth: column.minWidth }}
                        >
                          <TableSortLabel
                            active={sortTable.orderBy === column.id}
                            direction={
                              sortTable.orderBy === column.id
                                ? sortTable.order
                                : "asc"
                            }
                            onClick={() => sortRows(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {creditSupport.length === 0 ? (
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={
                          getTableColumnAccordingToStatus(
                            CREDIT_SUPPORT_COLUMNS,
                            currentProject?.status as string,
                          ).length
                        }
                      >
                        There are no credit support in place currently, please
                        add one.
                      </TableCell>
                    </TableRow>
                  ) : null}
                  {sortedRows.map((credit_support, idx) => {
                    return (
                      <TableRow
                        hover
                        key={idx}
                        tabIndex={-1}
                        className={styles.classes.dataRow}
                        onClick={() => goToCreditSupport(credit_support.id)}
                      >
                        <TableCell align="left">
                          {PROJECT_OBLICATION_TYPE[
                            credit_support?.obligation_type
                          ] || ""}
                        </TableCell>

                        <TableCell align="left">
                          {credit_support.name || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {credit_support?.credit_support_dollar_per_wac +
                            " $/kW-ac"}
                        </TableCell>
                        <TableCell align="left">
                          {numberToUSD.format(
                            credit_support?.credit_support_dollar_amount,
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {
                            DATE_SELECTION_TYPES[
                              credit_support?.start_date_type
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {credit_support?.credit_support_start_date}
                        </TableCell>
                        <TableCell align="left">
                          {credit_support?.credit_support_start_point}
                        </TableCell>
                        <TableCell align="left">
                          {DATE_SELECTION_TYPES[credit_support?.end_date_type]}
                        </TableCell>
                        <TableCell align="left">
                          {credit_support?.credit_support_end_date}
                        </TableCell>
                        <TableCell align="left">
                          {credit_support?.credit_support_end_point}
                        </TableCell>
                        <TableCell align="left">
                          {
                            PROJECT_CREDIT_SUPPORT_COST_METHOD[
                              credit_support?.credit_support_method
                            ]
                          }
                        </TableCell>
                        <TableCell align="left">
                          {credit_support.credit_support_method == "LC"
                            ? credit_support?.letter_of_credit_fee + "%"
                            : "N/A"}
                        </TableCell>
                        <ConditionalProtect
                          type="project"
                          permission={USER_PERMISSIONS.PROJECTS_CRUD}
                        >
                          <StickyTableCell
                            direction="right"
                            fixedColumnWidth={80}
                            align="center"
                          >
                            <PopoverMenu
                              uniqueId={idx}
                              canOpenUpgrade
                              items={[
                                {
                                  label: "Edit",
                                  onClick: () =>
                                    handleOpenUpdateCreditSupportModal(
                                      credit_support.id,
                                    ),
                                },
                                {
                                  label: "Delete",
                                  onClick: () =>
                                    handleOnDeleteClick(credit_support.id),
                                },
                              ]}
                            />
                          </StickyTableCell>
                        </ConditionalProtect>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <ViewWrapper
            loading={loadingCostScheduleDetail}
            error={getCostScheduleDetailFailed}
          >
            {projectCostScheduleDetail && (
              <ReportTableV2
                data={projectCostScheduleDetail}
                groupExpandedDepth={-1}
                download={downloadCreditSupportCostReport}
              />
            )}
          </ViewWrapper>
        </Box>
      </ViewWrapper>

      <ProjectCreditSupportFormModal
        open={addCreditSupportModalOpen}
        headerLabel="Add Credit Support"
        loading={addProjectCreditSupportLoading}
        formErrors={addProjectCreditSupportFormErrors}
        setFormErrors={setAddProjectCreditSupportFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseAddCreditSupportModal}
        onConfirm={handleOnAddCreditSupport}
      />

      <ProjectCreditSupportFormModal
        open={updateCreditSupportModalOpen}
        loading={updateProjectCreditSupportLoading}
        headerLabel="Update Credit Support"
        formErrors={updateProjectCreditSupportFormErrors}
        setFormErrors={setUpdateProjectCreditSupportFormErrors}
        form={form}
        setForm={setForm}
        onClose={handleCloseUpdateCreditSupportModal}
        onConfirm={handleOnUpdateCreditSupport}
      />

      <LogsWrapper onClose={handleCloseDrawer} open={isDrawerOpen}>
        <Logs
          nextPage={loadMoreLogs}
          logs={logs}
          type={projectLogConfiguration.type}
          loading={loadingLogs}
          totalLogs={pagination.totalItems}
          id={projectLogConfiguration.id}
        />
      </LogsWrapper>
    </>
  );
}

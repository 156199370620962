import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import { PROJECT_API_ROUTES } from "../../constants";
import { IProjectProformaResponse, ProformaReportType } from "../../interfaces";

export const downloadProjectProforma = async (
  projectId: number,
  periodicity: ProformaReportType,
  basis: "cash" | "accrual",
) => {
  return await AxiosHelper.get<Blob>(
    `project-proforma/${projectId}/download/`,
    {
      params: {
        periodicity,
        basis,
      },

      responseType: "arraybuffer",
    },
  ).then((response) => {
    transformBlobToFile(
      response,
      `project-output-download-proforma-${projectId}-${periodicity}.xlsx`,
    );
  });
};

export const getProjectProforma = async (
  projectId: number,
  periodicity: ProformaReportType,
  cash_basis: "cash" | "accrual",
) => {
  const url = replaceParams(PROJECT_API_ROUTES.projectProforma.get, {
    projectId,
    periodicity,
    basis: cash_basis,
  });
  return await AxiosHelper.get<IProjectProformaResponse>(url);
};

import DealPagesLayout from "../../../components/deal-pages-layout";
import DealProformaView from "../../../views/deal/components/pro-forma";
import TabbedLayout from "../../../components/tabbed-layout";
import {
  downloadDealProforma,
  getDealProforma,
} from "../../../apis/deal/proforma";

export default function DealProforma(): JSX.Element {
  return (
    <DealPagesLayout>
      <TabbedLayout module="Deals" pages="Components">
        <DealProformaView
          getDealProforma={getDealProforma}
          downloadDealProforma={downloadDealProforma}
        />
      </TabbedLayout>
    </DealPagesLayout>
  );
}

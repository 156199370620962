import { ICoZe } from "../../interfaces";
import { numberToUSD } from "./common.helpers";
import {
  CO_ZE_DEAL_PREF_RETURN_TYPE,
  CO_ZE_DEAL_STRUCTURE_TYPE,
  CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY,
  CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  CO_ZE_PROJECT_TYPES,
  CO_ZE_TAX_CREDIT_BONUS_ADDRES,
  CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS,
  CO_ZE_TAX_EQUITY_TYPE,
  CO_ZE_TERM_DEBT_TYPE,
  DEAL_DEPRECIATION_ELECTION,
  PROJECT_GROSS_PRODUCTION_METHOD,
  PROJECT_TAX_CREDIT_TYPE,
  US_STATES,
} from "../../constants";

export function generateGeneralDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Project Type",
          value: {
            text: CO_ZE_PROJECT_TYPES[coZe.project_energy_type],
          },
        },
        {
          label: "Project State",
          value: {
            text: US_STATES[coZe.project_state],
          },
        },
      ],
    },
  ];
}

export function generateProductionDetailsCardSections(
  coZe: ICoZe,
  productionMethodInputUnit: string = "",
) {
  return [
    {
      fields: [
        {
          label: "Capacity AC",
          value: {
            text: (coZe.capacity_ac ?? 0) + " MW",
          },
        },
        {
          label: "Capacity DC",
          value: {
            text: (coZe.capacity_dc ?? 0) + " MW",
          },
        },
        {
          label: "Production Method",
          value: {
            text: PROJECT_GROSS_PRODUCTION_METHOD[coZe.production_method],
          },
        },
        {
          label: "Production Input",
          value: {
            text:
              String(coZe.production_input ?? 0) + productionMethodInputUnit,
          },
        },
        {
          label: "Degradation Rate",
          value: {
            text: (coZe.degradation_rate_percentage ?? 0) + "%",
          },
        },
        {
          label: "Availability Factor",
          value: {
            text: (coZe.availability_factor_percentage ?? 0) + "%",
          },
        },
        {
          label: "Curtailment Loss Percentage",
          value: {
            text: (coZe.curtailment_percentage ?? 0) + "%",
          },
        },
      ],
    },
  ];
}

export function generateTimingDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Commercial Operations Date (COD)",
          value: {
            text: coZe.cod ?? "N/A",
          },
        },
        {
          label: "Project Operating Life",
          value: {
            text: (coZe.operating_life_years ?? 0) + " Yrs",
          },
        },
      ],
    },
  ];
}

export function generateCostDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Project Cost",
          value: {
            text: numberToUSD.format(coZe.cost ?? 0),
          },
        },
        {
          label: "FMV Step Up",
          value: {
            text: (coZe.fmv_step_up_percentage ?? 0) + "%",
          },
        },
        {
          label: "FMV",
          value: {
            text: numberToUSD.format(coZe.fmv ?? 0),
          },
        },
      ],
    },
  ];
}

export function generateRevenueDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "PPA Price",
          value: {
            text: "$" + (coZe.ppa_price ?? 0) + " /MWh",
          },
        },
        {
          label: "Term",
          value: {
            text: (coZe.ppa_term_years ?? 0) + " Yrs",
          },
        },
        {
          label: "Escalator",
          value: {
            text: (coZe.ppa_escalator ?? 0) + "%",
          },
        },
        {
          label: "Merchant Price",
          value: {
            text: "$" + (coZe.merchant_price ?? 0) + " / MWh",
          },
        },
        {
          label: "Escalator",
          value: {
            text: (coZe.merchant_escalator ?? 0) + "%",
          },
        },
      ],
    },
  ];
}

export function generateExpenseDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Operating Expenses",
          value: {
            text: String(coZe.opex ?? 0) + " $/kW/Yr",
          },
        },
        {
          label: "Rate",
          value: {
            text: (coZe.opex_rate ?? 0) + "%",
          },
        },
        {
          label: "Escalator",
          value: {
            text: (coZe.opex_escalator ?? 0) + "%",
          },
        },
      ],
    },
  ];
}

export function generateTaxCreditDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Type",
          value: {
            text: PROJECT_TAX_CREDIT_TYPE[coZe.tax_credit_type],
          },
        },
        {
          label: "Adders",
          value: {
            text:
              coZe.tax_credit_bonus_adders
                .map((v) => CO_ZE_TAX_CREDIT_BONUS_ADDRES[v])
                .join(", ") || "None",
          },
        },
      ],
    },
  ];
}

export function generateDealDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Structure",
          value: {
            text: CO_ZE_DEAL_STRUCTURE_TYPE[coZe.deal_structure],
          },
        },
        ...(["TEP", "CEP"].includes(coZe.deal_structure)
          ? [
              {
                label: "Type",
                value: {
                  text: CO_ZE_TAX_EQUITY_TYPE[coZe.tax_equity_type],
                },
              },
              ...(["YBF"].includes(coZe?.tax_equity_type)
                ? [
                    {
                      label: "Target Flip IRR",
                      value: {
                        text: (coZe.tax_equity_target_flip_irr ?? 0) + "%",
                      },
                    },
                  ]
                : []),
              ...(["YBF", "FF"].includes(coZe?.tax_equity_type)
                ? [
                    {
                      label: "Target Flip Term",
                      value: {
                        text: (coZe.tax_equity_target_flip_term ?? 0) + " Yrs",
                      },
                    },
                    {
                      label: "Pre-Flip Cash Split",
                      value: {
                        text: (coZe.tax_equity_pre_flip_cash_split ?? 0) + "%",
                      },
                    },
                    {
                      label: "Pre-Flip Tax Split",
                      value: {
                        text:
                          (coZe.tax_equity_pre_flip_tax_split_percent ?? 0) +
                          "%",
                      },
                    },
                  ]
                : []),
              {
                label: "Tax Allocation Method",
                value: {
                  text: CO_ZE_TAX_EQUITY_TAX_ALLOCATIONS_METHODS[
                    coZe.tax_equity_tax_allocation_method
                  ],
                },
              },
              {
                label: "Depreciation Election",
                value: {
                  text: DEAL_DEPRECIATION_ELECTION[
                    coZe.macrs_election as keyof typeof DEAL_DEPRECIATION_ELECTION
                  ],
                },
              },
            ]
          : []),
        ...(["FF"].includes(coZe?.tax_equity_type)
          ? [
              {
                label: "Target ROI",
                value: {
                  text: (coZe.tax_equity_target_roi ?? 0) + "%",
                },
              },
            ]
          : []),
        ...(["FF", "YBF"].includes(coZe?.tax_equity_type)
          ? [
              {
                label: "Preferred Return Type",
                value: {
                  text: CO_ZE_DEAL_PREF_RETURN_TYPE[
                    coZe.tax_equity_preferred_return_type
                  ],
                },
              },
            ]
          : []),
        ...(["FF", "YBF"].includes(coZe?.tax_equity_type) &&
        ["PCTINV", "PFCASH"].includes(coZe.tax_equity_preferred_return_type)
          ? [
              {
                label: "Preferred Return Percent",
                value: {
                  text: coZe.tax_equity_preferred_return_percent
                    ? (coZe.tax_equity_preferred_return_percent ?? 0) + "%"
                    : "",
                },
              },
              {
                label: "Sponsor Return of Capital",
                value: {
                  text: coZe.sponsor_return_of_capital
                    ? (coZe.sponsor_return_of_capital ?? 0) + "%"
                    : "",
                },
              },
            ]
          : []),
        {
          label: "Tax Credit Structure",
          value: {
            text: CO_ZE_DEAL_TAX_CREDIT_STRUCTURE_TYPE[
              coZe.deal_tax_credit_structure
            ],
          },
        },
        ...(coZe.deal_tax_credit_structure === "TR" ||
        coZe.deal_tax_credit_structure === "DP"
          ? [
              {
                label: "Tax Credit Transfer Portion",
                value: {
                  text: (coZe.tax_credit_transfer_portion ?? 0) + "%",
                },
              },
              {
                label: "Tax Credit Transfer Discount Rate",
                value: {
                  text: (coZe.tax_credit_transfer_discount_rate ?? 0) + "%",
                },
              },
              ...(coZe.tax_credit_type === "PTC"
                ? [
                    {
                      label: "Tax Credit Payment Periodicity",
                      value: {
                        text: CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY[
                          coZe.tax_credit_payment_periodicity as keyof typeof CO_ZE_DEAL_TAX_CREDIT_PAYMENT_PERIODICITY
                        ],
                      },
                    },
                  ]
                : []),
              {
                label: "Tax Credit Payment Rate",
                value: {
                  text:
                    (coZe.tax_credit_transfer_payment_rate ?? 0) + " $/Credit",
                },
              },
              {
                label: "Tax Credit Payment Dates Lag",
                value: {
                  text: (coZe.tax_credit_transfer_lag_period ?? 0) + " Months",
                },
              },
            ]
          : []),
      ],
    },
  ];
}

export function generateTermDebtDetailsCardSections(coZe: ICoZe) {
  return [
    {
      fields: [
        {
          label: "Type",
          value: {
            text: CO_ZE_TERM_DEBT_TYPE[coZe.term_debt_type],
          },
        },
        ...(coZe.term_debt_type !== "NONE"
          ? [
              {
                label: "Amortization Tenor",
                value: {
                  text: (coZe.debt_term ?? 0) + " Yrs",
                },
              },
              {
                label: "Interest Rate",
                value: {
                  text: (coZe.debt_interest_rate ?? 0) + "%",
                },
              },
              {
                label: "Coverage Ratio",
                value: {
                  text: (coZe.debt_coverage_ratio ?? 0) + "x",
                },
              },
            ]
          : []),
      ],
    },
  ];
}

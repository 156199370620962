import ProjectPagesLayout from "../../../../components/project-pages-layout";
import TabbedLayout from "../../../../components/tabbed-layout";
import CreditSupportDetailView from "../../../../views/project/cost/credit-support/details";
import {
  editProjectCreditSupport,
  deleteProjectCreditSupport,
  getDetailsCreditSupport,
  getProjectCreditSupportDetailSchedule,
} from "../../../../apis/project/cost";

export default function ProjectCostCreditSupportDetailPage(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Cost">
        <CreditSupportDetailView
          getDetailsCreditSupport={getDetailsCreditSupport}
          editProjectCreditSupport={editProjectCreditSupport}
          deleteProjectCreditSupport={deleteProjectCreditSupport}
          getCreditSupportDetailSchedule={getProjectCreditSupportDetailSchedule}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}

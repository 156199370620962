import { DEAL_API_ROUTES } from "../../constants";
import { AxiosHelper, replaceParams } from "../../utils/helpers";
import {
  IDealCreditSupportSummary,
  IDealOnlyCost,
  IDealProjectCostComponent,
  IReportTable,
} from "../../interfaces";

export const getDealCost = async (dealId: number, cost_input_type: string) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.get, {
    dealId,
    cost_input_type,
  });
  return await AxiosHelper.get<IDealProjectCostComponent>(url);
};

export const getCreditSupportSummary = async (
  dealId: number,
  cost_input_type: string,
) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.getCreditSupportSummart, {
    dealId,
    cost_input_type,
  });
  return await AxiosHelper.get<IDealCreditSupportSummary>(url);
};

export const getDealOnlyCost = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.deal_cost, { dealId });
  return await AxiosHelper.get<IDealOnlyCost>(url);
};

export const getDealCostsSchedule = async (dealId: number) => {
  const url = replaceParams(DEAL_API_ROUTES.cost.summary.getCostSchedule, {
    dealId,
  });
  return await AxiosHelper.get<IReportTable>(url);
};

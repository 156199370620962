import Box from "@mui/material/Box";
import { useOrganization } from "@clerk/clerk-react";

import DetailsCard from "../../../../components/details-card";
import ViewWrapper from "../../../../components/view-wrapper";
import { cn } from "../../../../utils/helpers";
import { useAppSelector } from "../../../../utils/hooks";
import {
  MARKETS,
  MW_IN_OPERATION,
  ORGANIZATION_TYPE,
  PROJECT_ENERGY_TYPES,
  US_STATES,
} from "../../../../constants";

export default function ConfigurationOrganizationProfileView(): JSX.Element {
  const { currentOrg } = useAppSelector((s) => s.org);

  const currentOrgLogo = useOrganization()?.organization?.imageUrl;

  return (
    <ViewWrapper error={false} loading={false}>
      <Box className={cn("grid md:grid-cols-2 gap-4 mt-4")}>
        <DetailsCard
          heading="General"
          sections={[
            {
              fields: [
                {
                  label: "Organization Type",
                  value: {
                    text:
                      ORGANIZATION_TYPE[
                        currentOrg?.type as keyof typeof ORGANIZATION_TYPE
                      ] ?? "N/A",
                  },
                },
                {
                  label: "Website",
                  value: {
                    text: currentOrg?.website_url ?? "N/A",
                  },
                },
                {
                  label: "Logo",
                  value: {
                    text: (
                      <img src={currentOrgLogo} alt="" height={30} width={30} />
                    ),
                  },
                },
              ],
            },
          ]}
        />
        <DetailsCard
          heading="Details"
          sections={[
            {
              fields: [
                {
                  label: "Project Types",
                  value: {
                    text:
                      currentOrg?.project_energy_types
                        ?.map(
                          (t) =>
                            PROJECT_ENERGY_TYPES[
                              t as keyof typeof PROJECT_ENERGY_TYPES
                            ],
                        )
                        .join(", ") || "N/A",
                  },
                },
                {
                  label: "MW in operations",
                  value: {
                    text:
                      MW_IN_OPERATION[
                        currentOrg?.mw_in_operation as keyof typeof MW_IN_OPERATION
                      ] ?? "N/A",
                  },
                },
                {
                  label: "Markets",
                  value: {
                    text:
                      currentOrg?.markets
                        ?.map((m) => MARKETS[m as keyof typeof MARKETS])
                        ?.join(", ") || "N/A",
                  },
                },
                {
                  label: "States",
                  value: {
                    text:
                      currentOrg?.states
                        ?.map((s) => US_STATES[s as keyof typeof US_STATES])
                        .join(", ") || "N/A",
                  },
                },
              ],
            },
          ]}
        />
      </Box>
    </ViewWrapper>
  );
}

/* eslint-disable  @typescript-eslint/no-explicit-any */
import React from "react";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import {
  Chart as ChartJS,
  TooltipItem,
  ChartEvent,
  ActiveElement,
  DoughnutController,
  ArcElement,
  ChartOptions,
} from "chart.js";

import { useAppSelector, useScreenWidth } from "../../../utils/hooks";
import {
  generateColorGradient,
  getKeyFromEnumObject,
} from "../../../utils/helpers";
import {
  DASHBOARD_MARKETS,
  DASHBOARD_PROJECT_TYPES,
  MARKETS,
  PROJECT_TAX_CREDIT_TYPE,
  PROJECT_ENERGY_TYPES,
  US_STATES,
} from "../../../constants";
import {
  IPowerMarket,
  IState,
  ITaxCreditType,
  ITechnologyType,
} from "../../../interfaces";

ChartJS.register(DoughnutController, ArcElement);

type ChartType = "technologyType" | "state" | "powerMarket" | "taxCreditType";

interface IProps {
  technologyTypeData: ITechnologyType[];
  stateData: IState[];
  powerMarketData: IPowerMarket[];
  taxCreditTypeData: ITaxCreditType[];
  type: ChartType;
}

const commonChartOptions: ChartOptions = {
  devicePixelRatio: 4,
  responsive: true,
  aspectRatio: 1.6,
  onHover: (e: ChartEvent, el: ActiveElement[]) => {
    const target = e?.native?.target as HTMLCanvasElement;
    if (target?.style) {
      if (el.length) {
        target.style.cursor = "pointer";
      } else {
        target.style.cursor = "default";
      }
    }
  },
};

export default function DashboardChart({
  technologyTypeData,
  stateData,
  powerMarketData,
  taxCreditTypeData,
  type,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const width = useScreenWidth();

  const { ctrlPressed } = useAppSelector((s) => s.common);

  const technologyTypeRef = React.useRef<HTMLCanvasElement | null>(null);
  const stateRef = React.useRef<HTMLCanvasElement | null>(null);
  const powerMarketRef = React.useRef<HTMLCanvasElement | null>(null);
  const taxCreditTypeRef = React.useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    const technologyType2DRef = technologyTypeRef?.current?.getContext("2d");

    const technologyTypeChartData = {
      labels: technologyTypeData?.map((item) => {
        const value =
          PROJECT_ENERGY_TYPES[item?.type as keyof typeof PROJECT_ENERGY_TYPES];
        return getKeyFromEnumObject(value, DASHBOARD_PROJECT_TYPES);
      }),
      datasets: [
        {
          label: "Count",
          data: technologyTypeData?.map((item) => item.count),
          backgroundColor: generateColorGradient(
            ["#5335FA", "#00B969", "#DDDDDD"],
            technologyTypeData?.length,
          ),
          borderWidth: 0,
        },
      ],
    };

    const technologyTypeChart =
      technologyType2DRef &&
      new ChartJS(technologyType2DRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: technologyTypeChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: (_, ctx: Context) => {
                return ctx.chart?.data?.labels?.[ctx.dataIndex];
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx: TooltipItem<"doughnut">) => {
                  return ctx.parsed + " Projects";
                },
                title(tooltipItems) {
                  const key = tooltipItems[0]?.label;
                  return DASHBOARD_PROJECT_TYPES[
                    key as keyof typeof DASHBOARD_PROJECT_TYPES
                  ];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                PROJECT_ENERGY_TYPES,
              );
              if (title) {
                const url = `/project-list?energy_type=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      technologyTypeChart?.destroy();
    };
  }, [width, technologyTypeData]);

  React.useEffect(() => {
    const stateData2dRef = stateRef?.current?.getContext("2d");

    const stateChartData = {
      labels: stateData?.map((item) => item.state),
      datasets: [
        {
          label: "Count",
          data: stateData?.map((item) => item.count),
          backgroundColor: generateColorGradient(
            ["#5335FA", "#00B969", "#DDDDDD"],
            stateData?.length,
          ),
          borderWidth: 0,
        },
      ],
    };

    const stateChart =
      stateData2dRef &&
      new ChartJS(stateData2dRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: stateChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: (value: any, ctx: any) => {
                return ctx.chart.data.labels[ctx.dataIndex];
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx: TooltipItem<"doughnut">) => {
                  return ctx.parsed + " Projects";
                },
                title: (tooltipItems) => {
                  const label = tooltipItems[0]?.label;
                  return US_STATES[label as keyof typeof US_STATES];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                US_STATES,
              );
              if (title) {
                const url = `/project-list?state=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      stateChart?.destroy();
    };
  }, [width, stateData]);

  React.useEffect(() => {
    const powerMarket2Dref = powerMarketRef?.current?.getContext("2d");

    const powerMarketChartData = {
      labels: powerMarketData?.map((item) => {
        const value = MARKETS[item?.power_market as keyof typeof MARKETS];
        const key = getKeyFromEnumObject(value, DASHBOARD_MARKETS);
        return key == "NA" ? "N/A" : key;
      }),
      datasets: [
        {
          label: "Count",
          data: powerMarketData?.map((item) => item.count),
          backgroundColor: generateColorGradient(
            ["#5335FA", "#00B969", "#DDDDDD"],
            powerMarketData?.length,
          ),
          borderWidth: 0,
        },
      ],
    };

    const powerMarketChart =
      powerMarket2Dref &&
      new ChartJS(powerMarket2Dref as CanvasRenderingContext2D, {
        type: "doughnut",
        data: powerMarketChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: (_, ctx: Context) => {
                return ctx?.chart?.data?.labels?.[ctx.dataIndex];
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx: TooltipItem<"doughnut">) => {
                  return ctx.parsed + " Projects";
                },
                title(tooltipItems) {
                  const key = tooltipItems[0]?.label;
                  return DASHBOARD_MARKETS[
                    key as keyof typeof DASHBOARD_MARKETS
                  ];
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                MARKETS,
              );
              if (title) {
                const url = `/project-list?power_market=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      powerMarketChart?.destroy();
    };
  }, [width, powerMarketData]);

  React.useEffect(() => {
    const taxCreditTypeData2dRef = taxCreditTypeRef?.current?.getContext("2d");

    const taxCreditTypeChartData = {
      labels: taxCreditTypeData?.map(
        (item) =>
          PROJECT_TAX_CREDIT_TYPE[
            item?.tax_credit__credit_type as keyof typeof PROJECT_TAX_CREDIT_TYPE
          ],
      ),
      datasets: [
        {
          label: "Count",
          data: taxCreditTypeData?.map((item) => item.count),
          backgroundColor: ["#5335FA", "#00B969", "#CCCCCC"],
          borderWidth: 0,
        },
      ],
    };

    const taxCreditTypeChart =
      taxCreditTypeData2dRef &&
      new ChartJS(taxCreditTypeData2dRef as CanvasRenderingContext2D, {
        type: "doughnut",
        data: taxCreditTypeChartData,
        plugins: [ChartDataLabels],
        options: {
          ...commonChartOptions,
          plugins: {
            datalabels: {
              color: "#FFFFFF",
              formatter: (value: any, ctx: any) => {
                return ctx.chart.data.labels[ctx.dataIndex];
              },
            },
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (ctx: TooltipItem<"doughnut">) => {
                  return ctx.parsed + " Projects";
                },
              },
            },
          },
          onClick: (e: any, c) => {
            if (c[0]?.index >= 0) {
              const title = getKeyFromEnumObject(
                e?.chart?.tooltip?.title[0],
                PROJECT_TAX_CREDIT_TYPE,
              );
              if (title) {
                const url = `/project-list?tax_credit__credit_type=${title}`;
                if (ctrlPressed) {
                  window.open(url);
                } else {
                  navigate(url);
                }
              }
            }
          },
        },
      });

    return () => {
      taxCreditTypeChart?.destroy();
    };
  }, [width, taxCreditTypeData]);

  const selectedChart = (chart: ChartType) => {
    switch (chart) {
      case "technologyType":
        return technologyTypeRef;
      case "state":
        return stateRef;
      case "powerMarket":
        return powerMarketRef;
      case "taxCreditType":
        return taxCreditTypeRef;
      default:
        return undefined;
    }
  };

  return (
    <div>
      <canvas id={type} ref={selectedChart(type)} />
    </div>
  );
}

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Link } from "@mui/material";
import { cloneDeep, uniqueId } from "lodash";
import {
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridValidRowModel,
} from "@mui/x-data-grid-pro";

import {
  numberWithCommas,
  numberWithDecimalsAndCommas,
  numberToUSD,
  formatNumberWithDecimals,
} from "../helpers";
import {
  IReport,
  ITableRow,
  IPartnershipReportSplit,
  ITaxEquityReportIRRData,
  IPartnershipReportSummaryData,
  ITaxEquityReportSummaryData,
  ISponsorEquityReportSummaryData,
  IDebtReportSummaryData,
  ITransferReportSummaryData,
  IPartnershipAllocCols,
  ITableColumn,
  ITransferReportIRRData,
  IOutputLessorLesseeSummary,
  IDeveloperCashReport,
  IDeveloperTaxReport,
  ISponsorEquityBOL_CashReport,
  ISponsorEquityBOL_TaxReport,
  IDeveloperReportSummary,
  DeveloperReportSummaryKeyType,
  ISponsorEquityBOL_ReportSummary,
  SponsorEquityBOL_ReportSummaryMetricsForView,
  IConstructionDebtLoanReport,
  IConstructionDebtCashReport,
  IConstructionDebtEquityReport,
  IConstructionDebtSummary,
  IReportTable,
  IDebtCoverageReport,
  ReportTerm,
  IPortfolioSourcesAndUsesConstruction,
  IPortfolioSourcesAndUsesCOD,
} from "../../interfaces";
import {
  SUMMARY_REPORT_ITEMS,
  TAX_EQUITY_SUMMARY_IRR_ITEMS,
  SPONSOR_EQUITY_SUMMARY_IRR_ITEMS,
  PARTNERSHIP_SUMMARY_IRR_ITEMS,
  PARTNERSHIP_ALLOC_DATE_NAMES,
  PARTNERSHIP_ALLOC_ITEM_NAMES,
  DEBT_SUMMARY_IRR_ITEMS,
  TRANSFER_SUMMARY_REPORT_ITEMS,
  TRANSFER_SUMMARY_REPORT_NPV_ITEMS,
  PARTNERSHIP_COMBINED_TABLE_ROWS,
  DEAL_STRUCTURE_TYPE,
  DEVELOPER_TAX_REPORT_TABLE_COLUMNS,
  SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_COLUMNS,
  SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_DIRECT_PAY_ADDITIONAL_COLUMNS,
  SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_TRANSFER_ADDITIONAL_COLUMNS,
  SPONSOR_EQUITY_BOL_TAX_REPORT_TABLE_COLUMNS,
  DEVELOPER_REPORT_SUMMARY_ITEMS,
  DEVELOPER_REPORT_CASHFLOW_ITEMS,
  SPONSOR_EQUITY_BOL_SUMMARY_ITEMS,
  CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS,
  CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS,
  CONSTRUCTION_DEBT_EQUITY_REPORT_COLUMNS,
  CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_ITEMS,
  DEBT_COVERAGE_REPORT_TABLE_COLUMNS,
  PARTNERSHIP_SUMMARY_REPORT_ITEMS,
  TAX_EQUITY_SUMMARY_REPORT_ITEMS,
  SE_SUMMARY_REPORT_ITEMS,
} from "../../constants";
import {
  FUNDING_METHODOLOGY_CHOICES,
  CONSTRUCTION_DEBT_BASE_RATE_TYPE,
} from "../../constants/deal/construction-debt";

export const generateIrrPartnershipSummaryRows = (
  report: IPartnershipReportSummaryData,
): ITableRow[] => {
  const rows: ITableRow[] = [];
  Object.keys(PARTNERSHIP_SUMMARY_IRR_ITEMS).forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report]?.stakeholder_amount;
      rows.push({
        cells: [
          {
            align: "left",
            value:
              PARTNERSHIP_SUMMARY_IRR_ITEMS[
                key as keyof typeof PARTNERSHIP_SUMMARY_IRR_ITEMS
              ].name,
          },
          {
            align: "right",
            value:
              typeof col_value === "number"
                ? numberWithDecimalsAndCommas(Number(col_value)) +
                  PARTNERSHIP_SUMMARY_IRR_ITEMS[
                    key as keyof typeof PARTNERSHIP_SUMMARY_IRR_ITEMS
                  ].suffix
                : col_value,
          },
        ],
      });
    }
  });

  return rows;
};

const getPartnershipAllocItemName = (
  dealStructure?: keyof typeof DEAL_STRUCTURE_TYPE,
) => {
  if (dealStructure === "CEP") {
    const clonedObj = cloneDeep(PARTNERSHIP_ALLOC_ITEM_NAMES);
    Object.entries(clonedObj).forEach(([key, value]) => {
      if (value.includes("TE ")) {
        clonedObj[key as keyof typeof clonedObj] = value.replace("TE ", "CE ");
      }
    });
    return clonedObj;
  }
  return PARTNERSHIP_ALLOC_ITEM_NAMES;
};

export const generatePartnershipAllocRows = (
  report: IPartnershipAllocCols,
  dealStructure?: keyof typeof DEAL_STRUCTURE_TYPE,
): { rows: ITableRow[]; columns: ITableColumn[] } => {
  const rows: ITableRow[] = [];
  const colNames = Object.keys(report);

  // get first column of the report
  const col1 = report[colNames[0] as keyof typeof report];
  // if first column is empty, then all cols are empty / something has gone wrong
  if (!col1) return { rows: [], columns: [] };

  //  columns
  const columns: ITableColumn[] = [];
  columns.push(
    PARTNERSHIP_ALLOC_DATE_NAMES[
      "row_labels" as keyof typeof PARTNERSHIP_ALLOC_DATE_NAMES
    ],
  );

  colNames.forEach((colName) => {
    const column =
      PARTNERSHIP_ALLOC_DATE_NAMES[
        colName as keyof typeof PARTNERSHIP_ALLOC_DATE_NAMES
      ];
    if (column) {
      columns.push(column);
    } else {
      columns.push({
        id: colName,
        align: "right",
        label: colName
          .replace(/_/g, " ")
          .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
            index === 0 ? word.toUpperCase() : word.toLowerCase(),
          ),

        minWidth: 170,
      });
    }
  });
  // rows

  const partnershipAllocItemName = getPartnershipAllocItemName(dealStructure);

  Object.keys(partnershipAllocItemName).forEach((item) => {
    const newRow: ITableRow = { cells: [] };
    const borderBottom = partnershipAllocItemName[
      item as keyof typeof partnershipAllocItemName
    ].startsWith("SE ")
      ? "1px solid #000000"
      : null;

    newRow.cells.push({
      align: "left",
      bold: true,
      value:
        partnershipAllocItemName[item as keyof typeof partnershipAllocItemName],
      borderBottom: borderBottom,
    });

    colNames.forEach((colName) => {
      const col_value = report[colName as keyof typeof report];
      newRow.cells.push({
        align: "right",
        value: col_value ? col_value[item as keyof typeof col_value] : "",
        borderBottom: borderBottom,
      });
    });

    // if newRow has any values other than the first cell, then add it to the rows
    if (newRow.cells.some((cell, idx) => idx !== 0 && cell.value)) {
      rows.push(newRow);
    }
  });
  return { rows: rows, columns: columns };
};

export const generateDebtReportSummary = (
  report: IDebtReportSummaryData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];
  const SHORT_TABLE_ITEMS = [
    "principal",
    "interest",
    "pre_tax_cash",
    "pre_tax_cash_with_tax_credits",
    "after_tax_cash",
    "debt_service_reserve",
    "debt_term",
    "min_debt_service_coverage_ratio",
    "avg_debt_service_coverage_ratio",
  ];

  Object.keys(SUMMARY_REPORT_ITEMS).forEach((key) => {
    if (SHORT_TABLE_ITEMS.includes(key)) {
      return;
    }
    if (key in report) {
      const col_value = report[key as keyof typeof report];
      if (!SHORT_TABLE_ITEMS.includes(key)) {
        rows.push({
          cells: [
            {
              align: "right",
              value:
                SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                  .name +
                SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                  .suffix,
            },
            {
              align: "right",
              value:
                typeof col_value.stakeholder_amount === "number"
                  ? numberWithCommas.format(
                      Number(col_value.stakeholder_amount),
                    )
                  : col_value.stakeholder_amount,
            },
            {
              align: "right",
              value:
                typeof col_value.stakeholder_share === "number"
                  ? col_value.stakeholder_share + "%"
                  : col_value.stakeholder_share,
            },
            {
              align: "right",
              value:
                typeof col_value.npv === "number"
                  ? numberWithCommas.format(Number(col_value.npv))
                  : col_value.npv,
            },
            {
              align: "right",
              value:
                typeof col_value.npv_percent === "number"
                  ? col_value.npv_percent + "%"
                  : col_value.npv_percent,
            },
          ],
        });
      }
    }
  });

  return rows;
};

export const generateShortDebtReportSummary = (
  report: IDebtReportSummaryData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];
  const SHORT_TABLE_ITEMS = [
    // "debt_amount",
    // "principal",
    // "interest",
    "debt_service_reserve",
    "debt_term",
    "min_debt_service_coverage_ratio",
    "avg_debt_service_coverage_ratio",
  ];
  SHORT_TABLE_ITEMS.forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report];
      rows.push({
        cells: [
          {
            align: "left",
            value:
              SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                .name,
          },
          {
            align: "right",
            value:
              typeof col_value.stakeholder_amount === "number"
                ? (!key.includes("debt_service_coverage_ratio")
                    ? numberWithCommas.format(
                        Number(col_value.stakeholder_amount),
                      )
                    : Number(col_value.stakeholder_amount).toFixed(4)) +
                  SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                    .suffix
                : "N/A",
          },
        ],
      });
    }
  });

  return rows;
};

export const generateConstructionDebtGeneralSummary = (
  report: IConstructionDebtSummary,
): ITableRow[] => {
  const {
    funding_methodology,
    min_equity_during_construction,
    equity_letter_of_credit_fee,
    admin_agent_fees,
    debt_closing_date_calculated,
    loc_for_construction_credit_support,
    sofr_org_curve_detail,
  } = report.data.general_sizing;

  const minEquity =
    typeof min_equity_during_construction === "number"
      ? min_equity_during_construction + "%"
      : "N/A";

  const equityLetterFee =
    typeof equity_letter_of_credit_fee === "number"
      ? equity_letter_of_credit_fee + "%"
      : "N/A";

  const adminAgentFees =
    typeof admin_agent_fees === "number"
      ? numberToUSD.format(admin_agent_fees)
      : "N/A";

  const rows: ITableRow[] = [
    {
      cells: [
        {
          value: "Debt Closing Date",
        },
        {
          align: "right",
          value: debt_closing_date_calculated,
        },
      ],
    },
    {
      cells: [
        {
          value: "Funding",
        },
        {
          align: "right",
          value: FUNDING_METHODOLOGY_CHOICES[funding_methodology],
        },
      ],
    },
    {
      cells: [
        {
          value: "Minimum Equity During Construction",
        },
        {
          align: "right",
          value: minEquity,
        },
      ],
    },
    {
      cells: [
        {
          value: "Admin Agent Fees",
        },
        {
          align: "right",
          value: adminAgentFees,
        },
      ],
    },
    {
      cells: [
        {
          value: "Letters of Credit for Construction Credit Support",
        },
        {
          align: "right",
          value: loc_for_construction_credit_support ? "Yes" : "No",
        },
      ],
    },
    {
      cells: [
        { value: "Interest Rate Curve" },
        {
          align: "right",
          value: sofr_org_curve_detail ? (
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                window.open(
                  `/configuration/data/curves/${sofr_org_curve_detail.curve_group}/${sofr_org_curve_detail.id}`,
                );
              }}
            >
              {sofr_org_curve_detail.name}
              <OpenInNewIcon fontSize="small" />
            </Link>
          ) : (
            "N/A"
          ),
        },
      ],
    },
  ];

  if (funding_methodology === "DF") {
    rows.push({
      cells: [
        {
          value: "Equity Letter of Credit Fee",
        },
        {
          align: "right",
          value: equityLetterFee,
        },
      ],
    });
  }

  return rows;
};

export const generateConstructionDebtLoanSummary = (
  report: IConstructionDebtSummary,
): ITableRow[] => {
  const {
    construction_loan,
    tax_equity_bridge_loan,
    // transfer_bridge_loan,
    general_sizing,
  } = report.data;

  return CONSTRUCTION_DEBT_LOANS_SUMMARY_TABLE_ITEMS.map((item) => {
    const { id, label, enum: enumObj, suffix, formatNumbers, prefix } = item;

    // c = construction_loan
    let clValue: string | number | null = "N/A";

    // i = Investor loan
    let ilValue: string | number | boolean = "N/A";

    // t = transfer loan
    // let tlValue: string | number = "N/A";

    if (id in construction_loan) {
      clValue =
        construction_loan[
          id as keyof IConstructionDebtSummary["data"]["construction_loan"]
        ];

      if (enumObj && typeof clValue === "string") {
        clValue = enumObj[clValue];
      }
      if (formatNumbers && typeof clValue === "number") {
        clValue = numberWithCommas.format(clValue) + (suffix || "");
      }
      clValue = clValue + (suffix || "");

      if (id === "base_rate") {
        if (construction_loan["base_rate_type"] == "SOFR") {
          clValue = CONSTRUCTION_DEBT_BASE_RATE_TYPE.SOFR;
        }
      }
    }

    if (id in tax_equity_bridge_loan) {
      ilValue =
        tax_equity_bridge_loan[
          id as keyof IConstructionDebtSummary["data"]["tax_equity_bridge_loan"]
        ];

      if (enumObj && typeof ilValue === "string") {
        ilValue = enumObj[ilValue];
      }

      if (formatNumbers && typeof ilValue === "number") {
        ilValue = numberWithCommas.format(ilValue) + (suffix || "");
      }
      ilValue = ilValue + (suffix || "");

      if (id === "base_rate") {
        if (tax_equity_bridge_loan["base_rate_type"] == "SOFR") {
          ilValue = CONSTRUCTION_DEBT_BASE_RATE_TYPE.SOFR;
        }
      }
    }

    // if (id in transfer_bridge_loan) {
    //   tlValue =
    //     transfer_bridge_loan[
    //       id as keyof IConstructionDebtSummary["data"]["transfer_bridge_loan"]
    //     ];

    //   if (enumObj) {
    //     tlValue = enumObj[tlValue];
    //   }
    //   tlValue = tlValue + (suffix || "");
    // }

    if (id === "admin_agent_fee") {
      const adminAgentFee = general_sizing.admin_agent_fees;
      if (formatNumbers && typeof adminAgentFee === "number") {
        const fomattedFee =
          prefix + numberWithCommas.format(adminAgentFee) + (suffix || "");
        clValue = fomattedFee;
        ilValue = fomattedFee;
        // tlValue = fomattedFee;
      }
    }

    return {
      cells: [
        { value: label },
        { value: clValue },
        { value: ilValue },
        // { value: tlValue },
      ],
    };
  });
};

export const generateIrrDebtReportSummary = (
  report: IDebtReportSummaryData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];
  Object.keys(DEBT_SUMMARY_IRR_ITEMS).forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report]?.stakeholder_amount;
      if (["spread_step_up", "spread_step_up_frequency"].includes(key)) {
        if (col_value === 0) {
          return;
        }
      }
      const fieldConfiguration =
        DEBT_SUMMARY_IRR_ITEMS[key as keyof typeof DEBT_SUMMARY_IRR_ITEMS];

      rows.push({
        cells: [
          {
            align: "left",
            value:
              DEBT_SUMMARY_IRR_ITEMS[key as keyof typeof DEBT_SUMMARY_IRR_ITEMS]
                .name,
          },
          {
            align: "right",
            value:
              typeof col_value === "number"
                ? numberWithDecimalsAndCommas(
                    Number(col_value),
                    fieldConfiguration.precision,
                  ) + fieldConfiguration.suffix
                : "N/A",
          },
        ],
      });
    }
  });
  return rows;
};

export const generateTransferReportSummary = (
  report: ITransferReportSummaryData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];

  const TABLE_ITEMS = [
    "purchase",
    "cash_distributions",
    "tax_credits",
    "depreciation_benefits",
    "tax_detriments",
    "tax_efficient_value",
  ];

  TABLE_ITEMS.forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report];
      rows.push({
        cells: [
          {
            align: "right",
            value:
              TRANSFER_SUMMARY_REPORT_NPV_ITEMS[
                key as keyof typeof TRANSFER_SUMMARY_REPORT_NPV_ITEMS
              ].name +
              TRANSFER_SUMMARY_REPORT_NPV_ITEMS[
                key as keyof typeof TRANSFER_SUMMARY_REPORT_NPV_ITEMS
              ].suffix,
          },
          {
            align: "right",
            value:
              typeof col_value.stakeholder_amount === "number"
                ? numberWithCommas.format(Number(col_value.stakeholder_amount))
                : col_value.stakeholder_amount,
          },
          {
            align: "right",
            value:
              typeof col_value.stakeholder_share === "number"
                ? col_value.stakeholder_share + "%"
                : col_value.stakeholder_share,
          },
          {
            align: "right",
            value:
              typeof col_value.npv === "number"
                ? numberWithCommas.format(Number(col_value.npv))
                : col_value.npv,
          },
          {
            align: "right",
            value:
              typeof col_value.npv_percent === "number"
                ? col_value.npv_percent + "%"
                : col_value.npv_percent,
          },
        ],
      });
    }
  });

  return rows;
};

export const generateShortTransferReportSummary = (
  report: ITransferReportSummaryData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];
  const TABLE_ITEMS = ["payment_total"];

  TABLE_ITEMS.forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report];
      rows.push({
        cells: [
          {
            align: "left",
            value:
              TRANSFER_SUMMARY_REPORT_ITEMS[
                key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
              ].name,
          },
          {
            align: "right",
            value:
              typeof col_value === "number"
                ? numberWithCommas.format(Number(col_value))
                : col_value +
                  TRANSFER_SUMMARY_REPORT_ITEMS[
                    key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
                  ].suffix,
          },
        ],
      });
    }
  });

  return rows;
};

export const generateIrrTransferReportSummary = (
  report: ITransferReportSummaryData | null,
  errors: ITransferReportIRRData | null,
): ITableRow[] | null => {
  if (report === null) {
    return null;
  }
  const rows: ITableRow[] = [];
  const TABLE_ITEMS = [
    "payment_total",
    "payment_rate",
    "discount_rate",
    "roi",
    "npv_payment_total",
    "npv_tax_credits",
    "adjusted_payment_rate",
    "adjusted_payment_total",
  ];

  TABLE_ITEMS.forEach((key) => {
    const col_value = report[key as keyof typeof report];
    const error = errors && errors[key as keyof ITransferReportIRRData];

    if (key in report) {
      rows.push({
        cells: [
          {
            align: "left",
            value:
              TRANSFER_SUMMARY_REPORT_ITEMS[
                key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
              ].name,
            icon: error ? { description: error } : { description: null },
          },
          {
            align: "right",
            value: TRANSFER_SUMMARY_REPORT_ITEMS[
              key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
            ].is_dollar
              ? numberWithCommas.format(Number(col_value))
              : numberWithDecimalsAndCommas(
                  Number(col_value),
                  TRANSFER_SUMMARY_REPORT_ITEMS[
                    key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
                  ].toFixed ?? 0,
                ) +
                TRANSFER_SUMMARY_REPORT_ITEMS[
                  key as keyof typeof TRANSFER_SUMMARY_REPORT_ITEMS
                ].suffix,
          },
        ],
      });
    }
  });

  return rows;
};

export const generateSponsorEquityReportSummary = (
  report: ISponsorEquityReportSummaryData,
): ITableRow[] => {
  const rows: ITableRow[] = [];
  const SHORT_TABLE_ITEMS = [
    "pre_tax_cash",
    "after_tax_cash",
    "pre_tax_cash_with_tax_credits",
  ];

  Object.keys(SUMMARY_REPORT_ITEMS).forEach((key) => {
    if (SHORT_TABLE_ITEMS.includes(key)) {
      return;
    }
    if (key in report) {
      const col_value = report[key as keyof typeof report];
      rows.push({
        cells: [
          {
            align: "right",
            value:
              SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                .name +
              SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
                .suffix,
          },
          {
            align: "right",
            value:
              typeof col_value.stakeholder_amount === "number"
                ? numberWithCommas.format(Number(col_value.stakeholder_amount))
                : col_value.stakeholder_amount,
          },
          {
            align: "right",
            value:
              typeof col_value.stakeholder_share === "number"
                ? col_value.stakeholder_share + "%"
                : col_value.stakeholder_share,
          },
          {
            align: "right",
            value:
              typeof col_value.npv === "number"
                ? numberWithCommas.format(Number(col_value.npv))
                : col_value.npv,
          },
          {
            align: "right",
            value:
              typeof col_value.npv_percent === "number"
                ? col_value.npv_percent + "%"
                : col_value.npv_percent,
          },
        ],
      });
    }
  });

  return rows;
};

export const generateShortTaxEquityReportSummary = (
  report: ITaxEquityReportSummaryData,
): ITableRow[] => {
  const rows: ITableRow[] = [];
  const SHORT_TABLE_ITEMS = [
    "deal_fmv",
    "deal_fees",
    "capital_contributions",
    // "fmv_step_up",
    // "debt_proceeds",
    "equity",
    "cash_distributions",
    "transfer_proceeds",
    "direct_pay_proceeds",
    // "debt_service",
    "pre_tax_cash",
    "tax_credits",
    "pre_tax_cash_with_tax_credits",
    "depreciation_benefits",
    "tax_detriments",
    "after_tax_cash",
  ];
  const BOLD_ROWS = [
    "capital_contributions",
    "pre_tax_cash",
    "pre_tax_cash_with_tax_credits",
    "after_tax_cash",
    "equity",
  ];
  const HIDE_IF_ZERO_ROWS = ["direct_pay_proceeds", "transfer_proceeds"];
  SHORT_TABLE_ITEMS.forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report]?.stakeholder_amount;
      if (HIDE_IF_ZERO_ROWS.includes(key) && col_value === 0) return;
      if (col_value !== null) {
        const item =
          TAX_EQUITY_SUMMARY_REPORT_ITEMS[
            key as keyof typeof TAX_EQUITY_SUMMARY_REPORT_ITEMS
          ];
        rows.push({
          cells: [
            {
              align: "left",
              value: item.prefix + item.name + item.suffix,
              bold: BOLD_ROWS.includes(key),
            },
            {
              align: "right",
              value:
                typeof col_value === "number"
                  ? numberWithCommas.format(Number(col_value))
                  : col_value,
              bold: BOLD_ROWS.includes(key),
            },
          ],
        });
      }
    }
  });

  return rows;
};

export const generateShortSponsorEquityReportSummary = (
  report: ISponsorEquityReportSummaryData,
): ITableRow[] => {
  const rows: ITableRow[] = [];
  const SHORT_TABLE_ITEMS = [
    "deal_fmv",
    "deal_fees",
    "capital_contributions",
    "fmv_step_up",
    "debt_proceeds",
    "equity",
    "cash_distributions",
    "transfer_proceeds",
    "direct_pay_proceeds",
    "debt_service",
    "pre_tax_cash",
    "tax_credits",
    "pre_tax_cash_with_tax_credits",
    "depreciation_benefits",
    "tax_detriments",
    "after_tax_cash",
  ];
  const BOLD_ROWS = [
    "capital_contributions",
    "pre_tax_cash",
    "pre_tax_cash_with_tax_credits",
    "after_tax_cash",
    "equity",
  ];
  const HIDE_IF_ZERO_ROWS = ["direct_pay_proceeds", "transfer_proceeds"];
  SHORT_TABLE_ITEMS.forEach((key) => {
    if (key in report) {
      const col_value = report[key as keyof typeof report].stakeholder_amount;
      if (HIDE_IF_ZERO_ROWS.includes(key) && col_value === 0) return;
      if (col_value !== null) {
        const item =
          SE_SUMMARY_REPORT_ITEMS[key as keyof typeof SE_SUMMARY_REPORT_ITEMS];
        rows.push({
          cells: [
            {
              align: "left",
              value: item.prefix + item.name + item.suffix,
              bold: BOLD_ROWS.includes(key),
            },
            {
              align: "right",
              value:
                typeof col_value === "number"
                  ? numberWithCommas.format(Number(col_value))
                  : col_value,
              bold: BOLD_ROWS.includes(key),
            },
          ],
        });
      }
    }
  });

  return rows;
};

export const generateIrrTaxEquityReportSummary = (
  report: ITaxEquityReportSummaryData,
  errors?: ITaxEquityReportIRRData,
  propertiesToRemove?: string[],
): ITableRow[] => {
  const rows: ITableRow[] = [];
  Object.keys(TAX_EQUITY_SUMMARY_IRR_ITEMS).forEach((key) => {
    if (
      key in report &&
      (!propertiesToRemove || !propertiesToRemove.includes(key))
    ) {
      const col =
        TAX_EQUITY_SUMMARY_IRR_ITEMS[
          key as keyof typeof TAX_EQUITY_SUMMARY_IRR_ITEMS
        ];

      if (col.hideWhenNull === undefined) {
        col.hideWhenNull = false;
      }
      if (
        col.hideWhenNull &&
        report[key as keyof typeof report]?.stakeholder_amount === null
      ) {
        return;
      }
      if (col.visibleInCoZe === undefined) {
        col.visibleInCoZe = true;
      }
      if (col.visibleInCoZe === false) {
        return;
      }
      const col_value = report[key as keyof typeof report]?.stakeholder_amount;
      const error = errors && errors[key as keyof ITaxEquityReportIRRData];
      rows.push({
        cells: [
          {
            align: "left",
            value:
              TAX_EQUITY_SUMMARY_IRR_ITEMS[
                key as keyof typeof TAX_EQUITY_SUMMARY_IRR_ITEMS
              ].name,
            icon: error
              ? { description: errors[key as keyof ITaxEquityReportIRRData] }
              : { description: null },
          },
          {
            align: "right",
            value: TAX_EQUITY_SUMMARY_IRR_ITEMS[
              key as keyof typeof TAX_EQUITY_SUMMARY_IRR_ITEMS
            ].isDollar
              ? numberWithCommas.format(Number(col_value)) +
                TAX_EQUITY_SUMMARY_IRR_ITEMS[
                  key as keyof typeof TAX_EQUITY_SUMMARY_IRR_ITEMS
                ].suffix
              : typeof col_value === "string"
                ? col_value
                : numberWithDecimalsAndCommas(Number(col_value)) +
                  TAX_EQUITY_SUMMARY_IRR_ITEMS[
                    key as keyof typeof TAX_EQUITY_SUMMARY_IRR_ITEMS
                  ].suffix,
          },
        ],
      });
    }
  });

  return rows;
};

export const generateIrrSponsorEquityReportSummary = (
  report: ISponsorEquityReportSummaryData,
): ITableRow[] => {
  const rows: ITableRow[] = [];

  Object.entries(SPONSOR_EQUITY_SUMMARY_IRR_ITEMS).forEach(
    ([key, colConfig]) => {
      if (key in report) {
        const rawValue = report[key as keyof typeof report]?.stakeholder_amount;
        const colValue = colConfig.enumType
          ? colConfig.enumType[rawValue as keyof typeof colConfig.enumType]
          : (rawValue ?? "N/A");

        const formattedValue = colConfig.isString
          ? colValue
          : typeof colValue === "number"
            ? colConfig.isNumberFormatted
              ? numberWithCommas.format(Number(colValue))
              : parseFloat(String(colValue)).toFixed(2) + colConfig.suffix
            : colValue;

        rows.push({
          cells: [
            {
              align: "left",
              value: colConfig.name,
            },
            {
              align: "right",
              value: formattedValue,
            },
          ],
        });
      }
    },
  );

  return rows;
};

export const generateCashReportNoBackLeverageTableRows = (
  report: IReport,
  buyout: string,
): ITableRow[] => {
  const r = report["pship/cashdetail"];
  const totals = report["pship/cashdetail_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      ...(buyout !== "taxflip"
        ? [
            {
              align: "right",
              value: numberWithCommas.format(totals.deal_fmv),
            },
            {
              align: "right",
              value: numberWithCommas.format(totals.deal_fees),
            },
            {
              align: "right",
              value: numberWithCommas.format(totals.capital_contributions),
            },
            ...(r.distributions_investor_preferred_return !== undefined
              ? [
                  {
                    align: "right",
                    value: numberWithCommas.format(
                      totals.distributions_investor_preferred_return,
                    ),
                  },
                ]
              : []),
            {
              align: "right",
              value: numberWithCommas.format(totals.distributions),
            },
            ...(r.transfer_proceeds !== undefined
              ? [
                  {
                    align: "right",
                    value: numberWithCommas.format(totals.transfer_proceeds),
                  },
                ]
              : []),
            ...(r.equity_buyout !== undefined
              ? [
                  {
                    align: "right",
                    value: numberWithCommas.format(totals.equity_buyout),
                  },
                ]
              : []),
          ]
        : []),
      { align: "right", value: numberWithCommas.format(totals.pre_tax_cash) },
      {
        align: "right",
        value: numberWithCommas.format(totals.investment_tax_credits),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_cash_with_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes_pre_credits),
      },
      { align: "right", value: numberWithCommas.format(totals.after_tax_cash) },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        ...(buyout !== "taxflip"
          ? [
              {
                align: "right",
                value: numberWithCommas.format(r.deal_fmv[idx]),
              },
              {
                align: "right",
                value: numberWithCommas.format(r.deal_fees[idx]),
              },
              {
                align: "right",
                value: numberWithCommas.format(r.capital_contributions[idx]),
              },
              ...(r.distributions_investor_preferred_return !== undefined
                ? [
                    {
                      align: "right",
                      value: numberWithCommas.format(
                        r.distributions_investor_preferred_return[idx],
                      ),
                    },
                  ]
                : []),
              {
                align: "right",
                value: numberWithCommas.format(r.distributions[idx]),
              },
              ...(r.transfer_proceeds !== undefined
                ? [
                    {
                      align: "right",
                      value: numberWithCommas.format(r.transfer_proceeds[idx]),
                    },
                  ]
                : []),
              ...(r.equity_buyout !== undefined
                ? [
                    {
                      align: "right",
                      value: numberWithCommas.format(r.equity_buyout[idx]),
                    },
                  ]
                : []),
            ]
          : []),
        // TODO: currently investment_tax_credits but its not confirmed
        { align: "right", value: numberWithCommas.format(r.pre_tax_cash[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.investment_tax_credits[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_cash_with_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes_pre_credits[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.after_tax_cash[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateSlbCashReportTableHeaders = (
  report: IReport,
): ITableColumn[] => {
  // map report.leasetax.columns to ITableColumn[] via .header property
  const r = report["leasecash"];
  const columns = r.columns;
  return columns.map((col) => {
    return {
      id: col.id,
      align: "right",
      label: col.header,
      minWidth: 100,
    };
  });
};

export const generateSlbCashReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report.leasecash;
  const columns = r.columns;
  // Create a row for the totals via r.columns.total for each item in the columns
  const totalsRow = {
    cells: columns.map((col) => {
      return {
        align: "right",

        //  format the total value, if number format it if strig leave it alone
        value:
          col.total !== null && typeof col.total === "number"
            ? numberWithCommas.format(col.total)
            : col.total,
      };
    }),
  };
  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: columns.map((col) => {
        const value = col.values[idx];
        return {
          align: "right",
          value:
            value !== null && typeof value === "number"
              ? numberWithCommas.format(value)
              : value,
        };
      }),
    };
  });
  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateSlbTaxReportTableHeaders = (
  report: IReport,
): ITableColumn[] => {
  const r = report.leasetax;
  const columns = r.columns;
  return columns.map((col) => {
    return {
      id: col.id,
      align: "right",
      label: col.header,
      minWidth: 100,
    };
  });
};

export const generateSlbTaxReportTableRows = (report: IReport): ITableRow[] => {
  const r = report["leasetax"];
  const columns = report["leasetax"].columns;
  // Create a row for the totals via r.columns.total for each item in the columns
  const totalsRow = {
    cells: columns.map((col) => {
      return {
        align: "right",
        value:
          col.total !== null && typeof col.total === "number"
            ? numberWithCommas.format(col.total)
            : col.total,
      };
    }),
  };
  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: columns.map((col) => {
        const value = col.values[idx];
        return {
          align: "right",
          value:
            value !== null && typeof value === "number"
              ? numberWithCommas.format(value)
              : value,
        };
      }),
    };
  });
  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateCashReportTableRows = (report: IReport): ITableRow[] => {
  const r = report["pship/cashdetail"];
  const totals = report["pship/cashdetail_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.deal_fmv),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.deal_fees),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_contributions),
      },
      { align: "right", value: numberWithCommas.format(totals.fmv_step_up) },
      { align: "right", value: numberWithCommas.format(totals.debt_proceeds) },
      { align: "right", value: numberWithCommas.format(totals.equity) },
      ...(r.distributions_investor_preferred_return !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(
                totals.distributions_investor_preferred_return,
              ),
            },
          ]
        : []),
      ...(r.distributions_sponsor_return_of_capital !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(
                totals.distributions_sponsor_return_of_capital,
              ),
            },
          ]
        : []),
      { align: "right", value: numberWithCommas.format(totals.distributions) },
      ...(r.transfer_proceeds !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(totals.transfer_proceeds),
            },
          ]
        : []),
      ...(r.equity_buyout !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(totals.equity_buyout),
            },
          ]
        : []),
      {
        align: "right",
        value: numberWithCommas.format(totals.distributions_sponsor_bl_ds),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_released_reserved),
      },
      { align: "right", value: numberWithCommas.format(totals.pre_tax_cash) },
      {
        align: "right",
        value: numberWithCommas.format(totals.investment_tax_credits),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_cash_with_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes_pre_credits),
      },
      { align: "right", value: numberWithCommas.format(totals.after_tax_cash) },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.deal_fmv[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.deal_fees[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_contributions[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.fmv_step_up[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.debt_proceeds[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.equity[idx]) },
        ...(r.distributions_investor_preferred_return !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(
                  r.distributions_investor_preferred_return[idx],
                ),
              },
            ]
          : []),
        ...(r.distributions_sponsor_return_of_capital !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(
                  r.distributions_sponsor_return_of_capital[idx],
                ),
              },
            ]
          : []),
        {
          align: "right",
          value: numberWithCommas.format(r.distributions[idx]),
        },
        ...(r.transfer_proceeds !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(r.transfer_proceeds[idx]),
              },
            ]
          : []),
        ...(r.equity_buyout !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(r.equity_buyout[idx]),
              },
            ]
          : []),
        {
          align: "right",
          value: numberWithCommas.format(r.distributions_sponsor_bl_ds[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_released_reserved[idx]),
        },
        // TODO: currently investment_tax_credits but its not confirmed
        { align: "right", value: numberWithCommas.format(r.pre_tax_cash[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.investment_tax_credits[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_cash_with_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes_pre_credits[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.after_tax_cash[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateDebtFacilityReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["loan/combined(all)"];
  const totals = report["loan/combined(all)_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.beginning_balance),
      },
      { align: "right", value: numberWithCommas.format(totals.draw_down) },
      // TODO: currently investment_tax_credits but its not confirmed
      {
        align: "right",
        value: numberWithCommas.format(totals.interest),
      },
      { align: "right", value: numberWithCommas.format(totals.principal) },
      {
        align: "right",
        value: numberWithCommas.format(totals.balance),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.debt_service),
      },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.beginning_balance[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.draw_down[idx]) },
        // TODO: currently investment_tax_credits but its not confirmed
        {
          align: "right",
          value: numberWithCommas.format(r.interest[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.principal[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.balance[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.debt_service[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateTransferCashReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["transfer"];
  const totals = report["transfer_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.credits_transferred),
      },
      ...(totals.cash_paid_to_investor !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(totals.cash_paid_to_investor),
            },
          ]
        : []),
      ...(totals.cash_paid_to_sponsor !== undefined
        ? [
            {
              align: "right",
              value: numberWithCommas.format(totals.cash_paid_to_sponsor),
            },
          ]
        : []),
      { align: "right", value: numberWithCommas.format(totals.after_tax_cash) },
      {
        align: "right",
        value: numberWithCommas.format(totals.cumulative_atc),
      },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.credits_transferred[idx]),
        },
        ...(r.cash_paid_to_investor !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(r.cash_paid_to_investor[idx]),
              },
            ]
          : []),
        ...(r.cash_paid_to_sponsor !== undefined
          ? [
              {
                align: "right",
                value: numberWithCommas.format(r.cash_paid_to_sponsor[idx]),
              },
            ]
          : []),
        {
          align: "right",
          value: numberWithCommas.format(r.after_tax_cash[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cumulative_atc[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateTaxReportTableRows = (report: IReport): ITableRow[] => {
  const r = report["pship/taxdetail"];
  const totals = report["pship/taxdetail_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.net_operating_income),
      },
      { align: "right", value: numberWithCommas.format(totals.depreciation) },
      { align: "right", value: numberWithCommas.format(totals.fmv_step_up) },
      {
        align: "right",
        value: numberWithCommas.format(
          totals.other_income_interest_on_back_leverage,
        ),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.other_income_loss),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.total_taxable_income),
      },
      { align: "right", value: totals.tax_rate + "%" },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes_before_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_tax_from_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes),
      },
    ],
  };

  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.net_operating_income[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.depreciation[idx]) },
        { align: "right", value: numberWithCommas.format(r.fmv_step_up[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(
            r.other_income_interest_on_back_leverage[idx],
          ),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.other_income_loss[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.total_taxable_income[idx]),
        },
        { align: "right", value: r.tax_rate[idx] + "%" },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes_before_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_tax_from_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateShortTaxReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["pship/taxdetail"];
  const totals = report["pship/taxdetail_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.net_operating_income),
      },
      { align: "right", value: numberWithCommas.format(totals.depreciation) },
      {
        align: "right",
        value: numberWithCommas.format(totals.other_income_loss),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.total_taxable_income),
      },
      { align: "right", value: totals.tax_rate + "%" },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes_before_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_tax_from_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_taxes),
      },
    ],
  };

  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.net_operating_income[idx]),
        },
        { align: "right", value: numberWithCommas.format(r.depreciation[idx]) },
        {
          align: "right",
          value: numberWithCommas.format(r.other_income_loss[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.total_taxable_income[idx]),
        },
        { align: "right", value: r.tax_rate[idx] + "%" },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes_before_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_tax_from_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_taxes[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const getOutputSplitTableRows = (
  report: IPartnershipReportSplit,
): ITableRow[] => {
  const split_data = report.data;

  const rows: ITableRow[] = [];
  const BOLD_ROWS = [
    "capital_contributions_draws",
    "pre_tax_cash",
    "pre_tax_cash_with_tax_credits",
    "after_tax_cash",
  ];
  const HIDE_IF_ZERO_ROWS = ["direct_pay_proceeds", "transfer_proceeds"];
  PARTNERSHIP_COMBINED_TABLE_ROWS.forEach((key) => {
    const col_value = split_data[key as keyof typeof split_data];
    // If the key is in the HIDE_IF_ZERO_ROWS list and every property is 0 in it then ignore the row
    if (
      HIDE_IF_ZERO_ROWS.includes(key) &&
      Object.keys(col_value).every(
        (key) => col_value[key as keyof typeof col_value] === 0,
      )
    )
      return;
    const item =
      PARTNERSHIP_SUMMARY_REPORT_ITEMS[
        key as keyof typeof PARTNERSHIP_SUMMARY_REPORT_ITEMS
      ];
    rows.push({
      cells: [
        {
          align: "left",
          value: item.prefix + item.name + item.suffix,
          bold: Boolean(BOLD_ROWS.includes(key)),
        },
        {
          align: "right",
          value:
            typeof col_value.partnership === "number"
              ? numberWithCommas.format(Number(col_value.partnership))
              : col_value.partnership,
          bold: Boolean(BOLD_ROWS.includes(key)),
        },
        {
          align: "right",
          value:
            typeof col_value.tax_equity === "number"
              ? numberWithCommas.format(Number(col_value.tax_equity))
              : col_value.tax_equity,
          bold: Boolean(BOLD_ROWS.includes(key)),
        },
        {
          align: "right",
          value:
            typeof col_value.sponsor_equity === "number"
              ? numberWithCommas.format(Number(col_value.sponsor_equity))
              : col_value.sponsor_equity,
          bold: Boolean(BOLD_ROWS.includes(key)),
        },
        // {
        //   align: "right",
        //   value:
        //     typeof col_value.debt === "number"
        //       ? numberWithCommas.format(Number(col_value.debt))
        //       : col_value.debt,
        // },
      ],
    });
  });

  // Object.keys(split_data).forEach((key) => {
  //   const col_value = split_data[key as keyof typeof split_data];
  //   rows.push({
  //     cells: [
  //       {
  //         align: "left",
  //         value:
  //           SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
  //           .prefix +
  //           SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
  //             .name +
  //           SUMMARY_REPORT_ITEMS[key as keyof typeof SUMMARY_REPORT_ITEMS]
  //             .suffix,
  //       },
  //       {
  //         align: "right",
  //         value:
  //           typeof col_value.partnership === "number"
  //             ? numberWithCommas.format(Number(col_value.partnership))
  //             : col_value.partnership,
  //       },
  //       {
  //         align: "right",
  //         value:
  //           typeof col_value.tax_equity === "number"
  //             ? numberWithCommas.format(Number(col_value.tax_equity))
  //             : col_value.tax_equity,
  //       },
  //       {
  //         align: "right",
  //         value:
  //           typeof col_value.sponsor_equity === "number"
  //             ? numberWithCommas.format(Number(col_value.sponsor_equity))
  //             : col_value.sponsor_equity,
  //       },
  //       {
  //         align: "right",
  //         value:
  //           typeof col_value.debt === "number"
  //             ? numberWithCommas.format(Number(col_value.debt))
  //             : col_value.debt,
  //       },
  //     ],
  //   });
  // });

  return rows;
};

export const generateAccountingReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["pship/accounting(income)"];
  const totals = report["pship/accounting(income)_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      { align: "right", value: numberWithCommas.format(totals.pre_tax_cash) },
      { align: "right", value: "" },
      { align: "right", value: "" },
      {
        align: "right",
        value: numberWithCommas.format(totals.change_in_hlbv),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.deferred_tax_asset),
      },

      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_book_income),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.suspended_book_losses),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.provision_for_taxes),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.tax_effect_of_credit_transfer),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.tax_effect_of_basis_reduction),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.allocated_tax_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.other_credits),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.after_tax_book_income),
      },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_cash[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.beginning_hlbv[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.ending_hlbv[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.change_in_hlbv[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.deferred_tax_asset[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_book_income[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.suspended_book_losses[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.provision_for_taxes[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.tax_effect_of_credit_transfer[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.tax_effect_of_basis_reduction[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.allocated_tax_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.other_credits[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.after_tax_book_income[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateAccountingPamReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["pship/accounting(income_pam)"];
  const totals = report["pship/accounting(income_pam)_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_cash),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.taxable_income),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.tax_liability),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.taxable_benefit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.tax_on_taxable_benefit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.tax_credit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.total_tax_benefit),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.total_tax_benefit_proportion),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_payments),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_cash_applied_to_capital),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_basis),
      },
      {
        align: "right",
        value: numberWithCommas.format(
          totals.unadjusted_amortization_of_capital,
        ),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.adjusted_amortization_of_capital),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_amortization_balance),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.pre_tax_book_income),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.amortization_of_capital),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.total_tax_provision),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.after_tax_book_income),
      },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_cash[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.taxable_income[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.tax_liability[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.taxable_benefit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.tax_on_taxable_benefit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.tax_credit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.total_tax_benefit[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.total_tax_benefit_proportion[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_payments[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(
            r.pre_tax_cash_applied_to_capital[idx],
          ),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_basis[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(
            r.unadjusted_amortization_of_capital[idx],
          ),
        },
        {
          align: "right",
          value: numberWithCommas.format(
            r.adjusted_amortization_of_capital[idx],
          ),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_amortization_balance[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.pre_tax_book_income[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.amortization_of_capital[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.total_tax_provision[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.after_tax_book_income[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateCapitalAccountsReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["pship/capital"];
  const totals = report["pship/capital_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_contributions),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_distributions),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.basis_reduction),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.allocated_income),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.other_adjustments),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_account_balance),
      },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_contributions[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_distributions[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.basis_reduction[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.allocated_income[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.other_adjustments[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_account_balance[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateOutsideBasisReportTableRows = (
  report: IReport,
): ITableRow[] => {
  const r = report["pship/basis"];
  const totals = report["pship/basis_totals"];

  // Create a row for the totals
  const totalsRow = {
    cells: [
      { align: "left", value: "Total" },
      {
        align: "right",
        value: numberWithCommas.format(totals.capital_contributions),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.cash_distributions),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.basis_reduction),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.allocated_income),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.suspended_losses),
      },
      {
        align: "right",
        value: numberWithCommas.format(totals.excess_distributions),
      },
      { align: "right", value: numberWithCommas.format(totals.other) },
      { align: "right", value: "" },
    ],
  };

  // Generate the rows for the data
  const rows = new Array(r.payments || 0).fill(0).map((_, idx) => {
    return {
      cells: [
        { align: "left", value: r.dates[idx] },
        {
          align: "right",
          value: numberWithCommas.format(r.capital_contributions[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.cash_distributions[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.basis_reduction[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.allocated_income[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.suspended_losses[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.excess_distributions[idx]),
        },
        {
          align: "right",
          value: numberWithCommas.format(r.other[idx]),
        }, // TODO: need writeoff
        {
          align: "right",
          value: numberWithCommas.format(r.outside_basis[idx]),
        },
      ],
    };
  });

  // Add the totals row to the start and end of the rows array
  return [totalsRow, ...rows, totalsRow].map((row) => {
    return {
      cells: row.cells.map((cell) => {
        return {
          align: cell.align === "right" ? "right" : "left",
          value: cell.value,
        };
      }),
    };
  });
};

export const generateLessorLesseeSummaryReport = (
  data: IOutputLessorLesseeSummary,
): ITableRow[] => {
  return [
    {
      cells: [
        { align: "left", value: "After-Tax IRR" },
        { align: "right", value: data.data.metrics.atc_irr.toFixed(2) + "%" },
      ],
    },
    {
      cells: [
        { align: "left", value: "Pre-Tax IRR" },
        { align: "right", value: data.data.metrics.ptc_irr.toFixed(2) + "%" },
      ],
    },
    {
      cells: [
        { align: "left", value: "After-Tax IRR (EBO)" },
        {
          align: "right",
          value: data.data.metrics.atc_irr_ebo.toFixed(2) + "%",
        },
      ],
    },
    {
      cells: [
        { align: "left", value: "Pre-Tax IRR (EBO)" },
        {
          align: "right",
          value: data.data.metrics.ptc_irr_ebo.toFixed(2) + "%",
        },
      ],
    },
  ];
};

export const generateDeveloperCashFlowSummaryReport = (
  report: IDeveloperReportSummary,
) => {
  const reportData = report.data.cashflow;
  const rows: ITableRow[] = [];

  Object.keys(DEVELOPER_REPORT_CASHFLOW_ITEMS).forEach((key) => {
    if (key in reportData) {
      const cellValue =
        reportData[key as DeveloperReportSummaryKeyType<"cashflow">];
      const cellData =
        DEVELOPER_REPORT_CASHFLOW_ITEMS[
          key as Exclude<
            DeveloperReportSummaryKeyType<"cashflow">,
            "equity_contributions" | "project_cost"
          >
        ];

      rows.push({
        cells: [
          {
            align: "left",
            bold: cellData.bold,
            value: cellData.name,
          },
          {
            align: "right",
            bold: cellData.bold,
            value:
              typeof cellValue === "number"
                ? numberWithCommas.format(cellValue) + (cellData?.suffix || "")
                : "N/A",
          },
        ],
      });
    }
  });

  return rows;
};

export const generateDeveloperReportSummary = (
  report: IDeveloperReportSummary,
): ITableRow[] => {
  const reportData = report.data.summary;
  const rows: ITableRow[] = [];

  Object.keys(DEVELOPER_REPORT_SUMMARY_ITEMS).forEach((key, idx) => {
    if (key in reportData) {
      const cellValue =
        reportData[key as DeveloperReportSummaryKeyType<"summary">];
      const cellData =
        DEVELOPER_REPORT_SUMMARY_ITEMS[
          key as DeveloperReportSummaryKeyType<"summary">
        ];

      rows.push({
        cells: [
          {
            align: "left",
            value: cellData.name,
          },
          {
            align: "right",
            value:
              typeof cellValue === "number"
                ? (cellData?.prefix || "") +
                  numberWithCommas.format(cellValue) +
                  (cellData?.suffix || "")
                : "N/A",
          },
        ],
      });
    }
  });

  return rows;
};

export const generateDeveloperCashReportRows = (
  report: IDeveloperCashReport,
  columns: ITableColumn<keyof IDeveloperCashReport["data"]["items"]>[],
): ITableRow[] => {
  const r = report.data.items;
  const total = report.data.totals;

  const totalsRow = columns.map((column) => {
    const rawValue = column.id === "dates" ? "Total" : total[column.id];

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue)
          : rawValue,
    };
  });

  const rows = new Array(r.after_tax_cash.length || 0).fill(0).map((_, idx) => {
    return columns.map((column) => {
      const rawValue = r[column.id] ? r[column.id][idx] : "N/A";

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue !== "string"
            ? numberWithCommas.format(rawValue)
            : rawValue,
      };
    });
  });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateDeveloperTaxReportRows = (
  report: IDeveloperTaxReport,
): ITableRow[] => {
  const r = report.data.items;
  const total = report.data.totals;

  const totalsRow = DEVELOPER_TAX_REPORT_TABLE_COLUMNS.map((column) => {
    const rawValue = column.id === "dates" ? "Total" : total[column.id];

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue) + (column.suffix || "")
          : rawValue,
    };
  });

  const rows = new Array(r.cash_taxes.length || 0).fill(0).map((_, idx) => {
    return DEVELOPER_TAX_REPORT_TABLE_COLUMNS.map((column) => {
      const rawValue = r[column.id as keyof typeof r][idx];

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue !== "string"
            ? numberWithCommas.format(rawValue) + (column.suffix || "")
            : rawValue + (column.suffix || ""),
      };
    });
  });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateSponsorEquityBOL_ReportSummaryRows = (
  report: ISponsorEquityBOL_ReportSummary,
): ITableRow[] => {
  const metrics = report.data.metrics;
  const rows: ITableRow[] = [];

  Object.keys(SPONSOR_EQUITY_BOL_SUMMARY_ITEMS).forEach((key) => {
    if (key in metrics) {
      let rawValue =
        metrics[key as SponsorEquityBOL_ReportSummaryMetricsForView]
          .stakeholder_amount || "";

      const cellData =
        SPONSOR_EQUITY_BOL_SUMMARY_ITEMS[
          key as SponsorEquityBOL_ReportSummaryMetricsForView
        ];

      if (cellData.enum) {
        rawValue = cellData.enum[rawValue];
      }

      rows.push({
        cells: [
          {
            align: "left",
            value: cellData.name,
          },
          {
            align: "right",
            value:
              cellData.format && typeof rawValue !== "string"
                ? numberWithCommas.format(rawValue) + (cellData.suffix || "")
                : rawValue + (cellData.suffix || ""),
          },
        ],
      });
    }
  });
  return rows;
};

export function getSponsorEquityBOLCashReportColumns(
  tax_credit_structure: "DP" | "TR" | "REG" | undefined,
  buyout: ReportTerm,
): ITableColumn<keyof ISponsorEquityBOL_CashReport["data"]["items"]>[] {
  const columns = [...SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_COLUMNS];

  // Add buyout payment column if report term is buyout
  if (buyout === "buyout") {
    const targetColumnId = "distributions";
    const targetIndex = columns.findIndex(
      (column) => column.id === targetColumnId,
    );

    if (targetIndex !== -1) {
      columns.splice(targetIndex + 1, 0, {
        id: "buyout_payment",
        label: "Buyout Payment",
        minWidth: 150,
        align: "right",
        formatNumbers: true,
      });
    }
  }

  // Determine which additional columns to add based on the deal structure
  if (tax_credit_structure === "DP") {
    // Direct Pay
    const targetColumnId = "distributions";
    const targetIndex = columns.findIndex(
      (column) => column.id === targetColumnId,
    );

    if (targetIndex !== -1) {
      columns.splice(
        targetIndex + 1,
        0,
        ...SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_DIRECT_PAY_ADDITIONAL_COLUMNS,
      );
    }
  } else if (tax_credit_structure === "TR") {
    // Transfer
    const targetColumnId = "distributions";
    const targetIndex = columns.findIndex(
      (column) => column.id === targetColumnId,
    );

    if (targetIndex !== -1) {
      columns.splice(
        targetIndex + 1,
        0,
        ...SPONSOR_EQUITY_BOL_CASH_REPORT_TABLE_TRANSFER_ADDITIONAL_COLUMNS,
      );
    }
  }

  return columns;
}

export const generateSponsorEquityBOL_CashReportRows = (
  report: ISponsorEquityBOL_CashReport,
  tax_credit_structure: "DP" | "TR" | "REG" | undefined,
  buyout: ReportTerm,
): ITableRow[] => {
  const r = report.data.items;
  const total = report.data.totals;

  const columns = getSponsorEquityBOLCashReportColumns(
    tax_credit_structure,
    buyout,
  );

  const totalsRow = columns.map((column) => {
    const rawValue = column.id === "dates" ? "Total" : total[column.id];

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue)
          : rawValue,
    };
  });

  const rows = new Array(r.after_tax_cash.length || 0).fill(0).map((_, idx) => {
    return columns.map((column) => {
      const rawValue = r[column.id as keyof typeof r][idx];

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue !== "string"
            ? numberWithCommas.format(rawValue)
            : rawValue,
      };
    });
  });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateSponsorEquityBOL_TaxReportRows = (
  report: ISponsorEquityBOL_TaxReport,
): ITableRow[] => {
  const r = report.data.items;
  const total = report.data.totals;

  const totalsRow = SPONSOR_EQUITY_BOL_TAX_REPORT_TABLE_COLUMNS.map(
    (column) => {
      const rawValue = column.id === "dates" ? "Total" : total[column.id];

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue !== "string"
            ? numberWithCommas.format(rawValue) + (column.suffix || "")
            : rawValue,
      };
    },
  );

  const rows = new Array(r.cash_taxes.length || 0).fill(0).map((_, idx) => {
    return SPONSOR_EQUITY_BOL_TAX_REPORT_TABLE_COLUMNS.map((column) => {
      const rawValue = r[column.id as keyof typeof r][idx];

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue !== "string"
            ? numberWithCommas.format(rawValue) + (column.suffix || "")
            : rawValue,
      };
    });
  });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateConstructionDebtLoanReportTableRows = (
  report: IConstructionDebtLoanReport,
): ITableRow[] => {
  const r = report["data"]["items"];
  const totals = report["data"]["totals"];

  const totalsRow = CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS.map((column) => {
    let rawValue: number | string = "";
    if (column.id === "dates") {
      rawValue = "Total";
    } else if (column.totalVisible === false) {
      rawValue = "";
    } else {
      rawValue = totals[column.id as keyof typeof totals] || "";
    }

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue) + (column.suffix || "")
          : rawValue,
    };
  });

  const rows = new Array(r.beginning_balance.length || 0)
    .fill(0)
    .map((_, idx) => {
      return CONSTRUCTION_DEBT_LOAN_REPORT_COLUMNS.map((column) => {
        const rawValue = r[column.id as keyof typeof r][idx];

        return {
          align: column.align,
          value:
            column.formatNumbers && typeof rawValue === "number"
              ? numberWithCommas.format(rawValue) + (column.suffix || "")
              : rawValue,
        };
      });
    });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateConstructionDebtCashReportTableRows = (
  report: IConstructionDebtCashReport,
): ITableRow[] => {
  const r = report["data"]["items"];
  const totals = report["data"]["totals"];

  const totalsRow = CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS.map((column) => {
    let rawValue: number | string = "";
    if (column.id === "dates") {
      rawValue = "Total";
    } else if (column.totalVisible === false) {
      rawValue = "";
    } else {
      rawValue = totals[column.id as keyof typeof totals];
    }

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue) + (column.suffix || "")
          : rawValue,
    };
  });

  const rows = new Array(r.deal_project_cost.length || 0)
    .fill(0)
    .map((_, idx) => {
      return CONSTRUCTION_DEBT_CASH_REPORT_COLUMNS.map((column) => {
        const rawValue = r[column.id as keyof typeof r][idx];

        return {
          align: column.align,
          value:
            column.formatNumbers && typeof rawValue === "number"
              ? numberWithCommas.format(rawValue) + (column.suffix || "")
              : rawValue,
        };
      });
    });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateConstructionDebtEquityReportTableRows = (
  report: IConstructionDebtEquityReport,
): ITableRow[] => {
  const r = report["data"]["items"];
  const totals = report["data"]["totals"];

  const totalsRow = CONSTRUCTION_DEBT_EQUITY_REPORT_COLUMNS.map((column) => {
    let rawValue: number | string = "";
    if (column.id === "dates") {
      rawValue = "Total";
    } else if (column.totalVisible === false) {
      rawValue = "";
    } else {
      rawValue = totals[column.id as keyof typeof totals];
    }

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue) + (column.suffix || "")
          : rawValue,
    };
  });

  const rows = new Array(r.beginning_balance.length || 0)
    .fill(0)
    .map((_, idx) => {
      return CONSTRUCTION_DEBT_EQUITY_REPORT_COLUMNS.map((column) => {
        const rawValue = r[column.id as keyof typeof r][idx];

        return {
          align: column.align,
          value:
            column.formatNumbers && typeof rawValue === "number"
              ? numberWithCommas.format(rawValue) + (column.suffix || "")
              : rawValue,
        };
      });
    });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const generateConstructionDebtAdditionalTableRows = (
  sources: IConstructionDebtSummary["data"]["sources"],
): ITableRow[] => {
  const labels: Record<keyof typeof sources, string> = {
    construction_loan: "Construction Loan",
    tax_equity_bridge_loan: "Investor Bridge Loan",
    equity: "Sponsor Equity",
    total: "Total Sources",
  };

  const defaultRow = (label: string, bold: boolean = false) => ({
    cells: [
      { value: label, bold },
      { value: "N/A", bold },
      { value: "N/A", bold },
      { value: "N/A", bold },
    ],
  });

  if (!sources) {
    return Object.keys(labels).map((key) =>
      defaultRow(labels[key as keyof typeof labels], key === "total"),
    );
  }

  return Object.keys(labels).map((key) => {
    const label = labels[key as keyof typeof labels] || key;
    const bold = key === "total";
    const item = sources[key as keyof typeof sources];
    return {
      cells: [
        { value: label, bold },
        { value: numberWithCommas.format(item.amount ?? 0), bold },
        {
          value: parseFloat((item.amount_per_watt_dc ?? 0).toFixed(2)),
          bold,
        },
        {
          value:
            parseFloat((item.percentage_of_total_cost ?? 0).toFixed(2)) + "%",
          bold,
        },
      ],
    };
  });
};

const transformReportTableV2Rows = (
  rawRows: IReportTable["rows"],
  columns: GridColDef[],
  generatedRows: GridRowModel<GridValidRowModel>[],
  hierarchy: string[],
  supportsTotal: boolean,
  nestedStep: number,
) => {
  rawRows.forEach((row) => {
    const generatedRow: GridRowModel<GridValidRowModel> = {
      id: uniqueId(),
    };

    hierarchy = cloneDeep([...hierarchy.slice(0, nestedStep), row.label]);

    generatedRow["hierarchy"] = cloneDeep(hierarchy);
    generatedRow["Name"] = row.label;

    if (supportsTotal) {
      const total =
        typeof row.total === "number"
          ? formatNumberWithDecimals(row.total, 10)
          : row.total;
      generatedRow["Total"] = total;
    }

    row.values.forEach((v, rIdx) => {
      let value = v;
      if (typeof v === "number") {
        value = formatNumberWithDecimals(v, 10);
      }
      generatedRow[columns[rIdx + (supportsTotal ? 1 : 0)].field] = value;
    });

    generatedRows.push(generatedRow);

    if (row.components) {
      transformReportTableV2Rows(
        row.components,
        columns,
        generatedRows,
        hierarchy,
        supportsTotal,
        nestedStep + 1,
      );
    }
  });

  return generatedRows;
};

const commonReportTableColDef: Omit<GridColDef, "field"> = {
  minWidth: 150,
  sortable: false,
  align: "right",
  headerAlign: "right",
};

export const generateReportTableV2Data = (
  rawData: IReportTable,
): { rows: GridRowsProp; columns: GridColDef[] } => {
  const totalColumn: GridColDef[] = rawData.supports_total
    ? [{ field: "Total", ...commonReportTableColDef }]
    : [];

  const columns: GridColDef[] = [
    ...totalColumn,
    ...rawData.dates.map(
      (date): GridColDef => ({
        field: date,
        ...commonReportTableColDef,
      }),
    ),
  ];

  const rows = cloneDeep(
    transformReportTableV2Rows(
      rawData.rows,
      columns,
      [],
      [],
      rawData.supports_total,
      0,
    ),
  );

  return { rows, columns };
};

export const generateDebtCoverageReportTableRows = (
  report: IDebtCoverageReport,
): ITableRow[] => {
  const r = report.data.items;
  const totals = report.data.totals;

  const totalsRow = DEBT_COVERAGE_REPORT_TABLE_COLUMNS.map((column) => {
    let rawValue: number | string = "";
    if (column.id === "dates") {
      rawValue = "Total";
    } else if (column.id === "coverage_ratio") {
      rawValue = "";
    } else {
      const mappings: Record<string, keyof typeof totals> = {
        cf: "total_cf",
        cf_adjusted: "total_cf_periodicity_adjusted",
        cfads: "total_cfads",
        cfads_adjusted: "total_cfads_periodicity_adjusted",
        debt_service: "debt_service",
      };
      rawValue = totals[mappings[column.id]];
    }

    return {
      align: column.align,
      value:
        column.formatNumbers && typeof rawValue !== "string"
          ? numberWithCommas.format(rawValue)
          : rawValue,
    };
  });

  const rows = new Array(r.dates.length || 0).fill(0).map((_, idx) => {
    return DEBT_COVERAGE_REPORT_TABLE_COLUMNS.map((column) => {
      let rawValue: string | number | null = "";

      const mappings: Record<string, keyof typeof r> = {
        dates: "dates",
        cf: "total_cf",
        cf_adjusted: "total_cf_periodicity_adjusted",
        cfads: "total_cfads",
        cfads_adjusted: "total_cfads_periodicity_adjusted",
        debt_service: "debt_service",
        coverage_ratio: "coverage_ratio",
      };

      rawValue = r[mappings[column.id]][idx];

      return {
        align: column.align,
        value:
          column.formatNumbers && typeof rawValue === "number"
            ? numberWithCommas.format(rawValue)
            : rawValue === null
              ? ""
              : rawValue,
      };
    });
  });

  return [totalsRow, ...rows, totalsRow].map((row) => ({
    cells: row.map((cell) => ({
      align: cell.align,
      value: cell.value,
    })),
  }));
};

export const getOutputPortfolioConstructionRowsUses = (
  data: IPortfolioSourcesAndUsesConstruction["data"] | undefined,
) => {
  if (!data?.uses) return [];

  return [
    ...data.uses.costs.map((cost) => ({
      cells: [
        { value: cost.name, align: "left" },
        {
          value: formatNumberWithDecimals(cost.cost, 2),
          align: "right",
        },
      ],
    })),
    {
      cells: [
        { value: "Total Project Costs", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.uses.total_project_cost, 2),
          align: "right",
          bold: true,
        },
      ],
    },
    {
      cells: [
        { value: "Construction Interest & Fees", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.uses.construction_interest_and_fee,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Deal Allocated Costs", align: "left" },
        {
          value: formatNumberWithDecimals(data.uses.deal_allocated_cost, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Deal Project Cost", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.uses.deal_project_cost, 2),
          align: "right",
          bold: true,
        },
      ],
    },
    {
      cells: [
        { value: "Cash Collateral Funded", align: "left" },
        {
          value: formatNumberWithDecimals(data.uses.cash_collateral_funded, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Cash Collateral Released", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.uses.cash_collateral_released,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Net Cash Collateral", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.uses.net_cash_collateral, 2),
          align: "right",
          bold: true,
        },
      ],
    },
    {
      cells: [
        { value: "Total Uses", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.uses.total_uses, 2),
          align: "right",
          bold: true,
        },
      ],
    },
  ];
};

export const getOutputPortfolioConstructionRowsSources = (
  data: IPortfolioSourcesAndUsesConstruction["data"] | undefined,
  onBreakdownClick: (event: React.MouseEvent<HTMLElement>) => void,
  popper: React.ReactNode,
) => {
  if (!data?.sources) return [];

  return [
    {
      cells: [
        { value: "Investor Bridge Loan", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.sources.tax_equity_bridge_loan,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Construction Loan", align: "left" },
        {
          value: formatNumberWithDecimals(data.sources.construction_loan, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        {
          value: (
            <div
              className="underline cursor-pointer"
              onClick={onBreakdownClick}
            >
              Equity During Construction
              <span>{popper}</span>
            </div>
          ),
          align: "left",
        },
        {
          value: formatNumberWithDecimals(
            data.sources.equity_during_construction_absolute,
            2,
          ),
          align: "right",
        },
      ],
    },
    // Add 12 empty rows to align total sources with total uses
    ...Array.from({ length: 12 }).map((_, idx) => ({
      cells: [
        { value: "", align: "left" },
        { value: "", align: "right" },
      ],
    })),
    {
      cells: [
        { value: "Total Sources", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.sources.total_sources, 2),
          align: "right",
          bold: true,
        },
      ],
    },
  ];
};

export const getOutputPortfolioCODRowsUses = (
  data: IPortfolioSourcesAndUsesCOD["data"] | undefined,
) => {
  if (!data?.uses) return [];

  return [
    {
      cells: [
        { value: "Investor Bridge Loan Repayment", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.uses.tax_equity_bridge_loan_repayment,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Construction Loan Repayment", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.uses.construction_loan_repayment,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Developer Equity Repayment", align: "left" },
        {
          value: formatNumberWithDecimals(
            data.uses.developer_equity_repayment,
            2,
          ),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Deal Fees", align: "left" },
        {
          value: formatNumberWithDecimals(data.uses.deal_fees, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "FMV Step Up", align: "left" },
        {
          value: formatNumberWithDecimals(data.uses.fmv_step_up, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Total Uses", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.uses.total_uses, 2),
          align: "right",
          bold: true,
        },
      ],
    },
  ];
};

export const getOutputPortfolioCODRowsSources = (
  data: IPortfolioSourcesAndUsesCOD["data"] | undefined,
  onBreakdownClick: (event: React.MouseEvent<HTMLElement>) => void,
  popper: React.ReactNode,
) => {
  if (!data?.sources) return [];

  return [
    {
      cells: [
        { value: "Tax Equity", align: "left" },
        {
          value: formatNumberWithDecimals(data.sources.tax_equity, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "Back-Leverage", align: "left" },
        {
          value: formatNumberWithDecimals(data.sources.backleverage_debt, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        {
          value: (
            <div
              className="underline cursor-pointer"
              onClick={onBreakdownClick}
            >
              Sponsor Equity
              <span>{popper}</span>
            </div>
          ),
          align: "left",
        },
        {
          value: formatNumberWithDecimals(data.sources.sponsor_equity, 2),
          align: "right",
        },
      ],
    },
    {
      cells: [
        { value: "FMV Step Up", align: "left" },
        {
          value: formatNumberWithDecimals(data.sources.fmv_step_up, 2),
          align: "right",
        },
      ],
    },
    // Add 1 empty row to align total sources with total uses
    ...Array.from({ length: 1 }).map((_, idx) => ({
      cells: [
        { value: "", align: "left" },
        { value: "", align: "right" },
      ],
    })),
    {
      cells: [
        { value: "Total Sources", align: "left", bold: true },
        {
          value: formatNumberWithDecimals(data.sources.total_sources, 2),
          align: "right",
          bold: true,
        },
      ],
    },
  ];
};

export const getOutputPortfolioSourcesAndUsesConBreakdown = (
  data: IPortfolioSourcesAndUsesConstruction["data"] | undefined,
) => {
  return data?.sources
    ? [
        {
          cells: [
            {
              value: "Equity During Construction Breakdown",
              align: "left",
              bold: true,
            },
            { value: "", align: "right" },
          ],
        },
        {
          cells: [
            { value: "(-) Equity Contributions", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.equity_contributions,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(-) Excess Minimum Equity", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.excess_minimum_equity,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(-) Cash Collateral Funded", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.cash_collateral_funded,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(+) Cash Collateral Released", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.cash_collateral_released,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "Equity During Construction", align: "left", bold: true },
            {
              value: formatNumberWithDecimals(
                data?.sources.equity_during_construction,
                2,
              ),
              align: "right",
              bold: true,
            },
          ],
        },
      ]
    : [];
};

export const getOutputPortfolioSourcesAndUsesCodBreakdown = (
  data: IPortfolioSourcesAndUsesCOD["data"] | undefined,
) => {
  return data?.sources
    ? [
        {
          cells: [
            { value: "Sponsor Equity Breakdown", align: "left", bold: true },
            { value: "", align: "right" },
          ],
        },
        {
          cells: [
            { value: "(-) Share of Deal FMV", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.share_of_deal_fmv,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(-) Share of Deal Fees", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.share_of_deal_fees,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            {
              value: "Capital Contributions",
              align: "left",
              bold: true,
            },
            {
              value: formatNumberWithDecimals(
                data?.sources.capital_contributions,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(+) FMV Step Up", align: "left" },
            {
              value: formatNumberWithDecimals(data?.sources.fmv_step_up, 2),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "(+) Term Debt Proceeds", align: "left" },
            {
              value: formatNumberWithDecimals(
                data?.sources.term_debt_proceeds,
                2,
              ),
              align: "right",
            },
          ],
        },
        {
          cells: [
            { value: "Net Equity", align: "left", bold: true },
            {
              value: formatNumberWithDecimals(data?.sources.net_equity, 2),
              align: "right",
            },
          ],
        },
      ]
    : [];
};

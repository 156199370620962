import { PROJECT_API_ROUTES } from "../../constants";
import {
  AxiosHelper,
  replaceParams,
  transformBlobToFile,
} from "../../utils/helpers";
import {
  IProjectRevenue,
  IProjectRevenueForm,
  IProjectMerchantRevenue,
  IProjectMerchantRevenueForm,
  ServerPaginatedResponse,
  IReportTable,
} from "../../interfaces";

export const getProjectRevenueContracts = async (projectId: number) => {
  const url = replaceParams(PROJECT_API_ROUTES.revenueContracts.get, {
    projectId,
  });
  return await AxiosHelper.get<ServerPaginatedResponse<IProjectRevenue[]>>(url);
};

export const getProjectRevenueContractDetails = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.revenueContracts.getDetails, {
    projectId,
    revenueId,
  });
  return await AxiosHelper.get<IProjectRevenue>(url);
};

export const addProjectRevenueContract = async (
  projectId: number,
  form: IProjectRevenueForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.revenueContracts.create, {
    projectId,
  });
  return await AxiosHelper.post<IProjectRevenueForm, IProjectRevenue>(
    url,
    form,
  );
};

export const editProjectRevenueContract = async (
  projectId: number,
  revenueId: number,
  form: IProjectRevenueForm,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.revenueContracts.update, {
    projectId,
    revenueId,
  });
  return await AxiosHelper.patch<IProjectRevenueForm, IProjectRevenue>(
    url,
    form,
  );
};

export const deleteProjectRevenueContract = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(PROJECT_API_ROUTES.revenueContracts.delete, {
    projectId,
    revenueId,
  });
  return await AxiosHelper.delete(url);
};

export const deleteProjectMerchantRevenueContract = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.deleteMerchantRevenue,
    {
      projectId,
      revenueId,
    },
  );
  return await AxiosHelper.delete(url);
};

export const getProjectMerchantRevenues = async (projectId: number) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.getMerchantRevenues,
    {
      projectId,
    },
  );
  return await AxiosHelper.get<
    ServerPaginatedResponse<IProjectMerchantRevenue[]>
  >(url);
};

export const getProjectMerchantRevenue = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.getMerchantRevenue,
    {
      projectId,
      revenueId,
    },
  );
  return await AxiosHelper.get<IProjectMerchantRevenue>(url);
};

export const updateMerchantRevenue = async (
  projectId: number,
  revenueId: number,
  form: IProjectMerchantRevenueForm,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.updateMerchantRevenue,
    {
      projectId,
      revenueId,
    },
  );
  return await AxiosHelper.patch<
    IProjectMerchantRevenueForm,
    IProjectMerchantRevenue
  >(url, form);
};

export const addMerchantRevenue = async (
  projectId: number,
  form: IProjectMerchantRevenueForm,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.addMerchantRevenue,
    {
      projectId,
    },
  );
  return await AxiosHelper.post<
    IProjectMerchantRevenueForm,
    IProjectMerchantRevenue
  >(url, form);
};

export const getProjectContractedRevenueComponents = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.contractedRevenueComponents,
    {
      projectId,
      revenueId,
    },
  );

  return await AxiosHelper.get<IReportTable>(url);
};

export const getProjectMerchantRevenueComponents = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.merchantRevenueComponents,
    {
      projectId,
      revenueId,
    },
  );

  return await AxiosHelper.get<IReportTable>(url);
};

export const downloadRevenueContractComponents = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.downloadRevenueContractComponents,
    {
      projectId,
      revenueId,
    },
  );

  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${projectId}-revenue-contracts-components-report.xlsx`,
    );
  });
};

export const downloadMerchantRevenueComponents = async (
  projectId: number,
  revenueId: number,
) => {
  const url = replaceParams(
    PROJECT_API_ROUTES.revenueContracts.downloadMerchantRevenueComponents,
    {
      projectId,
      revenueId,
    },
  );

  return await AxiosHelper.get<Blob>(url, {
    responseType: "arraybuffer",
  }).then((response) => {
    transformBlobToFile(
      response,
      `project-${projectId}-merchant-revenue-components-report.xlsx`,
    );
  });
};

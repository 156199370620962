import {
  IAddDealCaseComparisonForm,
  IDuplicateDealCaseForm,
  IUpdateDealCaseComparisonForm,
  IUpdateDealCaseForm,
} from "../../interfaces";
import { enumToOptions } from "../../utils/helpers";

export enum DEAL_CASE_TYPE {
  SCEN = "Scenario",
  SENS = "Sensitivity",
}

export const DEAL_CASE_TYPE_OPTIONS = enumToOptions(DEAL_CASE_TYPE);

export enum DEAL_CASE_PROJECT_TAX_CREDIT_TYPE {
  NC = "No Change",
  NONE = "None",
  ITC = "ITC",
  PTC = "PTC",
}

export const DEAL_CASE_PROJECT_TAX_CREDIT_TYPE_OPTIONS = enumToOptions(
  DEAL_CASE_PROJECT_TAX_CREDIT_TYPE,
);

export const ADD_CASE_COMPARISON_FORM_DEFAULT_STATE: IAddDealCaseComparisonForm =
  {
    name: "",
    base_case_deal: "",
    deal_ids: [],
  };

export const UPDATE_CASE_COMPARISON_FORM_DEFAULT_STATE: IUpdateDealCaseComparisonForm =
  {
    base_case_deal: "",
    deal_ids: [],
    name: "",
    uuid: "",
  };

export const DUPLICATE_CASE_FORM_DEFAULT_STATE: IDuplicateDealCaseForm = {
  id: "",
  name: "",
  description: "",
};

export const UPDATE_CASE_FORM_DEFAULT_STATE: IUpdateDealCaseForm = {
  id: "",
  name: "",
  description: "",
};

export const DEAL_FEES_TABLE_COLUMN_HEADERS = [
  "Fee Type",
  "Name",
  "Allocation Method",
  "Amount",
];

export const DEAL_CREDIT_SUPPORTS_TABLE_COLUMN_HEADERS = [
  "Type",
  "Amount",
  "Start Date Type",
  "Start Date",
  "Start Point",
  "End Date Type",
  "End Date",
  "End Point",
  "Security Type",
  "Fee (%)",
];

export const DEAL_DEPRECIATION_STREAMS_TABLE_COLUMN_HEADERS = [
  "Type",
  "Amount",
  "Percentage of FMV",
  "ITC Eligibility",
  "ITC Eligibile Amount",
];

export const DEAL_REVENUES_TABLE_COLUMN_HEADERS = [
  "Type",
  "Start Date",
  "Term (Yrs)",
  "Price",
  "Escalator",
];

export const DEAL_MERCHANT_REVENUE_TABLE_COLUMN_HEADERS = [
  "Type",
  "Name",
  "Price",
  "Escalator",
  "Cash Lag (Months)",
];

export const DEAL_EXPENSES_TABLE_COLUMN_HEADERS = [
  "Type",
  "Name",
  "Expense (Unit)",
  "Esc.",
  "Start Date",
  "Term",
];

export const DEAL_OTHER_FLOWS_TABLE_COLUMN_HEADERS = [
  "Name",
  "Type",
  "Created",
  "Modified",
];

import React from "react";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import FilterIcon from "@mui/icons-material/FilterAlt";
import ClearIcon from "@mui/icons-material/Refresh";
import MuiButton from "@mui/material/Button";
import { Protect } from "@clerk/clerk-react";
import { useNavigate, useSearchParams } from "react-router-dom";

import useStyles from "./styles";
import Searchbar from "../../components/search-bar";
import Button from "../../components/button";
import ProjectFormModal from "../../components/project-form-modal";
import ProjectsTable from "../../components/projects-table";
import ProjectFilters from "../../components/project-filters";
import ViewWrapper from "../../components/view-wrapper";
import { useAPI } from "../../utils/hooks";
import { cn, updateStageInProjectList } from "../../utils/helpers";
import {
  FORM_TYPES,
  DEAL_STATUS_OPTIONS,
  PROJECTS_TABLE_COLUMNS,
  PROJECT_FORM_DEFAULT_STATE,
  PROJECT_STAGE_CONSTRUCTION,
  PROJECT_STAGE_DEVELOPMENT,
  PROJECT_STAGE_OPERATION,
  USER_PERMISSIONS,
} from "../../constants";
import {
  IProject,
  ITableSort,
  IProjectForm,
  ServerPaginatedResponse,
  IProjectFormErrors,
  IGetDealsParams,
  ProjectStageMap,
  IAddUpdateProjectResponse,
} from "../../interfaces";
import Tabs from "../../components/tabs";

interface IProps {
  getProjects: (
    params: IGetDealsParams,
  ) => Promise<ServerPaginatedResponse<IProject[]>>;
  addProject: (form: IProjectForm) => Promise<IAddUpdateProjectResponse>;
}

const mapProjectStage = {
  DEV: PROJECT_STAGE_DEVELOPMENT,
  CON: PROJECT_STAGE_CONSTRUCTION,
  OP: PROJECT_STAGE_OPERATION,
};

const mapSubStage = (stage: keyof ProjectStageMap) =>
  Object.keys(mapProjectStage[stage]).join(",");

export default function ProjectList({
  getProjects,
  addProject,
}: IProps): JSX.Element {
  const isFirstLoad = React.useRef(true);
  const styles = useStyles();
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useSearchParams();

  const [projects, setProjects] = React.useState<IProject[]>([]);
  const [searchString, setSearchString] = React.useState<string>("");
  const [addProjectModalOpen, setAddProjectModalOpen] =
    React.useState<boolean>(false);
  const [projectForm, setProjectForm] = React.useState<IProjectForm>(
    PROJECT_FORM_DEFAULT_STATE,
  );
  const [sortTable, setSortTable] = React.useState<ITableSort>({
    orderBy: "",
    order: "asc",
  });

  const isFiltersApplied = React.useMemo(() => {
    const energy_type = filterParams.get("energy_type");
    const state = filterParams.get("state");
    const sub_stage = filterParams.get("sub_stage");
    const cod = filterParams.get("cod");
    const tax_credit__credit_type = filterParams.get("tax_credit__credit_type");
    const power_market = filterParams.get("power_market");
    return Boolean(
      energy_type ||
        state ||
        sub_stage ||
        cod ||
        tax_credit__credit_type ||
        power_market,
    );
  }, []);

  const [isFilterOpen, setIsFilterOpen] = React.useState(isFiltersApplied);

  React.useEffect(() => {
    if (!isFirstLoad.current) {
      const status = filterParams.get("status");
      if (filterParams.size === 1 && status) return;
    }

    isFirstLoad.current = false;

    const energy_type = filterParams.get("energy_type");
    const stage = filterParams.get("project_stage");
    const state = filterParams.get("state");
    const cod = filterParams.get("cod");
    const tax_credit__credit_type = filterParams.get("tax_credit__credit_type");
    const power_market = filterParams.get("power_market");

    let sub_stage = filterParams.get("sub_stage");
    if (mapProjectStage[stage as keyof ProjectStageMap]) {
      sub_stage = mapSubStage(stage as keyof ProjectStageMap);
    }

    getProjectsCallAPI({
      energy_type,
      state,
      sub_stage,
      cod,
      tax_credit__credit_type,
      power_market,
    }).then((response) => {
      response && setProjects(updateStageInProjectList(response.results));
    });
  }, [filterParams]);

  const {
    callAPI: getProjectsCallAPI,
    errored: getProjectsFailed,
    loading: loadingProjects,
  } = useAPI((params) => getProjects({ ...params }), { initialLoading: true });

  const {
    callAPI: addProjectCallAPI,
    fieldErrors: addProjectFormErrors,
    setFieldErrors: setAddProjectFormErrors,
    loading: addProjectLoading,
  } = useAPI<IAddUpdateProjectResponse, IProjectFormErrors>(
    (form: IProjectForm) => addProject(form),
  );

  const handleAddProject = async (form: IProjectForm) => {
    const project = await addProjectCallAPI(form);
    project && goToProject(project.id);
    return project;
  };

  const filteredBySearchProjects: IProject[] = React.useMemo(() => {
    return projects.filter(
      (d) =>
        d.name.toLowerCase().includes(searchString.toLowerCase()) &&
        d.status ===
          (filterParams.get("status")
            ? filterParams.get("status")
            : DEAL_STATUS_OPTIONS[0].value),
    );
  }, [projects, searchString, filterParams]);

  const handleOpenAddProjectModal = () => {
    setAddProjectModalOpen(true);
  };

  const handleCloseAddProjectModal = () => {
    setAddProjectModalOpen(false);
  };

  const goToProject = (projectId: number) => {
    navigate(`/project/${projectId}/general`);
  };

  const onSearchStringChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchString(e.target.value);
  };

  const clearFilter = () => {
    setFilterParams();
    setIsFilterOpen(false);
  };

  return (
    <>
      <Box className={cn("p-4")}>
        <Box
          className={cn(
            "flex flex-col md:flex-row md:justify-between md:items-start gap-4 mb-4",
          )}
        >
          <Box className={cn("flex flex-col md:flex-row md:items-start gap-4")}>
            <Searchbar
              searchString={searchString}
              onTextChange={onSearchStringChange}
            />
            <MuiButton
              className={`${styles.classes.button} ${
                isFiltersApplied && styles.classes.activeButton
              }`}
              variant="outlined"
              size="large"
              endIcon={<FilterIcon />}
              onClick={() => setIsFilterOpen((v) => !v)}
            >
              Filters
            </MuiButton>
            {isFilterOpen && (
              <MuiButton
                className={cn("!capitalize")}
                variant="outlined"
                size="large"
                endIcon={<ClearIcon />}
                onClick={clearFilter}
              >
                Clear All
              </MuiButton>
            )}
          </Box>
          <Protect permission={USER_PERMISSIONS.PROJECTS_CRUD}>
            <Button
              canOpenUpgrade
              startIcon={<AddIcon />}
              btnType="primary"
              label="Add Project"
              onClick={handleOpenAddProjectModal}
            />
          </Protect>
        </Box>
        <Box>{isFilterOpen && <ProjectFilters />}</Box>
        <Box className={cn(`h-[calc(100vh_-_${isFilterOpen ? 148 : 165}px)]`)}>
          <ViewWrapper loading={loadingProjects} error={getProjectsFailed}>
            <Tabs
              selectedTab={
                filterParams.get("status") || DEAL_STATUS_OPTIONS[0].value
              }
              tabs={DEAL_STATUS_OPTIONS}
              onClick={(tab) => {
                setFilterParams(
                  { ...Object.fromEntries(filterParams), status: tab.value },
                  { replace: false },
                );
              }}
            />
            <ProjectsTable
              columns={PROJECTS_TABLE_COLUMNS}
              sortTable={sortTable}
              setSortTable={setSortTable}
              rows={filteredBySearchProjects}
              heightOptions={{
                fixedHeight: true,
                isFilterOpen,
              }}
              isFiltersApplied={isFiltersApplied}
            />
          </ViewWrapper>
        </Box>
      </Box>

      <ProjectFormModal
        open={addProjectModalOpen}
        headerLabel="Add a new Project"
        form={projectForm}
        loading={addProjectLoading}
        setForm={setProjectForm}
        formErrors={addProjectFormErrors}
        setFormErrors={setAddProjectFormErrors}
        onClose={handleCloseAddProjectModal}
        onConfirm={handleAddProject}
        formType={FORM_TYPES.CREATE}
      />
    </>
  );
}

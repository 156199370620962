import Link from "@mui/material/Link";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { isNumber } from "lodash";

import DateSchedule from "../../components/date-schedule";
import CurveFieldLabel from "../../components/curve-field-label";
import { IDetailsCardSection } from "../../components/details-card";
import { FUNDING_METHODOLOGY_CHOICES } from "../../constants/deal/construction-debt";
import {
  numberToUSD,
  getAbbreviatedMonthName,
  numberWithCommas,
} from "./common.helpers";
import {
  DEAL_TERM_DEBT_SOLVE_FORS,
  DEAL_TERM_TERM_DEBT_TYPES,
  DEAL_TAX_EQUITY_TYPES,
  DEAL_TE_SOLVE_FORS,
  PROJECT_TIMING_HORIZON,
  DEAL_DEPRECIATION_ELECTION,
  PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE,
  PROJECT_GROSS_PRODUCTION_METHOD,
  PROJECT_TAX_CREDIT_TYPE,
  CARRYFORWARD_CREDITS,
  DEAL_TIMING_DEVELOPER_HORIZON,
  DEAL_TAX_EQUITY_FIELDS_TO_HIDE,
  DEAL_TE_INPUT_METHOD,
  DEAL_TAX_EQUITY_PREF_RETURN_TYPES,
  DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS,
  DEAL_ITC_RECOGNITION_METHOD,
  DEAL_TE_BUYOUT_TYPES,
  DEAL_TE_BUYOUT_CALC_METHOD_TYPES,
  DEAL_TE_BUYOUT_IRR_INPUT_TYPES,
  TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME,
  DEAL_TERM_DEBT_PERIODICITY,
  PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES,
  PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES,
  PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES,
  PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES,
  SUSPEND_PRE_TAX_BOOK_LOSS,
  DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES,
  DEAL_PROJECT_COST_COMPONENT_TYPES,
  DEAL_CREDIT_SUPPORT_SUMMARY_TYPES,
  DEAL_COST_UNIT,
  DEAL_CREDIT_SUPPORT_UNIT,
  DEAL_TERM_DEBT_BASE_INPUT_TYPES,
  DEAL_TE_FLIP_ROI_INPUT_TYPES,
  DISTRIBUTION_PERIODICITY,
  TAX_COMPLIANCE_PERIOD_YEAR_TYPES,
} from "../../constants";
import {
  IDeal,
  IDealProjectDepreciation,
  IDealTiming,
  IDealTaxCredit,
  IDealProjectTaxCredit,
  IDealFat,
  IProjectDetailsFat,
  IDealTaxEquity,
  IDealOnlyCost,
  IDealDetails,
  IDealProjectCostComponent,
  IDealCreditSupportSummary,
  IDealProjectCostComponentItem,
  IDealCostRow,
  IDealTaxEquityForm,
  SetStateAction,
  IRow,
  ICol,
  EmptyObjectType,
  IProjectTiming,
} from "../../interfaces";

export const addProjectCountFieldToDealsList = (deals: IDeal[]) => {
  return deals.map((deal) => ({
    ...deal,
    projectCount: deal.project_count,
  }));
};

export const filterDealTaxEquityData = (
  taxEquity: IDetailsCardSection[],
  type?: keyof typeof DEAL_TAX_EQUITY_TYPES,
  solveFor?: keyof typeof DEAL_TE_SOLVE_FORS,
): IDetailsCardSection[] => {
  if (!type || !solveFor) {
    return taxEquity;
  }

  const fieldsToRemove = DEAL_TAX_EQUITY_FIELDS_TO_HIDE[type][solveFor];
  return taxEquity.map((te) => {
    const { fields } = te;
    const filteredFields = fields.filter((field) => {
      return !fieldsToRemove?.includes(
        field?.fieldName as keyof IDealTaxEquityForm,
      );
    });
    return {
      heading: te.heading,
      fields: filteredFields,
    };
  });
};

export const getFilterableDealTaxEquityData = (
  taxEquity: IDealTaxEquity | null,
  dealStructureType: "tax-equity" | "cash-equity",
): IDetailsCardSection[] => {
  if (!taxEquity) {
    return [];
  }
  return [
    {
      heading: "Structure",
      fields: [
        {
          label: "Type",
          value: { text: DEAL_TAX_EQUITY_TYPES[taxEquity.type] },
          fieldName: "type",
        },
        {
          label: "Solve for",
          value: { text: DEAL_TE_SOLVE_FORS[taxEquity.solve_for] },
          fieldName: "solve_for",
        },
      ],
    },
    {
      heading: "Key Parameters",
      fields: [
        {
          label: "Investor Funding Date",
          value: {
            text: taxEquity.tax_equity_funding_date,
          },
          fieldName: "tax_equity_funding_date",
        },
        ...(DEAL_TAX_EQUITY_TYPES[taxEquity.type] !== DEAL_TAX_EQUITY_TYPES.PR
          ? [
              {
                label: "Target IRR Type",
                value: {
                  text: DEAL_TE_FLIP_ROI_INPUT_TYPES[
                    taxEquity.target_flip_irr_type
                  ],
                },
                fieldName: "target_flip_irr_type",
              },
            ]
          : []),
        ...(DEAL_TAX_EQUITY_TYPES[taxEquity.type] !== DEAL_TAX_EQUITY_TYPES.PR
          ? [
              {
                label: "Target Flip IRR",
                value: { text: (taxEquity.target_flip_irr || 0) + "%" },
                fieldName: "target_flip_irr",
              },
            ]
          : []),
        ...(DEAL_TE_SOLVE_FORS[taxEquity.solve_for] !== DEAL_TE_SOLVE_FORS.FDATE
          ? [
              {
                label: "Target Flip Term",
                value: { text: (taxEquity.target_flip_term || 0) + " Yrs" },
                fieldName: "target_flip_term",
              },
              {
                label: "Target Flip Date",
                value: { text: taxEquity.target_flip_date },
                fieldName: "target_flip_date",
              },
            ]
          : []),
        ...(DEAL_TE_SOLVE_FORS[taxEquity.solve_for] !==
        DEAL_TE_SOLVE_FORS.TEQAMT
          ? [
              {
                label:
                  dealStructureType === "cash-equity"
                    ? "Cash Equity Input Method"
                    : "Tax Equity Input Method",
                value: {
                  text: taxEquity.tax_equity_input_method
                    ? DEAL_TE_INPUT_METHOD[taxEquity.tax_equity_input_method]
                    : "",
                },
                fieldName: "tax_equity_input_method",
              },
              ...(taxEquity.tax_equity_input_method &&
              DEAL_TE_INPUT_METHOD[taxEquity.tax_equity_input_method] ===
                DEAL_TE_INPUT_METHOD.PCTFMV
                ? [
                    {
                      label: "Percentage of FMV",
                      value: {
                        text: (taxEquity.tax_equity_input_value || 0) + "%",
                      },
                      fieldName: "tax_equity_input_value",
                    },
                  ]
                : []),
              ...(taxEquity.tax_equity_input_method &&
              DEAL_TE_INPUT_METHOD[taxEquity.tax_equity_input_method] ===
                DEAL_TE_INPUT_METHOD.ITCMU
                ? [
                    {
                      label: "ITC Multiple",
                      value: {
                        text: (taxEquity.tax_equity_input_value || 0) + "x",
                      },
                      fieldName: "tax_equity_input_value",
                    },
                  ]
                : []),
            ]
          : []),
        {
          label: "Investment Amount",
          value: {
            text:
              taxEquity.type === "YBF" &&
              // (taxEquity.solve_for === "ITCMULT" || taxEquity.solve_for === "TEQAMT")
              taxEquity.solve_for === "TEQAMT"
                ? "Solve for"
                : numberToUSD.format(taxEquity?.amount_total || 0),
          },
          fieldName: "amount_total",
        },
        ...(DEAL_TE_SOLVE_FORS[taxEquity.solve_for] !== DEAL_TE_SOLVE_FORS.PFCS
          ? [
              {
                label: "Pre-Flip Cash Split",
                value: {
                  text: (taxEquity.pre_flip_cash_split_percent || 0) + "%",
                },
                fieldName: "pre_flip_cash_split_percent",
              },
            ]
          : []),
        {
          label: "Pre-Flip Tax Split",
          value: { text: (taxEquity.pre_flip_tax_split_percent || 0) + "%" },
          fieldName: "pre_flip_tax_split_percent",
        },
        ...(taxEquity.type !== "PR"
          ? [
              {
                label: "Preferred Return Type",
                value: {
                  text:
                    DEAL_TAX_EQUITY_PREF_RETURN_TYPES[
                      taxEquity.preferred_return_type
                    ] || "",
                },
                fieldName: "preferred_return_type",
              },
            ]
          : []),
        ...(taxEquity.preferred_return_type !== "NONE"
          ? [
              {
                label: "Preferred Return %",
                value: { text: taxEquity.preferred_return_percent + "%" || "" },
                fieldName: "preferred_return",
              },
            ]
          : []),
        {
          label: "Federal Tax Rate",
          value: {
            text: Number(taxEquity?.federal_tax_rate ?? 0).toFixed(4) + "%",
          },
        },
        ...(isNumber(
          taxEquity?.new_federal_tax_rate ||
            taxEquity?.federal_tax_rate_change_year,
        )
          ? [
              {
                label: "New Federal Tax Rate",
                value: {
                  text:
                    Number(taxEquity?.new_federal_tax_rate ?? 0).toFixed(4) +
                    "%",
                },
              },
              {
                label: "Tax Rate Change Year",
                value: {
                  text: isNumber(taxEquity?.federal_tax_rate_change_year)
                    ? String(taxEquity?.federal_tax_rate_change_year)
                    : "N/A",
                },
              },
            ]
          : []),

        {
          label: "Distribution Periodicity",
          value: {
            text: DISTRIBUTION_PERIODICITY[
              taxEquity.distribution_periodicity as keyof typeof DISTRIBUTION_PERIODICITY
            ],
          },
        },
      ],
    },
    DEAL_TAX_EQUITY_TYPES[taxEquity.type] === DEAL_TAX_EQUITY_TYPES.PR
      ? {
          heading: "Additional Parameters",
          fields: [
            {
              label: "Cash/Tax Split",
              value: {
                text: (taxEquity.post_flip_cash_split_percent || 0) + "%",
              }, // Setting the value to the same as Post-Flip Cash Split
              fieldName: "cash_tax_split_percent",
            },
            {
              label: "Distribution Periodicity",
              value: {
                text: DISTRIBUTION_PERIODICITY[
                  taxEquity.distribution_periodicity
                ],
              },
              fieldName: "distribution_periodicity",
            },
          ],
        }
      : null,
    DEAL_TAX_EQUITY_TYPES[taxEquity.type] !== DEAL_TAX_EQUITY_TYPES.PR
      ? {
          heading: "Additional Parameters",
          fields: [
            {
              label: "Tax Allocation Method",
              value: {
                text: DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS[
                  taxEquity.tax_allocation_method
                ],
              },
              fieldName: "tax_allocation_method",
            },
            ...(!(
              DEAL_TAX_EQUITY_TYPES[taxEquity.type] ===
                DEAL_TAX_EQUITY_TYPES.PR ||
              DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS[
                taxEquity.tax_allocation_method
              ] === DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS.PRORATA
            )
              ? [
                  {
                    label: "Post-Flip Cash Split",
                    value: {
                      text: (taxEquity.post_flip_cash_split_percent || 0) + "%",
                    },
                    fieldName: "post_flip_cash_split_percent",
                  },
                  {
                    label: "Post-Flip Tax Split",
                    value: {
                      text: (taxEquity.post_flip_tax_split_percent || 0) + "%",
                    },
                    fieldName: "post_flip_tax_split_percent",
                  },
                ]
              : []),
            {
              label: "Distribution Periodicity",
              value: {
                text: DISTRIBUTION_PERIODICITY[
                  taxEquity.distribution_periodicity
                ],
              },
              fieldName: "distribution_periodicity",
            },
            ...(DEAL_TAX_EQUITY_TYPES[taxEquity.type] ===
              DEAL_TAX_EQUITY_TYPES.YBF && taxEquity.has_itc_projects
              ? [
                  {
                    label: "ITC Recognition Method",
                    value: {
                      text: taxEquity.itc_recognition_method
                        ? DEAL_ITC_RECOGNITION_METHOD[
                            taxEquity.itc_recognition_method
                          ]
                        : "",
                    },
                    fieldName: "itc_recognition_method",
                  },
                ]
              : []),
            ...(DEAL_TAX_EQUITY_TYPES[taxEquity.type] ===
              DEAL_TAX_EQUITY_TYPES.YBF && taxEquity.has_itc_projects
              ? [
                  {
                    label: "ITC Recognition Lag",
                    value: {
                      text: (taxEquity.itc_recognition_lag || 0) + " Months ",
                    },
                    fieldName: "itc_recognition_lag",
                  },
                ]
              : []),

            ...(taxEquity.tax_allocation_method === "P_99_67_99_5"
              ? [
                  {
                    label: "Tax Step Down Period",
                    value: {
                      text: (taxEquity.tax_step_down_period || 0) + " Months ",
                    },
                    fieldName: "tax_step_down_period",
                  },
                  {
                    label: "Tax Step Down Tax Split",
                    value: {
                      text: taxEquity.tax_step_tax_split + "%",
                    },
                    fieldName: "tax_step_tax_split",
                  },
                ]
              : []),

            ...(DEAL_TAX_EQUITY_TYPES[taxEquity.type] !==
              DEAL_TAX_EQUITY_TYPES.FF &&
            DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS[
              taxEquity.tax_allocation_method
            ] !== DEAL_TAX_EQUITY_TAX_ALLOCATIONS_METHODS.PRORATA
              ? [
                  {
                    label: "Target Flip Cash Split",
                    value: {
                      text: taxEquity.target_flip_cash_split + "%",
                    },
                    fieldName: "target_flip_cash_split",
                  },
                  {
                    label: "Target Flip Tax Split",
                    value: {
                      text: taxEquity.target_flip_tax_split + "%",
                    },
                    fieldName: "target_flip_tax_split",
                  },
                ]
              : []),
            {
              label: "Carryforward Credits and Losses",
              value: {
                text: CARRYFORWARD_CREDITS[
                  taxEquity.carryforward_credits_and_losses
                ],
              },
              fieldName: "carryforward_credits_and_losses",
            },
            {
              label: "Tax Compliance Period Year",
              value: {
                text: TAX_COMPLIANCE_PERIOD_YEAR_TYPES[
                  taxEquity.tax_compliance_period_year as keyof typeof TAX_COMPLIANCE_PERIOD_YEAR_TYPES
                ],
              },
              fieldName: "tax_compliance_period_year",
            },

            ...(["PCTINV", "PFCASH"].includes(taxEquity.preferred_return_type)
              ? [
                  {
                    label: "Sponsor Return of Capital",
                    value: {
                      text: taxEquity.sponsor_return_of_capital + "%" || "",
                    },
                    fieldName: "sponsor_return_of_capital",
                  },
                ]
              : []),
          ],
        }
      : null,

    DEAL_TAX_EQUITY_TYPES[taxEquity.type] !== DEAL_TAX_EQUITY_TYPES.PR
      ? {
          heading: "Constraints",
          fields: [
            {
              label: "Pre-Tax IRR Floor",
              value: { text: (taxEquity.pre_tax_irr_floor || 0) + "%" },
              fieldName: "pre_tax_irr_floor",
            },
            {
              label: "DRO Cap",
              value: { text: (taxEquity.dro_cap || 0) + "%" },
              fieldName: "dro_cap",
            },
            {
              label: "Loss Reallocation Method",
              value: {
                text: DEAL_IC_LOSS_REALLOCATION_METHOD_TYPES[
                  taxEquity.loss_reallocation_method
                ],
              },
              fieldName: "loss_reallocation_method",
            },
            {
              label: "ROI Floor (Full-Term)",
              value: {
                text: taxEquity.roi_floor_full_term
                  ? taxEquity.roi_floor_full_term + "%"
                  : "None set",
              },
              fieldName: "dro_cap",
            },
            {
              label: "ROI Floor (Buyout-Term)",
              value: {
                text: taxEquity.roi_floor_buyout_term
                  ? taxEquity.roi_floor_buyout_term + "%"
                  : "None set",
              },
              fieldName: "dro_cap",
            },
          ],
        }
      : null,

    DEAL_TAX_EQUITY_TYPES[taxEquity.type] !== DEAL_TAX_EQUITY_TYPES.PR
      ? {
          heading: "Buyout",
          fields: [
            {
              label: "Buyout Type",
              value: { text: DEAL_TE_BUYOUT_TYPES[taxEquity.buyout_type] },
              fieldName: "buyout_type",
            },
            ...(taxEquity.buyout_calculation_method === "POI"
              ? [
                  {
                    label: "Percentage of Investment",
                    value: {
                      text:
                        (taxEquity.buyout_percentage_of_investment || 0) + "%",
                    },
                    fieldName: "buyout_percentage_of_investment",
                  },
                ]
              : []),
            ...(taxEquity.buyout_calculation_method === "SPA"
              ? [
                  {
                    label: "Specified Amount",
                    value: {
                      text: numberToUSD.format(
                        taxEquity.buyout_specified_amount || 0,
                      ),
                    },
                    fieldName: "buyout_specified_amount",
                  },
                ]
              : []),
            ...(taxEquity.buyout_type === "FLDATE"
              ? [
                  ...(DEAL_TE_BUYOUT_CALC_METHOD_TYPES[
                    taxEquity.buyout_calculation_method
                  ] === DEAL_TE_BUYOUT_CALC_METHOD_TYPES.TGTRET
                    ? [
                        {
                          label: "Target Return",
                          value: {
                            text:
                              taxEquity.buyout_irr_input_type === "SPECIRR"
                                ? (taxEquity.buyout_irr || 0) + "%"
                                : DEAL_TE_BUYOUT_IRR_INPUT_TYPES[
                                    taxEquity.buyout_irr_input_type
                                  ],
                          },
                          fieldName: "buyout_irr",
                        },
                      ]
                    : []),
                  ...(DEAL_TE_BUYOUT_CALC_METHOD_TYPES[
                    taxEquity.buyout_calculation_method
                  ] === DEAL_TE_BUYOUT_CALC_METHOD_TYPES.DISCRT
                    ? [
                        {
                          label: "Discount Rate",
                          value: {
                            text:
                              (taxEquity.buyout_amount_discount_rate || 0) +
                              "%",
                          },
                          fieldName: "buyout_irr",
                        },
                      ]
                    : []),

                  ...(DEAL_TE_SOLVE_FORS[taxEquity.solve_for] !==
                  DEAL_TE_SOLVE_FORS.FDATE
                    ? [
                        {
                          label: "Buyout Date",
                          value: { text: taxEquity.buyout_date || "" },
                          fieldName: "buyout_date",
                        },
                      ]
                    : []),
                ]
              : []),
          ],
        }
      : null,

    {
      heading: "Accounting",
      fields: [
        {
          label: "Method",
          value: { text: taxEquity.accounting_method },
          fieldName: "accounting_method",
        },
        {
          label: "Tax Credits in Pre-Tax Book Income",
          value: {
            text: TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME[
              taxEquity.tax_credits_in_pre_tax_book_income as keyof typeof TAX_CREDITS_IN_PRE_TAX_BOOK_INCOME
            ],
          },
          fieldName: "tax_credits_in_pre_tax_book_income",
        },
        {
          label: "Amortization Years",
          value: {
            text: taxEquity.accounting_year,
          },
          fieldName: "accounting_year",
        },
        {
          label: "Suspend Pre-Tax Book Loss",
          value: {
            text: SUSPEND_PRE_TAX_BOOK_LOSS[
              taxEquity.suspend_pre_tax_book_loss as keyof typeof SUSPEND_PRE_TAX_BOOK_LOSS
            ],
          },
          fieldName: "suspend_pre_tax_book_loss",
        },
      ],
    },
  ].filter((s) => s !== null) as IDetailsCardSection[];
};

export const generateDealProjectDepreciationRows = (
  deal_project_depreciation: IDealProjectDepreciation[],
) => {
  const headers = generateDealProjectDepreciationHeaders(
    deal_project_depreciation,
  );

  // Create one row for each depreciation
  return deal_project_depreciation.map((depreciation) => {
    return headers
      .map((header, idx) => {
        if (idx <= 2) {
          let rawValue =
            depreciation[header.key as keyof IDealProjectDepreciation];
          if (header.key === "macrs_election") {
            if (!depreciation.type.includes("MACRS")) {
              rawValue = "N/A";
            } else {
              rawValue =
                DEAL_DEPRECIATION_ELECTION[
                  rawValue as keyof typeof DEAL_DEPRECIATION_ELECTION
                ];
            }
          }

          // Ensure valueToRender can only be string, number, or null
          let valueToRender: string | number | null = null;

          if (typeof rawValue === "string") {
            valueToRender = rawValue;
          } else if (typeof rawValue === "number") {
            valueToRender = depreciation.type.includes("percent")
              ? `${rawValue}%`
              : numberToUSD.format(rawValue);
          }

          // Only create a row if valueToRender is a string or a number
          if (
            typeof valueToRender === "string" ||
            typeof valueToRender === "number"
          ) {
            return {
              align: "right",
              value: valueToRender,
            };
          }
          return undefined; // Return undefined if valueToRender is neither
        } else {
          const projectOfColumn = depreciation.projects.find(
            (project) => project.name === header.key,
          );

          if (projectOfColumn) {
            const rawValue = projectOfColumn.amount;
            let valueToRender: string | number | null = null;

            if (typeof rawValue === "number") {
              valueToRender = depreciation.type.includes("percent")
                ? `${rawValue}%`
                : numberToUSD.format(rawValue);
            }

            // Only create a row if valueToRender is a string or a number
            if (
              typeof valueToRender === "string" ||
              typeof valueToRender === "number"
            ) {
              return {
                align: "right",
                value: valueToRender,
              };
            }
          }
          return undefined; // Return undefined if projectOfColumn does not exist
        }
      })
      .filter((row) => row !== undefined); // Filter out undefined rows
  });
};

export const generateDealCostsRows = (
  deal_project_cost: IDealProjectCostComponent,
  cost_input_type: keyof typeof DEAL_COST_UNIT,
) => {
  const headers = generateDealCostsHeaders(deal_project_cost);

  return new Array(Object.keys(deal_project_cost).length)
    .fill(0)
    .map((_, idx) => {
      return new Array(headers.length)
        .fill(0)
        .map((_, idx2) => {
          const type_obj =
            DEAL_PROJECT_COST_COMPONENT_TYPES[
              Object.keys(deal_project_cost)[
                idx
              ] as keyof IDealProjectCostComponent
            ];
          let rawValue;
          let align = "right";
          if (idx2 <= 1) {
            if (headers[idx2].key == "type") {
              align = "left";
              rawValue = type_obj.label;
            } else {
              rawValue =
                deal_project_cost[
                  Object.keys(deal_project_cost)[
                    idx
                  ] as keyof IDealProjectCostComponent
                ][headers[idx2].key as keyof IDealProjectCostComponentItem];
            }

            let valueToRender: string | number | null = null;

            if (type_obj.type === "percent" && typeof rawValue === "number") {
              valueToRender = `${rawValue}%`;
            } else if (
              type_obj.type === "dollar/dollar_per_wdc" &&
              typeof rawValue === "number"
            ) {
              valueToRender =
                cost_input_type === "DLPWD"
                  ? `${rawValue.toFixed(2)} $/W-dc`
                  : numberToUSD.format(rawValue);
            } else if (
              type_obj.type === "dollar" &&
              typeof rawValue === "number"
            ) {
              valueToRender = numberToUSD.format(rawValue);
            } else {
              valueToRender = rawValue as string;
            }

            const cell = {
              align: align,
              value: valueToRender,
              is_bold: type_obj.is_bold,
            };
            return cell;
          } else {
            const projectOfColumn = deal_project_cost[
              Object.keys(deal_project_cost)[
                idx
              ] as keyof IDealProjectCostComponent
            ].projects.find((project) => project.name === headers[idx2].key);

            if (projectOfColumn) {
              const rawValue = projectOfColumn.amount;

              let valueToRender: string | number | null = null;

              if (type_obj.type === "percent" && typeof rawValue === "number") {
                valueToRender = `${rawValue}%`;
              } else if (
                type_obj.type === "dollar/dollar_per_wdc" &&
                typeof rawValue === "number"
              ) {
                valueToRender =
                  cost_input_type === "DLPWD"
                    ? `${rawValue.toFixed(2)} $/W-dc`
                    : numberToUSD.format(rawValue);
              } else if (
                type_obj.type === "dollar" &&
                typeof rawValue === "number"
              ) {
                valueToRender = numberToUSD.format(rawValue);
              }

              return {
                align: "right",
                value: valueToRender,
                is_bold: type_obj.is_bold,
              };
            }

            return undefined;
          }
        })
        .filter((cell) => cell !== undefined); // Filter out undefined cells
    });
};

export const generateDealCreditSupportRows = (
  deal_project_cost: IDealCreditSupportSummary,
  dealCreditUnit: keyof typeof DEAL_CREDIT_SUPPORT_UNIT,
) => {
  const headers = generateDealCreditSupportHeaders(deal_project_cost);

  return new Array(Object.keys(deal_project_cost).length)
    .fill(0)
    .map((_, idx) => {
      return new Array(headers.length)
        .fill(0)
        .map((_, idx2) => {
          const type_obj =
            DEAL_CREDIT_SUPPORT_SUMMARY_TYPES[
              Object.keys(deal_project_cost)[
                idx
              ] as keyof IDealCreditSupportSummary
            ];
          let rawValue;
          let align = "right";

          if (idx2 <= 1) {
            if (headers[idx2].key == "type") {
              rawValue =
                type_obj.label === "Total"
                  ? "Total Credit Support"
                  : type_obj.label;
              align = "left";
            } else {
              align = "right";
              rawValue =
                deal_project_cost[
                  Object.keys(deal_project_cost)[
                    idx
                  ] as keyof IDealCreditSupportSummary
                ][headers[idx2].key as keyof IDealProjectCostComponentItem];
            }

            let valueToRender: string | number | null = null;

            if (type_obj.type === "percent" && typeof rawValue === "number") {
              valueToRender = `${rawValue}%`;
            } else if (
              type_obj.type === "dollar" &&
              typeof rawValue === "number"
            ) {
              valueToRender =
                dealCreditUnit === "DLPAD"
                  ? `${rawValue.toFixed(2)} $/kW-ac`
                  : numberToUSD.format(rawValue);
            } else {
              valueToRender = rawValue as string;
            }

            return {
              align: align,
              value: valueToRender,
              is_bold: type_obj.is_bold,
            };
          } else {
            const projectOfColumn = deal_project_cost[
              Object.keys(deal_project_cost)[
                idx
              ] as keyof IDealCreditSupportSummary
            ].projects.find((project) => project.name === headers[idx2].key);

            if (projectOfColumn) {
              const rawValue = projectOfColumn.amount;

              let valueToRender: string | number | null = null;

              if (type_obj.type === "percent" && typeof rawValue === "number") {
                valueToRender = `${rawValue}%`;
              } else if (
                type_obj.type === "dollar" &&
                typeof rawValue === "number"
              ) {
                valueToRender =
                  dealCreditUnit === "DLPAD"
                    ? `${rawValue.toFixed(2)} $/kW-ac`
                    : numberToUSD.format(rawValue);
              }

              return {
                align: "right",
                value: valueToRender,
                is_bold: type_obj.is_bold,
              };
            }
          }
          return undefined;
        })
        .filter((cell) => cell !== undefined); // Filter out undefined cells
    });
};

export const generateDealOnlyCostsRows = (
  dealOnlyCost: IDealOnlyCost,
): IRow[] => {
  const rows: IRow[] = [];

  const columns1: ICol[] = [
    { align: "right", value: "Deal Fees Expensed" },
    {
      align: "right",
      value: numberToUSD.format(dealOnlyCost.deal_fees_expensed),
    },
  ];
  rows.push({ columns: columns1, is_bold: false });

  const columns2: ICol[] = [
    { align: "right", value: "Deal Fees Amortized" },
    {
      align: "right",
      value: numberToUSD.format(dealOnlyCost.deal_fees_amortized),
    },
  ];
  rows.push({ columns: columns2, is_bold: false });

  return rows;
};

export const generateDealTaxCreditRows = (
  deal_project_depreciation: IDealTaxCredit[],
): IDealCostRow[] => {
  const headers = generateDealTaxCreditHeaders(deal_project_depreciation);
  const credit_type = [
    {
      label: "",
      key: "tax_credits_before_adders",
      project_key: "tax_credits_before_adders",
    },
    {
      label: "Adder",
      key: "adder_tax_credits",
      project_key: "adder_tax_credits",
    },
    { label: "Total", key: "total", project_key: "amount" },
  ];
  const rows: IDealCostRow[] = [];

  Array(deal_project_depreciation.length)
    .fill(0)
    .map((_, idx) => {
      Array(credit_type.length)
        .fill(0)
        .map((_, idx3) => {
          const columns: { align: string; value: string }[] = [];
          Array(headers.length)
            .fill(0)
            .map((_, idx2) => {
              if (idx2 <= 1) {
                let rawValue: string | number;
                if (idx2 === 0) {
                  rawValue = deal_project_depreciation[idx][
                    headers[idx2].key as keyof IDealTaxCredit
                  ] as string | number;
                } else {
                  rawValue = deal_project_depreciation[idx][
                    credit_type[idx3].key as keyof IDealTaxCredit
                  ] as string | number;
                }
                let valueToRender = rawValue;

                if (
                  deal_project_depreciation[idx].type.includes("percent") &&
                  typeof rawValue === "number"
                ) {
                  valueToRender = valueToRender + "%";
                } else if (typeof rawValue === "number") {
                  valueToRender = numberToUSD.format(rawValue);
                }
                if (idx2 === 0) {
                  if (valueToRender === "Total") {
                    valueToRender = "ITC/PTC";
                  }
                  columns.push({
                    align: "right",
                    value: valueToRender + " " + credit_type[idx3].label,
                  });
                } else {
                  columns.push({
                    align: "right",
                    value: ["string", "number"].includes(typeof valueToRender)
                      ? valueToRender.toString()
                      : "",
                  });
                }
              } else {
                const projectOfColumn = deal_project_depreciation[
                  idx
                ].projects.find(
                  (project) => project.name === headers[idx2].key,
                );

                if (projectOfColumn) {
                  const rawValue = projectOfColumn[
                    credit_type[idx3].project_key as keyof IDealProjectTaxCredit
                  ] as string | number;
                  let valueToRender = rawValue;
                  if (
                    deal_project_depreciation[idx].type.includes("percent") &&
                    typeof rawValue === "number"
                  ) {
                    valueToRender = valueToRender + "%";
                  } else if (typeof rawValue === "number") {
                    valueToRender = numberToUSD.format(rawValue);
                  }
                  columns.push({
                    align: "right",
                    value: ["string", "number"].includes(typeof valueToRender)
                      ? valueToRender.toString()
                      : "",
                  });
                }
              }
            });
          let is_bold = false;
          if (credit_type[idx3].label === "Total") {
            is_bold = true;
          }
          rows.push({ columns: columns, is_bold: is_bold });
        });
    });

  return rows;
};

export const generateDealTaxCreditHeaders = (
  deal_project_depreciation: IDealTaxCredit[],
) => {
  const constHeaders = [
    { label: "Type", key: "type_string", id: "type_string" },
    { label: "Total", key: "total", id: "total" },
  ];

  if (
    deal_project_depreciation.length &&
    deal_project_depreciation[0].projects.length
  ) {
    deal_project_depreciation[0].projects.forEach((project) => {
      constHeaders.push({
        label: project.name,
        key: project.name,
        id: project.id.toString(),
      });
    });
  }
  return constHeaders;
};

export const generateDealProjectDepreciationHeaders = (
  deal_project_depreciation: IDealProjectDepreciation[],
) => {
  const constHeaders = [
    { label: "Type", key: "type_string", id: "type_string" },
    {
      label: "Depreciation Election",
      key: "macrs_election",
      id: "macrs_election",
    },
    { label: "Total Projects", key: "total", id: "total" },
  ];

  deal_project_depreciation.forEach((depreciation) => {
    if (depreciation.projects.length) {
      depreciation.projects.forEach((project) => {
        if (!constHeaders.find((header) => header.key === project.name)) {
          constHeaders.push({
            label: project.name,
            key: project.name,
            id: project.id.toString(),
          });
        }
      });
    }
  });

  return constHeaders;
};

export const generateDealCostsHeaders = (
  dealProjectDepreciation: IDealProjectCostComponent,
) => {
  const dealCostHeaders = [
    { label: "Type", key: "type", id: "type", align: "left" },
    { label: "Total", key: "total", id: "total", align: "right" },
  ];

  const firstcostType: IDealProjectCostComponentItem = Object.values(
    dealProjectDepreciation,
  )[0];

  if (firstcostType) {
    firstcostType.projects.forEach((project) => {
      dealCostHeaders.push({
        id: project.id.toString(),
        key: project.name,
        align: "right",
        label: project.name,
      });
    });
  }

  return dealCostHeaders;
};

export const generateDealCreditSupportHeaders = (
  dealProjectDepreciation: IDealCreditSupportSummary,
) => {
  const creditSupportHeaders = [
    { label: "Type", key: "type", id: "type", align: "left" },
    { label: "Total", key: "total", id: "total", align: "right" },
  ];

  const firstCreditSupportType: IDealProjectCostComponentItem = Object.values(
    dealProjectDepreciation,
  )[0];

  if (firstCreditSupportType) {
    firstCreditSupportType.projects.forEach((project) => {
      creditSupportHeaders.push({
        id: project.id.toString(),
        key: project.name,
        align: "right",
        label: project.name,
      });
    });
  }

  return creditSupportHeaders;
};

export const generateDealTimingTableRows = (
  projectTimings: IDealTiming["project_timings"],
  viewSelected?: string[],
) => {
  const timingAttributes = [
    { label: "Project Evaluation Horizon", key: "horizon" },
    { label: "Development Start Date (BOL)", key: "start_date" },
    { label: "Notice to Proceed Date (NTP)", key: "ntp_date" },
    {
      label: "Mechanical Completion Date (MC)",
      key: "mechanical_completion_date",
    },
    {
      label: "Substantial Completion Date (SC)",
      key: "substantial_completion_date",
    },
    { label: "Placed In Service Date (PIS)", key: "placed_in_service_date" },
    {
      label: "Permission to Operate Date (PTO)",
      key: "permission_to_operate_date",
    },
    { label: "Commercial Operations Date (COD)", key: "cod" },
  ];

  let attributesToUse = timingAttributes;

  if (viewSelected) {
    attributesToUse = timingAttributes.filter((atr) =>
      viewSelected.includes(atr.key),
    );
  }

  return new Array(attributesToUse.length).fill(0).map((_, idx) => {
    const rowCells = [{ align: "right", value: attributesToUse[idx].label }];
    new Array(projectTimings.length).fill(0).map((_, innerIdx) => {
      const rawValue = projectTimings[innerIdx][
        attributesToUse[idx].key as keyof IProjectTiming
      ] as string;

      const valueToRender =
        attributesToUse[idx].key === "horizon"
          ? PROJECT_TIMING_HORIZON[
              rawValue as keyof typeof PROJECT_TIMING_HORIZON
            ]
          : rawValue;
      rowCells.push({
        align: "left",
        value: typeof valueToRender === "string" ? valueToRender : "N/A", // showing "N/A" if it is not a string
      });
    });
    return rowCells;
  });
};

const gotoCurveDetailPage = (url: string) => {
  window.open(url);
};

export const getCaseDealBlockDetails = (
  deal: IDealFat,
  block:
    | "investor"
    | "debt"
    | "timing"
    | "sponsor-equity"
    | "construction-debt",
): IDetailsCardSection[] => {
  switch (block) {
    case "investor": {
      const filterableTE = getFilterableDealTaxEquityData(
        deal.partnership_investor_configuration,
        deal.structure === "TEP" ? "tax-equity" : "cash-equity",
      );
      const filteredTE = filterDealTaxEquityData(
        filterableTE,
        deal.partnership_investor_configuration?.type,
        deal.partnership_investor_configuration?.solve_for,
      );
      return filteredTE.slice(0, 2); // returning first two card sections
    }
    case "debt": {
      if (deal.debt) {
        const {
          type,
          solve_for,
          debt_term,
          funding_date,
          hedged_percentage,
          payment_periodicity,
          base_rate,
          spread,
          spread_step_up,
          spread_step_up_frequency,
          upfront_fees_percentage,
          swap_rate,
          swap_rate_credit_spread,
          lc_fees_percentage,
          admin_fees,
          base_input_type,
          base_rate_org_curve,
          sofr_org_curve_detail,
        } = deal.debt!;
        return [
          {
            fields: [
              {
                label: "Type",
                value: { text: DEAL_TERM_TERM_DEBT_TYPES[type] },
              },
              {
                label: "Solve for",
                value: { text: DEAL_TERM_DEBT_SOLVE_FORS[solve_for] },
              },
            ],
          },
          {
            heading: "Key Parameters",
            fields: [
              {
                label: "Debt Funding Date",
                value: { text: funding_date },
              },
              {
                label: "Amortization Tenor",
                value: {
                  text: (debt_term || 0) + " Yrs",
                },
              },
              {
                label: "Payment Periodicity",
                value: {
                  text: DEAL_TERM_DEBT_PERIODICITY[
                    payment_periodicity as keyof typeof DEAL_TERM_DEBT_PERIODICITY
                  ],
                },
              },
              {
                label: "Base Rate Type",
                value: {
                  text: DEAL_TERM_DEBT_BASE_INPUT_TYPES[
                    base_input_type as keyof typeof DEAL_TERM_DEBT_BASE_INPUT_TYPES
                  ],
                },
              },
              ...(base_input_type === "RATE"
                ? [
                    {
                      label: "Base",
                      value: {
                        text: (base_rate || 0) + "%",
                      },
                    },
                  ]
                : []),

              ...(base_input_type === "OC"
                ? [
                    {
                      label: "SOFR (Organization Curve)",
                      value: {
                        text: (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={() =>
                              gotoCurveDetailPage(
                                `/configuration/data/curves/${sofr_org_curve_detail?.curve_group}/${sofr_org_curve_detail?.id}`,
                              )
                            }
                          >
                            {sofr_org_curve_detail?.name}
                            <OpenInNewIcon fontSize="small" />
                          </Link>
                        ),
                      },
                    },
                  ]
                : []),
              // {
              //   label: "Spread",
              //   value: { text: (spread || 0) + "%" },
              // },
              // {
              //   label: "Step Up",
              //   value: {
              //     text: (spread_step_up || 0) + "%",
              //   },
              // },
              // {
              //   label: "Step Up Frequency (Yrs)",
              //   value: {
              //     text: (spread_step_up_frequency || 0) + " Yrs",
              //   },
              // },
              // hide these for now
              // {
              //   label: "Hedged Percentage",
              //   value: {
              //     text: (hedged_percentage || 0) + "%",
              //   },
              // },
              // {
              //   label: "Swap Rate",
              //   value: {
              //     text: (swap_rate || 0) + "%",
              //   },
              // },
              // {
              //   label: "Swap Rate Credit Spread",
              //   value: {
              //     text: (swap_rate_credit_spread || 0) + "%",
              //   },
              // },
              // all fees hidden for now.
              // {
              //   label: "Upfront Fee",
              //   value: {
              //     text: (upfront_fees_percentage || 0) + "%",
              //   },
              // },
              // {
              //   label: "LC Fees",
              //   value: {
              //     text: (lc_fees_percentage || 0) + "%",
              //   },
              // },
              // {
              //   label: "Admin Fees",
              //   value: {
              //     text: numberToUSD.format(admin_fees || 0),
              //   },
              // },
            ],
          },
        ];
      }
      return [];
    }
    case "timing": {
      const {
        developer_horizon,
        tct_seller_tax_year_end_month,
        tct_buyer_tax_year_end_month,
        tct_seller_tax_filing_month,
        tct_buyer_tax_filing_month,
      } = deal.timing;
      const tctTiming =
        deal?.tax_credit_structure === "TR"
          ? [
              {
                label: "Seller's Tax Year End Month",
                value: {
                  text:
                    getAbbreviatedMonthName(
                      Number(tct_seller_tax_year_end_month),
                    ) || "",
                },
              },
              {
                label: "Buyer's Tax Year End Month",
                value: {
                  text:
                    getAbbreviatedMonthName(
                      Number(tct_buyer_tax_year_end_month),
                    ) || "",
                },
              },
              {
                label: "Seller's Tax Year Filling Month",
                value: {
                  text:
                    getAbbreviatedMonthName(
                      Number(tct_seller_tax_filing_month),
                    ) || "",
                },
              },
              {
                label: "Buyer's Tax Filling Month",
                value: {
                  text:
                    getAbbreviatedMonthName(
                      Number(tct_buyer_tax_filing_month),
                    ) || "",
                },
              },
            ]
          : [];

      return [
        {
          fields: [
            {
              label: "Developer Horizon",
              value: {
                text: DEAL_TIMING_DEVELOPER_HORIZON[
                  developer_horizon as keyof typeof DEAL_TIMING_DEVELOPER_HORIZON
                ],
              },
            },
            ...tctTiming,
          ],
        },
      ];
    }
    case "sponsor-equity": {
      const {
        pre_tax_discount_rate,
        after_tax_discount_rate,
        carryforward_credits_and_losses,
        federal_tax_rate,
        federal_tax_rate_change_year,
        new_federal_tax_rate,
        state_tax_rate,
      } = deal.sponsor_equity_configuration;
      return [
        {
          heading: "Key Parameters",
          fields: [
            {
              label: "Pre-Tax Discount Rate",
              value: {
                text: Number(pre_tax_discount_rate ?? 0)?.toFixed(4) + "%",
              },
            },
            {
              label: "After-Tax Discount Rate",
              value: {
                text: Number(after_tax_discount_rate ?? 0).toFixed(4) + "%",
              },
            },
            {
              label: "Carryforward Credits and Losses",
              value: {
                text:
                  CARRYFORWARD_CREDITS[
                    carryforward_credits_and_losses as keyof typeof CARRYFORWARD_CREDITS
                  ] ?? "N/A",
              },
            },
            {
              label: "Federal Tax Rate",
              value: {
                text: Number(federal_tax_rate ?? 0).toFixed(4) + "%",
              },
            },
            ...(isNumber(new_federal_tax_rate || federal_tax_rate_change_year)
              ? [
                  {
                    label: "New Federal Tax Rate",
                    value: {
                      text: Number(new_federal_tax_rate ?? 0).toFixed(4) + "%",
                    },
                  },
                  {
                    label: "Tax Rate Change Year",
                    value: {
                      text: isNumber(federal_tax_rate_change_year)
                        ? String(federal_tax_rate_change_year)
                        : "N/A",
                    },
                  },
                ]
              : []),
            {
              label: "State Tax Rate",
              value: {
                text: Number(state_tax_rate ?? 0).toFixed(4) + "%",
              },
            },
          ],
        },
      ];
    }
    case "construction-debt": {
      const { deal_construction_debt_general_sizing: generalAssumption } = deal;
      if (!generalAssumption) return [];
      return [
        {
          fields: [
            {
              label: "Funding Methodology",
              value: {
                text: FUNDING_METHODOLOGY_CHOICES[
                  generalAssumption?.funding_methodology as keyof typeof FUNDING_METHODOLOGY_CHOICES
                ],
              },
            },
            {
              label: "Min Equity During Construction",
              value: {
                text:
                  generalAssumption?.min_equity_during_construction.toString() +
                  "%",
              },
            },
            ...(generalAssumption.funding_methodology === "DF"
              ? [
                  {
                    label: "Equity Letter of Credit Fee",
                    value: {
                      text:
                        generalAssumption?.equity_letter_of_credit_fee.toString() +
                        "%",
                    },
                  },
                ]
              : []),
          ],
        },
      ];
    }
    default: {
    }
  }
  return [];
};

export const getCaseProjectBlockDetails = (
  project: IProjectDetailsFat,
  block: "timing" | "production" | "tax-credit",
  curveToggle?: {
    availabilityCurveToggle: boolean;
    curtailmentCurveToggle: boolean;
    degradationCurveToggle: boolean;
    lossCurveToggle: boolean;
    setAvailabilityCurveToggle: SetStateAction<boolean>;
    setCurtailmentCurveToggle: SetStateAction<boolean>;
    setDegradationCurveToggle: SetStateAction<boolean>;
    setLossCurveToggle: SetStateAction<boolean>;
  },
): IDetailsCardSection[] => {
  switch (block) {
    case "timing": {
      const { cod, operating_life_years } = project.timing;
      return [
        {
          heading: "Timing",
          fields: [
            {
              label: "Commercial Operations Date (COD)",
              value: { text: cod },
            },
            {
              label: "Project Operating Life",
              value: { text: operating_life_years + " Yrs" },
            },
          ],
        },
      ];
    }
    case "production": {
      const {
        capacity_ac,
        capacity_dc,
        gross_production_method,
        ncf_percentage,
        gross_yield,
        gross_production_mwh,
        probability_factor_type,
        case_probability_factor_type,
        degradation_rate_percentage,
        availability_factor_percentage,
        curtailment_percentage,
        duration_hrs,
        capacity_energy_mwh,
        production_input_type,
        degradation_input_type,
        availability_factor_input_type,
        curtailment_input_type,
        degradation_rate_percentage_curve,
        other_losses_input_type,
        other_losses_percentage,
        availability_factor_percentage_curve,
        curtailment_percentage_curve,
        other_losses_percentage_curve,
      } = project.production;

      const {
        availabilityCurveToggle,
        curtailmentCurveToggle,
        degradationCurveToggle,
        lossCurveToggle,
        setAvailabilityCurveToggle,
        setCurtailmentCurveToggle,
        setDegradationCurveToggle,
        setLossCurveToggle,
      } = curveToggle!;

      return [
        {
          fields: [
            {
              label:
                project.energy_type !== "BAST"
                  ? "Project Capacity (AC)"
                  : "Storage Power Capacity",
              value: { text: capacity_ac + " MW" },
            },
            ...(project.energy_type === "BAST"
              ? [
                  {
                    label: "Duration",
                    value: { text: duration_hrs + " Hrs" },
                  },
                  {
                    label: "Storage Energy Capacity",
                    value: { text: capacity_energy_mwh + " MWh" },
                  },
                ]
              : []),
            ...(project?.energy_type !== "BAST"
              ? [
                  {
                    label: "Project Capacity (DC)",
                    value: { text: capacity_dc + " MW" },
                  },
                  {
                    label: "Gross Production Method",
                    value: {
                      text: PROJECT_GROSS_PRODUCTION_METHOD[
                        gross_production_method
                      ],
                    },
                  },
                  ...(production_input_type === "SI"
                    ? [
                        {
                          label: "Gross NCF",
                          value: { text: ncf_percentage + " %" },
                        },
                      ]
                    : []),
                  {
                    label: "Gross Yield",
                    value: {
                      text: numberWithCommas.format(gross_yield) + " kWh/kWp",
                    },
                  },
                  {
                    label: "Gross Production",
                    value: {
                      text:
                        numberWithCommas.format(gross_production_mwh) + " MWh",
                    },
                  },
                  ...(production_input_type === "SI"
                    ? [
                        {
                          label: "Benchmark Probability Factor",
                          value: {
                            text:
                              PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE[
                                probability_factor_type as keyof typeof PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE
                              ] ?? "N/A",
                          },
                        },
                      ]
                    : []),
                  {
                    label: "Case Probability Factor",
                    value: {
                      text: PROJECT_PRODUCTION_PROBABILITY_FACTOR_TYPE[
                        case_probability_factor_type
                      ],
                    },
                  },
                  ...(PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES[
                    degradation_input_type
                  ] === PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES.DRP
                    ? [
                        {
                          label: "Degradation Rate",
                          value: { text: degradation_rate_percentage + " %" },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES[
                    degradation_input_type
                  ] === PROJECT_PRODUCTION_DEGRADATION_INPUT_TYPES.DRPC
                    ? [
                        {
                          label: (
                            <CurveFieldLabel
                              label="Cumulative Degradation Rate Percentage Curve"
                              primaryAction={setDegradationCurveToggle}
                              value={degradation_rate_percentage_curve}
                              toggled={degradationCurveToggle}
                            />
                          ),
                          value: {
                            text: (
                              <DateSchedule
                                curve={degradation_rate_percentage_curve || []}
                                dateSchedule={project.timing.date_schedule}
                                toggle={degradationCurveToggle}
                                handleToggle={setDegradationCurveToggle}
                              />
                            ),
                          },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES[
                    availability_factor_input_type
                  ] === PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES.AFP
                    ? [
                        {
                          label: "Availability Factor Percentage",
                          value: {
                            text: availability_factor_percentage + " %",
                          },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES[
                    availability_factor_input_type
                  ] === PROJECT_PRODUCTION_AVAILABILITY_FACTOR_INPUT_TYPES.AFPC
                    ? [
                        {
                          label: (
                            <CurveFieldLabel
                              label="Availability Factor Percentage Curve"
                              primaryAction={setAvailabilityCurveToggle}
                              value={availability_factor_percentage_curve}
                              toggled={availabilityCurveToggle}
                            />
                          ),
                          value: {
                            text: (
                              <DateSchedule
                                curve={
                                  availability_factor_percentage_curve || []
                                }
                                dateSchedule={project.timing.date_schedule}
                                toggle={availabilityCurveToggle}
                                handleToggle={setAvailabilityCurveToggle}
                              />
                            ),
                          },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES[
                    curtailment_input_type
                  ] === PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES.CP
                    ? [
                        {
                          label: "Curtailment Loss Percentage",
                          value: { text: curtailment_percentage + " %" },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES[
                    curtailment_input_type
                  ] === PROJECT_PRODUCTION_CURTAILMENT_INPUT_TYPES.CPC
                    ? [
                        {
                          label: (
                            <CurveFieldLabel
                              label="Curtailment Loss Percentage Curve"
                              primaryAction={setCurtailmentCurveToggle}
                              value={curtailment_percentage_curve}
                              toggled={curtailmentCurveToggle}
                            />
                          ),
                          value: {
                            text: (
                              <DateSchedule
                                curve={curtailment_percentage_curve || []}
                                dateSchedule={project.timing.date_schedule}
                                inUSD
                                toggle={curtailmentCurveToggle}
                                handleToggle={setCurtailmentCurveToggle}
                              />
                            ),
                          },
                        },
                      ]
                    : []),
                  ...(PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES[
                    other_losses_input_type
                  ] === PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES.OLP
                    ? [
                        {
                          label: "Other Losses Percentage",
                          value: {
                            text: `${other_losses_percentage}%`,
                          },
                        },
                      ]
                    : []),

                  ...(PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES[
                    other_losses_input_type
                  ] === PROJECT_PRODUCTION_OTHER_LOSSES_INPUT_TYPES.OLPC
                    ? [
                        {
                          label: (
                            <CurveFieldLabel
                              label="Other Losses Percentage Curve"
                              primaryAction={setLossCurveToggle}
                              value={other_losses_percentage_curve}
                              toggled={lossCurveToggle}
                            />
                          ),
                          value: {
                            text: (
                              <DateSchedule
                                curve={other_losses_percentage_curve || []}
                                dateSchedule={project.timing.date_schedule}
                                toggle={lossCurveToggle}
                                handleToggle={setLossCurveToggle}
                              />
                            ),
                          },
                        },
                      ]
                    : []),
                ]
              : []),
          ],
        },
      ];
    }
    case "tax-credit": {
      const {
        credit_type,
        pwa_requirements_met,
        is_ira_domestic_content_adder,
        is_ira_energy_community_adder,
        is_ira_environmental_justice_adder,
        is_ira_special_qualfied_ej_adder,
      } = project.tax_credit;

      return [
        {
          fields: [
            {
              label: "Credit Type",
              value: { text: PROJECT_TAX_CREDIT_TYPE[credit_type] },
            },
            {
              label: "PWA Requirements Met",
              value: { text: pwa_requirements_met ? "Yes" : "No" },
            },
          ],
        },
        {
          heading: "Adders",
          fields: [
            {
              label: "IRA Domestic Content Adder",
              value: { text: is_ira_domestic_content_adder ? "Yes" : "No" },
            },
            {
              label: "IRA Energy Community Adder",
              value: { text: is_ira_energy_community_adder ? "Yes" : "No" },
            },
            {
              label: "IRA Environmental Justice Adder",
              value: {
                text: is_ira_environmental_justice_adder ? "Yes" : "No",
              },
            },
            {
              label: "IRA Special Qualified EJ Adder",
              value: { text: is_ira_special_qualfied_ej_adder ? "Yes" : "No" },
            },
          ],
        },
      ];
    }
    default: {
    }
  }
  return [];
};

export const getChipColor = (status: string) => {
  if (status === "ACT") return "#006631";
  if (status === "DRFT") return "#202020";
  return "#D32F2F";
};

export const getFilledChipColor = (status: string) => {
  if (status === "ACT")
    return {
      color: "#006631",
      backgroundColor: "#E6F7EC",
    };
  if (status === "DRFT")
    return {
      color: "#202020",
      backgroundColor: "#EBEBEB",
    };
  return {
    color: "#D32F2F",
    backgroundColor: "#FDEDED",
  };
};

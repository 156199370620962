import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";

import Menu from "../../../../components/menu";
import ViewWrapper from "../../../../components/view-wrapper";
import Chart from "../../../project/pro-forma/dashboard/chart";
import ReportTableV2 from "../../../../components/report-table-v2";
import { useAPI, useSessionStorage } from "../../../../utils/hooks";
import { cn } from "../../../../utils/helpers";
import {
  PROJECT_TIMING_PERIODICITY,
  PROJECT_TIMING_PERIODICITY_OPTIONS,
  PROFORMA_BASIS,
  PROFORMA_BASIS_OPTIONS,
  PROFORMA_CHART_DETAIL_CONFIG,
} from "../../../../constants";
import {
  ProformaReportType,
  IProjectProformaResponse,
  IReportTable,
} from "../../../../interfaces";

interface IProps {
  getDealProforma: (
    id: number,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<IProjectProformaResponse | null>;
  downloadDealProforma: (
    id: number,
    periodicity: ProformaReportType,
    basis: "cash" | "accrual",
  ) => Promise<void>;
}

export default function DealProformaView({
  getDealProforma,
  downloadDealProforma,
}: IProps): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { dealId, caseDealId } = useParams();

  const [selectedPeriodicity, setSelectedPeriodicity] = useSessionStorage<
    ProformaReportType | ""
  >(`deal-periodicity-${dealId}`, "");
  const [isDownloading, setIsDownloading] = React.useState<boolean>(false);

  const [selectedBasis, setSelectedBasis] = useSessionStorage<
    "cash" | "accrual"
  >(`deal-selected-basis-${caseDealId}`, "cash");

  const [dealProforma, setDealProforma] = React.useState<IReportTable | null>(
    null,
  );

  React.useEffect(() => {
    if (searchParams.has("periodicity")) {
      const periodicity = searchParams.get("periodicity") as ProformaReportType;
      setSelectedPeriodicity(periodicity);
    } else {
      setSelectedPeriodicity(selectedPeriodicity || "AN");
      navigate(
        `${location.pathname}?periodicity=${selectedPeriodicity || "AN"}`,
        {
          replace: true,
        },
      );
    }
  }, []);

  React.useEffect(() => {
    if (Number(caseDealId)) {
      // Get the current value from sessionStorage
      const currentValue = sessionStorage.getItem(
        `deal-selected-basis-${caseDealId}`,
      );
      // If there's a value in sessionStorage for the new caseDealId, update activeShowUnlevered
      if (currentValue !== null) {
        setSelectedBasis(JSON.parse(currentValue));
      }
    }
  }, [caseDealId]);

  const {
    callAPI: getDealProformaCallAPI,
    errored: getDealProformaFailed,
    loading: loadingDealProforma,
  } = useAPI(
    (dealId: number, reportType: ProformaReportType, basis) =>
      getDealProforma(dealId, reportType, basis),
    {
      initialLoading: true,
    },
  );

  React.useEffect(() => {
    getDealProformaCallAPI(
      Number(caseDealId),
      selectedPeriodicity,
      selectedBasis,
    ).then((response) => {
      response && setDealProforma(response.data);
    });
  }, [caseDealId, selectedPeriodicity, selectedBasis]);

  const handleReportTypeChange = (v: ProformaReportType) => {
    setSelectedPeriodicity(v);
    navigate(`${location.pathname}?periodicity=${v}`, {
      replace: true,
    });
  };

  const handleDownloadDealProforma = async () => {
    if (selectedPeriodicity && caseDealId) {
      setIsDownloading(true);
      await downloadDealProforma(
        Number(caseDealId),
        selectedPeriodicity,
        selectedBasis,
      ).catch(() => null);
      setIsDownloading(false);
    }
  };

  const handleBasisChange = (basis: "cash" | "accrual") => {
    setSelectedBasis(basis);
  };

  return (
    <>
      <Box className={cn("flex justify-between items-center mt-4")}>
        <Box className={cn("flex gap-4")}>
          <Menu
            selectedItem={
              selectedPeriodicity
                ? PROJECT_TIMING_PERIODICITY[selectedPeriodicity]
                : selectedPeriodicity
            }
            placeholder="Periodicity"
            menuItems={PROJECT_TIMING_PERIODICITY_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleReportTypeChange(o.value as ProformaReportType),
            }))}
          />
          <Menu
            selectedItem={
              selectedBasis ? PROFORMA_BASIS[selectedBasis] : selectedBasis
            }
            placeholder="Basis"
            menuItems={PROFORMA_BASIS_OPTIONS.map((o) => ({
              label: o.label,
              onClick: () =>
                handleBasisChange(o.value as keyof typeof PROFORMA_BASIS),
            }))}
          />
        </Box>

        {!loadingDealProforma && !getDealProformaFailed && (
          <IconButton
            title="Download Deal Pro Forma"
            onClick={handleDownloadDealProforma}
            disabled={isDownloading}
          >
            <DownloadIcon />
          </IconButton>
        )}
      </Box>

      <ViewWrapper loading={loadingDealProforma} error={getDealProformaFailed}>
        {dealProforma ? (
          <Box>
            <Box>
              <Box className={cn("mt-4")}>
                <ReportTableV2 data={dealProforma} groupExpandedDepth={1} />
              </Box>
              <Chart
                chartData={dealProforma}
                chartElementConfig={PROFORMA_CHART_DETAIL_CONFIG}
                selectedBasis={selectedBasis}
              />
            </Box>
          </Box>
        ) : null}
      </ViewWrapper>
    </>
  );
}

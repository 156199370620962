import React from "react";
import Chart from "chart.js/auto";
import { format } from "date-fns";

import { useScreenWidth } from "../../../../utils/hooks";
import { IReportTable } from "../../../../interfaces";
import { PROJECT_COST_TYPE } from "../../../../constants";
import {
  generateColorGradient,
  getKeyFromEnumObject,
} from "../../../../utils/helpers";

interface IProps {
  data: IReportTable;
}

const hexColorsForChart = [
  "#FF6384",
  "#36A2EB",
  "#4BC0C0",
  "#9966FF",
  "#FF9F40",
  "#FFCE56",
  "#C9CBCE",
  "#FF6384",
  "#36A2EB",
  "#4BC0C0",
  "#9966FF",
];

const ProjectCostDetailChart = ({ data }: IProps) => {
  const width = useScreenWidth();
  const costDetailChartRef = React.useRef<HTMLCanvasElement | null>(null);
  const colors = generateColorGradient(
    hexColorsForChart,
    data?.rows?.[0]?.components?.length ?? 0,
  );

  React.useEffect(() => {
    const costChart2DRef = costDetailChartRef.current?.getContext("2d");

    if (costChart2DRef) {
      const datasets =
        data.rows[0].components?.map(({ label, values }, idx) => {
          // Extracting corresponding values for each date
          const color = colors[idx];
          // Find the maximum value in the dataset
          const maxCost = Math.max(...(values as number[]));
          return {
            label: label,
            data: values,
            borderColor: color,
            backgroundColor: color,
            fill: true,
            borderRadius: 0,
            maxCost: maxCost, // Store the max cost for sorting
          };
        }) ?? [];

      // Sort datasets so that lines with highest cost stays behind
      datasets.sort(
        (datasetA, datasetB) => datasetA.maxCost - datasetB.maxCost,
      );

      const costDetailChart = new Chart(costChart2DRef, {
        type: "line",
        data: {
          labels: data.dates.map((date) => format(new Date(date), "MMM yyyy")),
          datasets: datasets,
        },
        options: {
          aspectRatio: 4.2,
          plugins: {
            legend: {
              position: "bottom",
              labels: {
                // keep the legend items sorted by cost type enum order
                sort: (legendItemA, legendItemB) => {
                  const typeA = getKeyFromEnumObject(
                    legendItemA.text,
                    PROJECT_COST_TYPE,
                  );
                  const typeB = getKeyFromEnumObject(
                    legendItemB.text,
                    PROJECT_COST_TYPE,
                  );
                  const indexA = Object.keys(PROJECT_COST_TYPE).indexOf(
                    typeA as keyof typeof PROJECT_COST_TYPE,
                  );
                  const indexB = Object.keys(PROJECT_COST_TYPE).indexOf(
                    typeB as keyof typeof PROJECT_COST_TYPE,
                  );
                  return indexA - indexB;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              display: true,
              title: {
                display: true,
                text: "Date",
              },
            },
            y: {
              beginAtZero: true,
              grid: {
                display: false,
              },
              display: true,
              title: {
                display: true,
                text: "Cost ($)",
              },
            },
          },
        },
      });

      return () => {
        costDetailChart.destroy();
      };
    }
    return undefined;
  }, [width, data]);

  return <canvas id="costDetailChart" ref={costDetailChartRef} />;
};

export default ProjectCostDetailChart;

import { REPORT_API_ROUTES } from "../../../constants";
import { AxiosHelper, replaceParams } from "../../../utils/helpers";
import {
  IPortfolioSourcesAndUsesCOD,
  IPortfolioSourcesAndUsesConstruction,
} from "../../../interfaces";

export const getOutputPortfolioSourcesAndUsesConstruction = async (
  dealId: number,
) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPortfolioSourcesAndUsesConstruction,
    {
      dealId,
    },
  );
  return await AxiosHelper.get<IPortfolioSourcesAndUsesConstruction>(url);
};

export const getOutputPortfolioSourcesAndUsesCOD = async (dealId: number) => {
  const url = replaceParams(
    REPORT_API_ROUTES.deal.getOutputPortfolioSourcesAndUsesCod,
    {
      dealId,
    },
  );
  return await AxiosHelper.get<IPortfolioSourcesAndUsesCOD>(url);
};

import * as React from "react";
import Paper from "@mui/material/Paper";
import { Property as CssProperty } from "csstype";

import { cn } from "../../utils/helpers";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

const ChartWrapperHeader = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn("text-lg mb-4 flex justify-between items-center", className)}
    {...props}
  />
));
ChartWrapperHeader.displayName = "ChartWrapperHeader";

const ChartWrapperBody = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div ref={ref} className={cn("", className)} {...props} />
));
ChartWrapperBody.displayName = "ChartWrapperBody";

const ChartWrapper = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & {
    primaryButton?: JSX.Element;
    secondaryButton?: JSX.Element;
    displayToggle?: JSX.Element;
    fontWeight?: CssProperty.FontWeight;
  }
>(
  (
    {
      className,
      title,
      children,
      primaryButton,
      secondaryButton,
      displayToggle,
      fontWeight = "normal",
      ...props
    },
    ref,
  ) => {
    return (
      <Paper
        ref={ref}
        className={cn(`rounded-xl p-4`, className)}
        {...props}
        data-pw={title}
      >
        <ChartWrapperHeader>
          <p style={{ fontWeight }}>{title}</p>
          {displayToggle && displayToggle}
          <div className={cn("flex items-center gap-4")}>
            {secondaryButton && secondaryButton}
            {primaryButton && primaryButton}
          </div>
        </ChartWrapperHeader>
        <ChartWrapperBody>{children}</ChartWrapperBody>
      </Paper>
    );
  },
);
ChartWrapper.displayName = "ChartWrapper";

export default ChartWrapper;

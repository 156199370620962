import {
  IAddDealComparisonForm,
  IComparisonItem,
  IDealComparisonInputs,
  IDealComparisonOutputs,
  IUpdateDealComparisonForm,
} from "../interfaces";
import { MARKETS, US_STATES } from "./common";
import {
  CARRYFORWARD_CREDITS,
  DEAL_CASE_TYPE,
  DEAL_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_STRUCTURE_TYPE,
  DEAL_TAX_CREDIT_TYPE,
  DEAL_TAX_EQUITY_TYPES,
  DEAL_TE_SOLVE_FORS,
  DEAL_TERM_TERM_DEBT_TYPES,
  DEAL_TIMING_DEVELOPER_HORIZON,
} from "./deal";
import {
  PROJECT_COST_INPUT_CATEGORY,
  PROJECT_DEPRECIATION_SYSTEM,
  PROJECT_STAGE_DEVELOPMENT,
  PROJECT_ENERGY_TYPES,
} from "./project";

export const UPDATE_DEAL_COMPARISON_FORM_DEFAULT_STATE: IUpdateDealComparisonForm =
  {
    name: "",
    benchmark_deal: "",
    deal_ids: [],
    uuid: "",
  };

export const ADD_DEAL_COMPARISON_FORM_DEFAULT_STATE: IAddDealComparisonForm = {
  name: "",
  benchmark_deal: "",
  deal_ids: [],
};

export const DEAL_COMMON_COMPARISON_ITEMS: (IComparisonItem<
  keyof Pick<
    IDealComparisonInputs["deal"],
    | "structure"
    | "tax_credit_structure"
    | "term_debt_structure"
    | "case_type"
    | "case_description"
    | "is_synced_with_base_case"
    | "has_construction_loan"
    | "has_investor_bridge_loan"
    | "tax_credit_type"
  >
> & { type: string[] })[] = [
  {
    id: "case_type",
    hierarchy: ["Case Type"],
    property: "Case Type",
    enum: DEAL_CASE_TYPE,
    type: ["case"],
    nullValue: "Base Case",
  },
  {
    id: "case_description",
    hierarchy: ["Case Description"],
    property: "Case Description",
    type: ["case"],
    nullValue: "N/A",
  },
  {
    id: "is_synced_with_base_case",
    hierarchy: ["Case Synchronized"],
    property: "Case Synchronized",
    type: ["case"],
    nullValue: "N/A",
  },
  {
    id: "structure",
    hierarchy: ["Structure", "Deal Structure"],
    property: "Deal Structure",
    enum: DEAL_STRUCTURE_TYPE,
    type: ["deal", "case"],
    nullValue: "N/A",
  },
  {
    id: "tax_credit_structure",
    hierarchy: ["Structure", "Tax Credit Structure"],
    property: "Tax Credit Structure",
    enum: DEAL_TAX_CREDIT_STRUCTURE_TYPE,
    type: ["deal", "case"],
    nullValue: "N/A",
  },
  {
    id: "tax_credit_type",
    hierarchy: ["Structure", "Tax Credit Type"],
    property: "Tax Credit Type",
    enum: DEAL_TAX_CREDIT_TYPE,
    type: ["deal", "case"],
    nullValue: "N/A",
  },
  {
    id: "has_construction_loan",
    hierarchy: ["Structure", "Construction Loan"],
    property: "Construction Loan",
    type: ["deal", "case"],
    nullValue: "N/A",
  },
  {
    id: "has_investor_bridge_loan",
    hierarchy: ["Structure", "Investor Bridge Loan"],
    property: "Investor Bridge Loan",
    type: ["deal", "case"],
    nullValue: "N/A",
  },
  {
    id: "term_debt_structure",
    hierarchy: ["Structure", "Term Debt Type"],
    property: "Term Debt Type",
    enum: DEAL_TERM_TERM_DEBT_TYPES,
    type: ["deal", "case"],
    nullValue: "N/A",
  },
];

export const DEAL_GENERAL_COMPARISON_ITEMS: IComparisonItem<
  keyof Pick<
    IDealComparisonInputs["deal"],
    "project_energy_type" | "capacity_ac" | "capacity_dc"
  >
>[] = [
  {
    id: "project_energy_type",
    hierarchy: ["General", "Technology"],
  },
  {
    id: "capacity_ac",
    hierarchy: ["General", "Capacity AC"],
    suffix: " MW",
  },
  {
    id: "capacity_dc",
    hierarchy: ["General", "Capacity DC"],
    suffix: " MW",
  },
];

export const DEAL_GENERAL_COMPARISON_ITEMS_2: (IComparisonItem<
  keyof Pick<
    IDealComparisonInputs["project_attributes"],
    | "sponsor_names"
    | "project_names"
    | "types"
    | "states"
    | "power_markets"
    | "sub_stages"
  >
> & { type: string[] })[] = [
  {
    id: "sponsor_names",
    hierarchy: ["General", "Sponsor Name(s)"],
    type: ["deal", "case"],
  },
  {
    id: "project_names",
    hierarchy: ["General", "Project Name(s)"],
    type: ["deal"],
  },
  {
    id: "types",
    hierarchy: ["General", "Project Type(s)"],
    enum: PROJECT_ENERGY_TYPES,
    type: ["deal", "case"],
  },
  {
    id: "states",
    hierarchy: ["General", "Project State(s)"],
    enum: US_STATES,
    type: ["deal", "case"],
  },
  {
    id: "power_markets",
    hierarchy: ["General", "Power Market(s)"],
    enum: MARKETS,
    type: ["deal", "case"],
  },
  {
    id: "sub_stages",
    hierarchy: ["General", "Project Stage(s)"],
    enum: PROJECT_STAGE_DEVELOPMENT,
    type: ["deal", "case"],
  },
];

export const DEAL_TIMING_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["timing"]
>[] = [
  {
    id: "developer_horizon",
    hierarchy: ["Timing", "Developer Horizon"],
    enum: DEAL_TIMING_DEVELOPER_HORIZON,
  },
  {
    id: "development_start_date",
    hierarchy: ["Timing", "Development Start Date"],
  },
  {
    id: "development_period_years",
    hierarchy: ["Timing", "Development Period"],
    suffix: " Yrs",
  },
  {
    id: "construction_start_date",
    hierarchy: ["Timing", "Construction Start Date"],
  },
  {
    id: "construction_period_years",
    hierarchy: ["Timing", "Construction Period"],
    suffix: " Yrs",
  },
  {
    id: "operation_start_date",
    hierarchy: ["Timing", "Operations Start Date"],
  },
  {
    id: "operating_life_years",
    hierarchy: ["Timing", "Operations Period"],
    suffix: " Yrs",
  },
  {
    id: "debt_closing_date",
    hierarchy: ["Timing", "Financial Closing Date"],
  },
  {
    id: "tax_equity_funding_date",
    hierarchy: ["Timing", "Investor Funding Date"],
  },
  {
    id: "tax_equity_date_1",
    hierarchy: ["Timing", "Term Conversion Date"],
  },
  {
    id: "operating_life_years",
    hierarchy: ["Timing", "Deal Operating Life"],
    suffix: " Yrs",
  },
  {
    id: "end_date",
    hierarchy: ["Timing", "Deal End Date"],
  },
];

export const DEAL_COST_COMPARISON_ITEMS: IComparisonItem<
  | keyof IDealComparisonInputs["cost"]["costs"]
  | keyof Pick<
      IDealComparisonOutputs["portfolio_costs"],
      | "total_project_costs"
      | "deal_allocated_costs"
      | "construction_interest_fees"
      | "deal_project_cost"
      | "deal_fmv"
      | "fmv_step_up"
      | "credit_support_costs"
    >
>[] = [
  ...Object.keys(PROJECT_COST_INPUT_CATEGORY).map((costKey) => ({
    id: costKey as keyof typeof PROJECT_COST_INPUT_CATEGORY,
    hierarchy: [
      "Deal FMV ($)",
      PROJECT_COST_INPUT_CATEGORY[
        costKey as keyof typeof PROJECT_COST_INPUT_CATEGORY
      ],
    ],
    isDollar: true,
    nestedObjLevel: 1,
  })),
  {
    id: "credit_support_costs",
    hierarchy: ["Deal FMV ($)", "Credit Support Cost"],
    isDollar: true,
  },
  {
    id: "total_project_costs",
    hierarchy: ["Deal FMV ($)", "Total Project Cost"],
    isDollar: true,
  },
  {
    id: "construction_interest_fees",
    hierarchy: ["Deal FMV ($)", "Construction Interest Fees"],
    isDollar: true,
  },
  {
    id: "deal_allocated_costs",
    hierarchy: ["Deal FMV ($)", "Deal Allocated Cost"],
    isDollar: true,
  },
  {
    id: "deal_project_cost",
    hierarchy: ["Deal FMV ($)", "Deal Project Cost"],
    isDollar: true,
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Deal FMV ($)", "FMV Step Up"],
    isDollar: true,
  },
  {
    id: "deal_fmv",
    hierarchy: ["Deal FMV ($)", "Deal FMV"],
    isDollar: true,
  },
];

export const DEAL_COST_PER_WDC_COMPARISON_ITEMS: IComparisonItem<
  | keyof IDealComparisonInputs["cost_per_watt_dc"]["costs"]
  | keyof Pick<
      IDealComparisonOutputs["portfolio_costs_per_watt"],
      | "total_project_costs"
      | "deal_allocated_costs"
      | "construction_interest_fees"
      | "deal_project_cost"
      | "deal_fmv"
      | "fmv_step_up"
      | "credit_support_costs"
    >
>[] = [
  ...Object.keys(PROJECT_COST_INPUT_CATEGORY).map((costKey) => ({
    id: costKey as keyof typeof PROJECT_COST_INPUT_CATEGORY,
    hierarchy: [
      "Deal FMV ($/Wdc)",
      PROJECT_COST_INPUT_CATEGORY[
        costKey as keyof typeof PROJECT_COST_INPUT_CATEGORY
      ],
    ],
    nestedObjLevel: 1,
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  })),
  {
    id: "credit_support_costs",
    hierarchy: ["Deal FMV ($/Wdc)", "Credit Support Cost"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "total_project_costs",
    hierarchy: ["Deal FMV ($/Wdc)", "Total Project Cost"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "deal_allocated_costs",
    hierarchy: ["Deal FMV ($/Wdc)", "Deal Allocated Cost"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "deal_project_cost",
    hierarchy: ["Deal FMV ($/Wdc)", "Deal Project Cost"],
    isDollar: true,
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Deal FMV ($/Wdc)", "FMV Step Up"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
  {
    id: "deal_fmv",
    hierarchy: ["Deal FMV ($/Wdc)", "Deal FMV"],
    isDollar: true,
    decimalDigits: 4,
    suffix: "/Wdc",
  },
];

export const DEAL_CREDIT_SUPPORT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["credit_support_costs"]
>[] = [
  {
    id: "interconnection",
    hierarchy: ["Credit Support (During Construction)", "Interconnection"],
    isDollar: true,
  },
  {
    id: "lease",
    hierarchy: ["Credit Support (During Construction)", "Lease"],
    isDollar: true,
  },
  {
    id: "offtake",
    hierarchy: ["Credit Support (During Construction)", "Offtake"],
    isDollar: true,
  },
  {
    id: "other",
    hierarchy: ["Credit Support (During Construction)", "Other"],
    isDollar: true,
  },
  {
    id: "total",
    hierarchy: ["Credit Support (During Construction)", "Total"],
    isDollar: true,
  },
];

export const DEAL_FEE_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["deal_fees"]
>[] = [
  {
    id: "deal_fees_amortized",
    hierarchy: ["Deal Fees", "Expensed"],
    isDollar: true,
  },
  {
    id: "deal_fees_expensed",
    hierarchy: ["Deal Fees", "Amortized"],
    isDollar: true,
  },
];

export const DEAL_DEPRECIATION_FEE_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["depreciation"]
>[] = [
  {
    id: "macrs_election",
    hierarchy: ["Depreciation", "Depreciation Election"],
    enum: PROJECT_DEPRECIATION_SYSTEM,
  },
];

export const DEAL_TAX_CREDIT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["tax_credit"]
>[] = [
  {
    id: "itc_total",
    hierarchy: ["Tax Credits", "Total ITC"],
    isDollar: true,
  },
  {
    id: "ptc_total",
    hierarchy: ["Tax Credits", "Total PTC"],
    isDollar: true,
  },
  {
    id: "total_tax_credit",
    hierarchy: ["Tax Credits", "Total Tax Credit"],
    isDollar: true,
  },
];

export const DEAL_PROFORMA_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonInputs["proforma"]
>[] = [
  {
    id: "gross_production",
    hierarchy: ["Pro Forma", "Gross Production (MWh)"],
    isDollar: false,
    suffix: " MWh",
    decimalDigits: 2,
  },
  {
    id: "production_losses",
    hierarchy: ["Pro Forma", "Production Losses (MWh)"],
    isDollar: false,
    suffix: " MWh",
    decimalDigits: 2,
  },
  {
    id: "net_production",
    hierarchy: ["Pro Forma", "Net Production (MWh)"],
    isDollar: false,
    suffix: " MWh",
    decimalDigits: 2,
  },
  {
    id: "contracted_revenue",
    hierarchy: ["Pro Forma", "Contracted Revenue"],
    isDollar: true,
  },
  {
    id: "merchant_revenue",
    hierarchy: ["Pro Forma", "Merchant Revenue"],
    isDollar: true,
  },
  {
    id: "revenue_total",
    hierarchy: ["Pro Forma", "Total Revenue"],
    isDollar: true,
  },

  {
    id: "oandm",
    hierarchy: ["Pro Forma", "O&M (Scheduled)"],
    isDollar: true,
  },
  {
    id: "oandm_un",
    hierarchy: ["Pro Forma", "O&M (Unscheduled)"],
    isDollar: true,
  },
  {
    id: "insurance",
    hierarchy: ["Pro Forma", "Insurance"],
    isDollar: true,
  },
  {
    id: "asset_management",
    hierarchy: ["Pro Forma", "Asset Management"],
    isDollar: true,
  },
  {
    id: "administrative",
    hierarchy: ["Pro Forma", "Administrative"],
    isDollar: true,
  },
  {
    id: "land_lease",
    hierarchy: ["Pro Forma", "Land Lease"],
    isDollar: true,
  },
  {
    id: "property_tax",
    hierarchy: ["Pro Forma", "Property Tax"],
    isDollar: true,
  },
  {
    id: "major_maintenance",
    hierarchy: ["Pro Forma", "Major Maintenance"],
    isDollar: true,
  },
  {
    id: "letter_of_credit",
    hierarchy: ["Pro Forma", "Letter of Credit"],
    isDollar: true,
  },
  {
    id: "taxes",
    hierarchy: ["Pro Forma", "Taxes"],
    isDollar: true,
  },
  {
    id: "contingency",
    hierarchy: ["Pro Forma", "Contingency"],
    isDollar: true,
  },
  {
    id: "other_operations",
    hierarchy: ["Pro Forma", "Other Operations"],
    isDollar: true,
  },
  {
    id: "other_non_operational",
    hierarchy: ["Pro Forma", "Other Non-Operational"],
    isDollar: true,
  },

  {
    id: "expenses",
    hierarchy: ["Pro Forma", "Total Expenses"],
    isDollar: true,
  },
  {
    id: "ebitda",
    hierarchy: ["Pro Forma", "EBITDA"],
    isDollar: true,
  },
];

export const DEAL_PARTNERSHIP_SINGLE_OWNER_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["partnership"]
>[] = [
  {
    id: "deal_fmv",
    hierarchy: ["Partnership/Single Owner", "(-) Deal FMV"],
    isDollar: true,
  },
  {
    id: "deal_fees",
    hierarchy: ["Partnership/Single Owner", "(-) Deal Fees"],
    isDollar: true,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Partnership/Single Owner", "Capital Contributions"],
    isDollar: true,
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Partnership/Single Owner", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Partnership/Single Owner", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Partnership/Single Owner", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Partnership/Single Owner", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Partnership/Single Owner", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Partnership/Single Owner", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_SPONSOR_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["sponsor_equity"]
>[] = [
  {
    id: "deal_fmv",
    hierarchy: ["Sponsor Equity (COD)", "(-) Share of Deal FMV"],
    isDollar: true,
  },
  {
    id: "deal_fees",
    hierarchy: ["Sponsor Equity (COD)", "(-) Share of Deal Fees"],
    isDollar: true,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Sponsor Equity (COD)", "Capital Contributions"],
    isDollar: true,
  },
  {
    id: "fmv_step_up",
    hierarchy: ["Sponsor Equity (COD)", "(+) FMV Step Up"],
    isDollar: true,
  },
  {
    id: "debt_proceeds",
    hierarchy: ["Sponsor Equity (COD)", "(+) Debt Proceeds"],
    isDollar: true,
  },
  {
    id: "equity",
    hierarchy: ["Sponsor Equity (COD)", "Net Equity"],
    isDollar: true,
  },
  {
    id: "negated_equity",
    hierarchy: ["Sponsor Equity (COD)", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_irr_carryforward",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_moic",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "after_tax_moic",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "after_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_payback_period",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "after_tax_payback_period",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_discount_rate",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_discount_rate",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_npv",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax NPV"],
    isDollar: true,
  },
  {
    id: "after_tax_npv",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax NPV"],
    isDollar: true,
  },
  {
    id: "pre_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "after_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "carryforward_credits_and_losses",
    hierarchy: ["Sponsor Equity (COD)", "Carryforward Credits and Losses"],
    enum: CARRYFORWARD_CREDITS,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Sponsor Equity (COD)", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "state_tax_rate",
    hierarchy: ["Sponsor Equity (COD)", "State Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "composite_tax_rate",
    hierarchy: ["Sponsor Equity (COD)", "Composite Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "new_composite_tax_rate",
    hierarchy: ["Sponsor Equity (COD)", "New Composite Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Sponsor Equity (COD)", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Sponsor Equity (COD)", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_SPONSOR_EQUITY_BOL_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["sponsor_equity_bol"]
>[] = [
  {
    id: "total_project_costs_including_idc_and_fees",
    hierarchy: ["Sponsor Equity (BOL)", "(-) Deal Project Cost"],
    isDollar: true,
  },
  // {
  //   id: "credit_support_cash_collateral",
  //   hierarchy: ["Sponsor Equity (BOL)", "(-) Equity Contributions"],
  //   isDollar: true,
  // },
  {
    id: "credit_support_cash_collateral",
    hierarchy: ["Sponsor Equity (BOL)", "(-) Net Cash Collateral"],
    isDollar: true,
  },
  {
    id: "construction_debt_proceeds",
    hierarchy: ["Sponsor Equity (BOL)", "(+) Construction Debt Draws"],
    isDollar: true,
  },
  {
    id: "construction_debt_repayments",
    hierarchy: ["Sponsor Equity (BOL)", "(-) Construction Debt Repayments"],
    isDollar: true,
  },
  {
    id: "term_debt_proceeds",
    hierarchy: ["Sponsor Equity (BOL)", "(+) Term Debt Proceeds"],
    isDollar: true,
  },
  {
    id: "investor_contributions",
    hierarchy: ["Sponsor Equity (BOL)", "(+) Investor Contributions"],
    isDollar: true,
  },
  {
    id: "deal_fees",
    hierarchy: ["Sponsor Equity (BOL)", "(-) Deal Fees"],
    isDollar: true,
  },
  {
    id: "net_equity",
    hierarchy: ["Sponsor Equity (BOL)", "Net Equity"],
    isDollar: true,
  },
  {
    id: "investment_amount",
    hierarchy: ["Sponsor Equity (BOL)", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_cash_yield_5_yr",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax Cash Yield (5-Yr)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_cash_yield_10_yr",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax Cash Yield (10-Yr)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_moic",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "after_tax_moic",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax MOIC"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "after_tax_moic_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax MOIC (Buyout-Term)"],
    suffix: "x",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_payback_period",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "after_tax_payback_period",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax Payback Period"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_discount_rate",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_discount_rate",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_npv",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax NPV"],
    isDollar: true,
  },
  {
    id: "after_tax_npv",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax NPV"],
    isDollar: true,
  },
  {
    id: "pre_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "after_tax_npv_buyout_term",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax NPV (Buyout-Term)"],
    isDollar: true,
  },
  {
    id: "carryforward_credits_and_losses",
    hierarchy: ["Sponsor Equity (BOL)", "Carryforward Credits and Losses"],
    enum: CARRYFORWARD_CREDITS,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Sponsor Equity (BOL)", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "state_tax_rate",
    hierarchy: ["Sponsor Equity (BOL)", "State Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "composite_tax_rate",
    hierarchy: ["Sponsor Equity (BOL)", "Composite Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "new_composite_tax_rate",
    hierarchy: ["Sponsor Equity (BOL)", "New Composite Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Sponsor Equity (BOL)", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Sponsor Equity (BOL)", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_CONSTRUCTION_DEBT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["construction_debt"]
>[] = [
  {
    id: "construction_loan",
    hierarchy: ["Construction Debt", "Construction Loan"],
    isDollar: true,
  },
  {
    id: "tax_equity_bridge_loan",
    hierarchy: ["Construction Debt", "Investor Bridge Loan"],
    isDollar: true,
  },
  {
    id: "total_idc",
    hierarchy: ["Construction Debt", "Interest During Construction"],
    isDollar: true,
  },
  {
    id: "total_commitment_fees",
    hierarchy: ["Construction Debt", "Commitment Fees"],
    isDollar: true,
  },
  {
    id: "total_upfront_fees",
    hierarchy: ["Construction Debt", "Upfront Fees"],
    isDollar: true,
  },
  {
    id: "total_lc_fees",
    hierarchy: ["Construction Debt", "LC Fees"],
    isDollar: true,
  },
];

export const DEAL_DEBT_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["debt"] | "base_rate" | "spread"
>[] = [
  {
    id: "debt_amount",
    hierarchy: ["Term Debt", "Debt Amount"],
    isDollar: true,
  },
  {
    id: "base_rate",
    hierarchy: ["Term Debt", "Base Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "spread",
    hierarchy: ["Term Debt", "Spread"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "debt_term",
    hierarchy: ["Term Debt", "Amortization Tenor"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "min_debt_service_coverage_ratio",
    hierarchy: ["Term Debt", "Min Debt Service Coverage Ratio"],
    suffix: "x",
    decimalDigits: 3,
  },
  {
    id: "avg_debt_service_coverage_ratio",
    hierarchy: ["Term Debt", "Avg Debt Service Coverage Ratio"],
    suffix: "x",
    decimalDigits: 3,
  },
  {
    id: "debt_irr",
    hierarchy: ["Term Debt", "Debt IRR"],
    suffix: "%",
    decimalDigits: 3,
  },
  {
    id: "wal",
    hierarchy: ["Term Debt", "WAL"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "principal",
    hierarchy: ["Term Debt", "Principal Repayments"],
    isDollar: true,
  },
  {
    id: "interest",
    hierarchy: ["Term Debt", "Interest Payments"],
    isDollar: true,
  },
];

export const DEAL_TAX_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["tax_equity"]
>[] = [
  {
    id: "type",
    hierarchy: ["Tax Equity", "Type"],
    enum: DEAL_TAX_EQUITY_TYPES,
  },
  {
    id: "solve_for",
    hierarchy: ["Tax Equity", "Solve For"],
    enum: DEAL_TE_SOLVE_FORS,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Tax Equity", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "after_tax_irr_flip_term",
    hierarchy: ["Tax Equity", "After-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Tax Equity", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Tax Equity", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Tax Equity", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi_buyout_term",
    hierarchy: ["Tax Equity", "ROI (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_flip_term",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Tax Equity", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_floor",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits) Floor"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_shortfall",
    hierarchy: ["Tax Equity", "Pre-Tax IRR (with Credits) Shortfall"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_cash_split_percent",
    hierarchy: ["Tax Equity", "Pre-Flip Cash Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_tax_split_percent",
    hierarchy: ["Tax Equity", "Pre-Flip Tax Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "target_flip_tenor",
    hierarchy: ["Tax Equity", "Target Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "actual_flip_tenor",
    hierarchy: ["Tax Equity", "Actual Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "flip_delay",
    hierarchy: ["Tax Equity", "Flip Delay"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "dro_cap",
    hierarchy: ["Tax Equity", "DRO Cap"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "max_dro",
    hierarchy: ["Tax Equity", "Max DRO"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_cap_exceedance",
    hierarchy: ["Tax Equity", "DRO Cap Exceedance"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_term",
    hierarchy: ["Tax Equity", "DRO Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "preferred_return_type",
    hierarchy: ["Tax Equity", "Preferred Return Type"],
  },
  {
    id: "preferred_return_amount",
    hierarchy: ["Tax Equity", "Preferred Return %"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Tax Equity", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Tax Equity", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Tax Equity", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Tax Equity", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_CASH_EQUITY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["cash_equity"]
>[] = [
  {
    id: "type",
    hierarchy: ["Cash Equity", "Type"],
    enum: DEAL_TAX_EQUITY_TYPES,
  },
  {
    id: "solve_for",
    hierarchy: ["Cash Equity", "Solve For"],
    enum: DEAL_TE_SOLVE_FORS,
  },
  {
    id: "capital_contributions",
    hierarchy: ["Cash Equity", "Investment Amount"],
    isDollar: true,
  },
  {
    id: "after_tax_irr_flip_term",
    hierarchy: ["Cash Equity", "After-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr",
    hierarchy: ["Cash Equity", "After-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "after_tax_irr_buyout_term",
    hierarchy: ["Cash Equity", "After-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Cash Equity", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi_buyout_term",
    hierarchy: ["Cash Equity", "ROI (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_flip_term",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (Flip-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr",
    hierarchy: ["Cash Equity", "Pre-Tax IRR"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_buyout_term",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (Buyout-Term)"],
    suffix: "%",
    decimalDigits: 2,
    nullValue: "NM",
  },
  {
    id: "pre_tax_irr_with_tax_credit",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits)"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_floor",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits) Floor"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_irr_shortfall",
    hierarchy: ["Cash Equity", "Pre-Tax IRR (with Credits) Shortfall"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_cash_split_percent",
    hierarchy: ["Cash Equity", "Pre-Flip Cash Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_flip_tax_split_percent",
    hierarchy: ["Cash Equity", "Pre-Flip Tax Split"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "target_flip_tenor",
    hierarchy: ["Cash Equity", "Target Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "actual_flip_tenor",
    hierarchy: ["Cash Equity", "Actual Flip Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "flip_delay",
    hierarchy: ["Cash Equity", "Flip Delay"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "dro_cap",
    hierarchy: ["Cash Equity", "DRO Cap"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "max_dro",
    hierarchy: ["Cash Equity", "Max DRO"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_cap_exceedance",
    hierarchy: ["Cash Equity", "DRO Cap Exceedance"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "dro_term",
    hierarchy: ["Cash Equity", "DRO Term"],
    suffix: " Yrs",
    decimalDigits: 2,
  },
  {
    id: "preferred_return_type",
    hierarchy: ["Cash Equity", "Preferred Return Type"],
  },
  {
    id: "preferred_return_amount",
    hierarchy: ["Cash Equity", "Preferred Return %"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "federal_tax_rate",
    hierarchy: ["Cash Equity", "Federal Tax Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "pre_tax_cash",
    hierarchy: ["Cash Equity", "Pre-Tax Cash"],
    isDollar: true,
  },
  {
    id: "pre_tax_cash_with_tax_credits",
    hierarchy: ["Cash Equity", "Pre-Tax Cash (with Credits)"],
    isDollar: true,
  },
  {
    id: "after_tax_cash",
    hierarchy: ["Cash Equity", "After-Tax Cash"],
    isDollar: true,
  },
];

export const DEAL_TRANSFER_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["transfer"]
>[] = [
  {
    id: "payment_total",
    hierarchy: ["Transfer", "Payment Total"],
    isDollar: true,
  },
  {
    id: "payment_rate",
    hierarchy: ["Transfer", "Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "discount_rate",
    hierarchy: ["Transfer", "Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "roi",
    hierarchy: ["Transfer", "ROI"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "npv_payment_total",
    hierarchy: ["Transfer", "NPV of Payments"],
    isDollar: true,
  },
  {
    id: "npv_tax_credits",
    hierarchy: ["Transfer", "NPV of Credits"],
    isDollar: true,
  },
  {
    id: "adjusted_payment_rate",
    hierarchy: ["Transfer", "Adjusted Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "adjusted_payment_total",
    hierarchy: ["Transfer", "Adjusted Payment Total"],
    isDollar: true,
  },
];

export const DEAL_DIRECT_PAY_COMPARISON_ITEMS: IComparisonItem<
  keyof IDealComparisonOutputs["transfer"]
>[] = [
  {
    id: "payment_total",
    hierarchy: ["Direct Pay", "Payment Total"],
    isDollar: true,
  },
  {
    id: "payment_rate",
    hierarchy: ["Direct Pay", "Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "discount_rate",
    hierarchy: ["Direct Pay", "Discount Rate"],
    suffix: "%",
    decimalDigits: 2,
  },
  {
    id: "npv_payment_total",
    hierarchy: ["Direct Pay", "NPV of Payments"],
    isDollar: true,
  },
  {
    id: "npv_tax_credits",
    hierarchy: ["Direct Pay", "NPV of Credits"],
    isDollar: true,
  },
  {
    id: "adjusted_payment_rate",
    hierarchy: ["Direct Pay", "Adjusted Payment Rate"],
    decimalDigits: 4,
  },
  {
    id: "adjusted_payment_total",
    hierarchy: ["Direct Pay", "Adjusted Payment Total"],
    isDollar: true,
  },
];

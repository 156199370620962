import ProjectPagesLayout from "../../../components/project-pages-layout";
import ProjectProFormaView from "../../../views/project/pro-forma/dashboard";
import TabbedLayout from "../../../components/tabbed-layout";
import { getProjectProforma } from "../../../apis/project/proforma";
import { getProjectTiming } from "../../../apis/project/timing";

export default function ProFormaDashboard(): JSX.Element {
  return (
    <ProjectPagesLayout>
      <TabbedLayout module="Projects" pages="Pro Forma">
        <ProjectProFormaView
          getProjectTiming={getProjectTiming}
          getProjectProforma={getProjectProforma}
        />
      </TabbedLayout>
    </ProjectPagesLayout>
  );
}

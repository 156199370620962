import React from "react";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  CategoryScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  BarElement,
  LineController,
  BarController,
  LinearScale,
  ChartDataset,
  ChartTypeRegistry,
  ChartEvent,
  LegendItem,
  LegendElement,
} from "chart.js";

import { ToggleButtonGroup, ToggleButton } from "@mui/material";

import CheckboxInput from "../../../../components/checkbox-input";
import ChartWrapper from "../../../../components/chart-wrapper";
import { cn } from "../../../../utils/helpers";
import { useAppSelector, useScreenWidth } from "../../../../utils/hooks";
import {
  ITableColumn,
  IReportTable,
  IReportTableRow,
  IProformaChartDetailConfig,
} from "../../../../interfaces";
import { externalLightTheme } from "../../../../utils/theme";
import {
  PROJECT_CONTRACTED_REVENUE_TYPE,
  PROJECT_MERCHANT_REVENUE_TYPE,
} from "../../../../constants/project/revenue";
import { PROJECT_EXPENSE_TYPE } from "../../../../constants/project/expense";

ChartJS.register(
  CategoryScale,
  PointElement,
  BarElement,
  LineElement,
  LineController,
  BarController,
  LinearScale,
  Title,
  Tooltip,
  Filler,
  Legend,
);

interface IProps {
  chartData: IReportTable;
  chartElementConfig: IProformaChartDetailConfig;
  primaryButton?: JSX.Element;
  selectedBasis: "cash" | "accrual";
  showOnlySummary?: boolean;
  hideMwAxis?: boolean;
}

export default function Chart({
  chartData,
  chartElementConfig,
  primaryButton,
  selectedBasis,
  showOnlySummary = false,
  hideMwAxis = false,
}: IProps): JSX.Element {
  const width = useScreenWidth();

  const { currentProject } = useAppSelector((s) => s.project);

  const summaryChartRef = React.useRef<HTMLCanvasElement | null>(null);
  const detailChartRef = React.useRef<HTMLCanvasElement | null>(null);

  const [chartDensity, setChartDensity] = React.useState<"summary" | "detail">(
    "summary",
  );
  const [hideDatasets, setHideDatasets] = React.useState<boolean>(false);

  const handleDensityChange = (
    _: React.MouseEvent<HTMLElement>,
    newDensity: string | null,
  ) => {
    if (newDensity !== null) {
      setChartDensity(newDensity as "summary" | "detail");
    }
  };

  function handleLegendOnClick(
    e: ChartEvent,
    legendItem: LegendItem,
    legend: LegendElement<keyof ChartTypeRegistry>,
  ) {
    const index = legendItem.datasetIndex!;
    const chart = legend.chart;
    const meta = chart.getDatasetMeta(index);
    // Toggle visibility of dataset
    meta.hidden = chart.isDatasetVisible(index);
    // Update the chart
    chart.update();
    // Assuming legendItem is an array of all legend items
    if (
      Array.isArray(legend?.legendItems) &&
      legend.legendItems.every((item) => item.text && !item?.hidden)
    ) {
      setHideDatasets(false);
    }
  }

  React.useEffect(() => {
    const summaryChart2DRef = summaryChartRef?.current?.getContext("2d");
    const detailChart2DRef = detailChartRef?.current?.getContext("2d");

    const labels = chartData.dates;

    const summaryChart = new ChartJS(
      summaryChart2DRef as CanvasRenderingContext2D,
      {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Contracted Revenue",
              data: chartData.rows
                .find((row) => row.label === "Revenue")
                ?.components?.find(
                  (component) => component.label === "Contracted Revenue",
                )?.values,
              // darker green border and background the same
              borderColor: "#4F6128",
              backgroundColor: "#4F6128",
              order: 6,
              type: "line",
              fill: true,
              // pointStyle: "line", // Use 'line' to hide dots
              yAxisID: "y_dollars",
              hidden: hideDatasets,
            },
            {
              label: "Merchant Revenue",
              data: chartData.rows
                .find((row) => row.label === "Revenue")
                ?.components?.find(
                  (component) => component.label === "Merchant Revenue",
                )?.values,
              borderColor: "#9ABB59",
              backgroundColor: "#9ABB59",
              order: 5,
              type: "line",
              fill: true,
              // pointStyle: "line", // Use 'line' to hide dots
              yAxisID: "y_dollars",
              hidden: hideDatasets,
            },
            {
              label: "Total Revenue",
              data: chartData.rows.find((row) => row.label === "Revenue")
                ?.values,
              borderColor: "#77933B",
              backgroundColor: "#77933B",
              stack: "1",
              order: 2,
              type: "bar",
              yAxisID: "y_dollars",
              hidden: hideDatasets,
            },
            {
              label: "Total Expenses",
              data: chartData.rows.find((row) => row.label === "Expenses")
                ?.values,
              borderColor: "#FF0000",
              backgroundColor: "#FF0000",
              stack: "2",
              order: 3,
              yAxisID: "y_dollars",
              hidden: hideDatasets,
            },
            {
              label:
                chartData.rows.find((row) => row.label === "EBITDA")?.label ||
                "EBITDA (Overridden)",
              data:
                chartData.rows.find((row) => row.label === "EBITDA")?.values ||
                chartData.rows.find(
                  (row) => row.label === "EBITDA (Overridden)",
                )?.values,
              borderColor: "#00AFF0",
              backgroundColor: "#00AFF0",
              stack: "3",
              order: 4,
              yAxisID: "y_dollars",
              hidden: hideDatasets,
            },
            {
              label:
                chartData.rows.find((row) => row.label === "Net Production")
                  ?.label || "Net Capacity",
              data:
                chartData.rows.find((row) => row.label === "Net Production")
                  ?.values ||
                chartData.rows.find((row) => row.label === "Net Capacity")
                  ?.values,
              borderColor: "#C3D59B",
              backgroundColor: "#C3D59B",
              stack: "4",
              type: "line",
              // pointStyle: "line", // Use 'line' to hide dots
              order: 1,
              yAxisID: "y_mwh",
              hidden: hideDatasets,
            },
            selectedBasis === "cash"
              ? {
                  label: "Total Other Flow Cash",
                  data: chartData.rows
                    .find((row) => row.label === "Other Flows")
                    ?.components?.find(
                      (component) => component.label === "Cash Flow",
                    )?.values,
                  borderColor: "#aaaaaa",
                  backgroundColor: "#aaaaaa",
                  type: "line",
                  order: 7,
                  yAxisID: "y_dollars",
                  hidden: hideDatasets,
                }
              : {
                  label: "Total Other Flow Tax",
                  data: chartData.rows
                    .find((row) => row.label === "Other Flows")
                    ?.components?.find(
                      (component) => component.label === "Tax Flow",
                    )?.values,
                  borderColor: "#aaaaaa",
                  backgroundColor: "#aaaaaa",
                  type: "line",
                  order: 7,
                  yAxisID: "y_dollars",
                  hidden: hideDatasets,
                },
          ],
        },
        options: {
          aspectRatio: 2.75,
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              align: "center",
              labels: {
                sort: (a, b) => {
                  const b_datasetIndex = b.datasetIndex || 0;
                  const a_datasetIndex = a.datasetIndex || 0;
                  return a_datasetIndex - b_datasetIndex;
                },
              },
              onClick: handleLegendOnClick,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  if (ctx.dataset.yAxisID === "y_dollars") {
                    const formatted_value = ctx.parsed.y.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      },
                    );
                    return formatted_value.startsWith("-")
                      ? `${ctx.dataset.label} -${formatted_value.substring(1)}`
                      : `${ctx.dataset.label} ${formatted_value}`;
                  }
                  return `${ctx.dataset.label} ${
                    ctx.parsed.y.toLocaleString("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }) + " MWh"
                  }`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              type: "category",
              stacked: true,
              max: 600, // TODO: hard to read with these many, maybe always roll up, not sure.
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y_dollars: {
              title: {
                display: true,
                text: "Dollars ($)",
              },
              grid: {
                display: false,
              },
              position: "left",
              display: true,
              stacked: true,
              ticks: {
                callback: (value, index, values) => {
                  // $###,###.##
                  const formatted_value = value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  });
                  return formatted_value.startsWith("-")
                    ? `-${formatted_value.substring(1)}`
                    : formatted_value;
                },
              },
            },
            y_mwh: {
              display: !hideMwAxis,
              title: {
                display: true,
                text: "Megawatt Hour (MWh)",
              },
              grid: {
                display: false,
              },
              position: "right",
              stacked: false,
              ticks: {
                // ###,###
                callback: (value, index, values) => {
                  return (
                    value.toLocaleString("en-US", {
                      style: "decimal",
                    }) + " MWh"
                  );
                },
              },
            },
          },
        },
      },
    );

    const incrementHexColor = (hexColor: string, step: number) => {
      // Remove the "#" symbol from the hex color string
      hexColor = hexColor.replace(/^#/, "");

      // Parse the hex color string into RGB components
      let r = parseInt(hexColor.substring(0, 2), 16);
      let g = parseInt(hexColor.substring(2, 4), 16);
      let b = parseInt(hexColor.substring(4, 6), 16);

      // Increment RGB components by the specified step
      r = Math.min(255, r + step * 20);
      g = Math.min(255, g + step * 40);
      b = Math.min(255, b + step * 20);

      // Convert RGB components back to hex and return the new hex color string
      return `#${(r < 16 ? "0" : "") + r.toString(16)}${
        (g < 16 ? "0" : "") + g.toString(16)
      }${(b < 16 ? "0" : "") + b.toString(16)}`;
    };

    const getDataSets = (
      chartData: IReportTable,
      chartElementConfig: IProformaChartDetailConfig,
    ): ChartDataset<"bar" | "line", number[]>[] => {
      interface partialDataSet {
        element_config: ITableColumn; // Adjust the type accordingly
        data: (string | number)[]; // Adjust the type accordingly
        color: string;
        suffix: string;
        type: string;
        order: number;
        always_show: true;
        yAxisID: string;
      }

      const detail_datasets = Array<partialDataSet>();

      function getDisplayedObject(
        allChildren: IReportTableRow[] | undefined,
        requiredChildLabel: string,
      ) {
        // Shows the grand children of the required child label
        // If no grand children, shows the child values
        const child = allChildren?.find(
          (row) => row.label === requiredChildLabel,
        );
        if (!child) {
          return {};
        }
        const grandChildren = child?.components || [];
        if (grandChildren.length === 0) {
          return { "": child?.values as number[] };
        }
        if (grandChildren.length === 1) {
          return { "": grandChildren[0].values as number[] };
        }
        return grandChildren.reduce(
          (acc: Record<string, number[]>, row, index) => {
            acc[`${index + 1}`] = row.values as number[];
            return acc;
          },
          {},
        );
      }

      const contracted_revenue_components = chartData.rows
        .find((row) => row.label === "Revenue")
        ?.components?.find(
          (component) => component.label === "Contracted Revenue",
        )?.components;
      const contracted_revenue_type_ppa = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.PPA,
      );
      const contracted_revenue_type_vppa = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.VPPA,
      );
      const contracted_revenue_type_rec = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.REC,
      );
      const contracted_revenue_type_hedge = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.HEDGE,
      );
      const contracted_revenue_type_capacity = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.CAPACITY,
      );
      const contracted_revenue_type_other = getDisplayedObject(
        contracted_revenue_components,
        PROJECT_CONTRACTED_REVENUE_TYPE.OTHER,
      );

      const merchant_revenue_components = chartData.rows
        .find((row) => row.label === "Revenue")
        ?.components?.find(
          (component) => component.label === "Merchant Revenue",
        )?.components;
      const merchant_revenue_type_energy = getDisplayedObject(
        merchant_revenue_components,
        PROJECT_MERCHANT_REVENUE_TYPE.ENERGY,
      );
      const merchant_revenue_type_rec = getDisplayedObject(
        merchant_revenue_components,
        PROJECT_MERCHANT_REVENUE_TYPE.REC,
      );
      const merchant_revenue_type_capacity = getDisplayedObject(
        merchant_revenue_components,
        PROJECT_MERCHANT_REVENUE_TYPE.CAPACITY,
      );
      const merchant_revenue_type_other = getDisplayedObject(
        merchant_revenue_components,
        PROJECT_MERCHANT_REVENUE_TYPE.OTHER,
      );

      const expense_components = chartData.rows.find(
        (row) => row.label === "Expenses",
      )?.components;
      const expense_type_oandm = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.OANDM,
      );
      const expense_type_oandm_un = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.OANDM_UN,
      );
      const expense_type_ins = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.INS,
      );
      const expense_type_assmgmt = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.ASSMGMT,
      );
      const expense_type_admin = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.ADMIN,
      );
      const expense_type_land = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.LAND,
      );
      const expense_type_proptx = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.PROPTX,
      );
      const expense_type_majmain = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.MAJMAIN,
      );
      const expense_type_loc = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.LOC,
      );
      const expense_type_taxes = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.TAXES,
      );
      const expense_type_cont = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.CONT,
      );
      const expense_type_other = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.OTHER,
      );
      const expense_type_other_no = getDisplayedObject(
        expense_components,
        PROJECT_EXPENSE_TYPE.OTHER_NO,
      );

      const getDetailDataSets = (
        chartelementconfig: ITableColumn,
        values: (string | number)[],
        suffix: string,
        idx: number,
      ): partialDataSet => {
        return {
          element_config: chartelementconfig,
          data: values,
          color: incrementHexColor(chartelementconfig.color || "#000000", idx),
          suffix: " " + suffix,
          yAxisID: "y_dollars",
          order: 2,
          type: "bar",
          always_show: true,
        };
      };
      Object.entries(contracted_revenue_type_hedge).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_hedge,
              values,
              key,
              idx,
            ),
          );
        },
      );
      Object.entries(contracted_revenue_type_capacity).forEach(
        ([key, values], idx) => {
          // Check the project type and adjust the label
          if (
            currentProject?.energy_type === "BAST" &&
            chartElementConfig.contracted_revenue_type_capacity.label ===
              "Capacity"
          ) {
            chartElementConfig.contracted_revenue_type_capacity.label =
              "Tolling Agreement";
          }
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_capacity,
              values,
              key,
              idx,
            ),
          );
        },
      );
      Object.entries(merchant_revenue_type_energy).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.merchant_revenue_type_energy,
              values,
              key,
              idx,
            ),
          );
        },
      );
      Object.entries(merchant_revenue_type_rec).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.merchant_revenue_type_rec,
              values,
              key,
              idx,
            ),
          );
        },
      );
      Object.entries(merchant_revenue_type_capacity).forEach(
        ([key, values], idx) => {
          // Check the project type and adjust the label
          if (
            currentProject?.energy_type === "BAST" &&
            chartElementConfig.merchant_revenue_type_capacity.label ===
              "Capacity"
          ) {
            chartElementConfig.merchant_revenue_type_capacity.label =
              "Merchant Capacity";
          }
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.merchant_revenue_type_capacity,
              values,
              key,
              idx,
            ),
          );
        },
      );
      Object.entries(expense_type_oandm).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_oandm,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_oandm_un).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_oandm_un,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_ins).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_ins,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_assmgmt).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_assmgmt,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_admin).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_admin,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_land).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_land,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_proptx).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_proptx,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_majmain).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_majmain,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_loc).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_loc,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_taxes).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_taxes,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_other).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_other,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_other_no).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_other_no,
            values,
            key,
            idx,
          ),
        );
      });
      Object.entries(expense_type_cont).forEach(([key, values], idx) => {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.expense_type_cont,
            values,
            key,
            idx,
          ),
        );
      });

      detail_datasets.push({
        element_config: chartElementConfig.gross_production,
        data:
          chartData.rows
            .find((row) => row.label === "Net Production")
            ?.components?.find(
              (component) => component.label === "Gross Production",
            )?.values || [],
        color: "#6994c7",
        order: 1, // order lines on top of bars
        type: "line",
        suffix: "",
        always_show: true,
        yAxisID: "y_mwh",
      });
      detail_datasets.push({
        element_config: chartElementConfig.production_losses,
        data:
          chartData.rows
            .find((row) => row.label === "Net Production")
            ?.components?.find(
              (component) => component.label === "Production Losses",
            )?.values || [],
        color: "#bf504d",
        order: 1,
        type: "line",
        always_show: true,
        suffix: "",
        yAxisID: "y_mwh",
      });
      detail_datasets.push({
        element_config: chartElementConfig.net_production,
        data:
          chartData.rows.find((row) => row.label === "Net Production")
            ?.values || [],
        color: "#9bbb59",
        order: 1,
        type: "line",
        always_show: true,
        suffix: "",
        yAxisID: "y_mwh",
      });

      Object.entries(contracted_revenue_type_ppa).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_ppa,
              values,
              key,
              idx,
            ),
          );
        },
      );

      Object.entries(contracted_revenue_type_vppa).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_vppa,
              values,
              key,
              idx,
            ),
          );
        },
      );

      Object.entries(contracted_revenue_type_rec).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_rec,
              values,
              key,
              idx,
            ),
          );
        },
      );

      Object.entries(contracted_revenue_type_other).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.contracted_revenue_type_other,
              values,
              key,
              idx,
            ),
          );
        },
      );

      Object.entries(merchant_revenue_type_other).forEach(
        ([key, values], idx) => {
          detail_datasets.push(
            getDetailDataSets(
              chartElementConfig.merchant_revenue_type_other,
              values,
              key,
              idx,
            ),
          );
        },
      );

      if (selectedBasis === "cash") {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.other_flow_cash_total,
            chartData.rows
              .find((row) => row.label === "Other Flows")
              ?.components?.find((component) => component.label === "Cash Flow")
              ?.values || [],
            "",
            0,
          ),
        );
      }

      if (selectedBasis === "accrual") {
        detail_datasets.push(
          getDetailDataSets(
            chartElementConfig.other_flow_tax_total,
            chartData.rows
              .find((row) => row.label === "Other Flows")
              ?.components?.find((component) => component.label === "Tax Flow")
              ?.values || [],
            "",
            0,
          ),
        );
      }

      // remove items that are all-zero
      const datasets_filtered = detail_datasets.filter((dataset) => {
        return dataset.data.some(
          (value) => Number(value) > 0 || Number(value) < 0,
        ); // < 0 , > 0  helps avoid null columns.. TODO: better way to do this?
      });

      const new_datasets = [datasets_filtered].flat().map((dataset) => {
        return {
          label: dataset.element_config.label + (dataset.suffix || ""),
          data: dataset.data,
          order:
            dataset.type && dataset.type === "line" ? 1 : 1 + dataset.order,
          type: dataset.type,
          // pointStyle: dataset.type && dataset.type === "line" ? "line" : "rect", // Use 'line' to hide dots
          // pointRadius: 0, // hide dots
          borderWidth: 2,
          borderColor: dataset.color,
          backgroundColor: dataset.color,
          yAxisID: dataset.yAxisID,
        };
      });

      return new_datasets as ChartDataset<"bar" | "line", number[]>[];
    };

    const detailChart = new ChartJS(
      detailChart2DRef as CanvasRenderingContext2D,
      {
        type: "bar",
        data: {
          labels,
          datasets: getDataSets(chartData, chartElementConfig).map(
            (dataset) => ({ ...dataset, hidden: hideDatasets }),
          ),
        },

        options: {
          aspectRatio: 2.75,
          responsive: true,
          plugins: {
            legend: {
              position: "bottom",
              reverse: false,
              align: "center",
              labels: {
                boxWidth: 20,
                // usePointStyle: true,
              },
              onClick: handleLegendOnClick,
            },
            tooltip: {
              callbacks: {
                label: (ctx) => {
                  if (ctx.dataset.yAxisID === "y_dollars") {
                    const formatted_value = ctx.parsed.y.toLocaleString(
                      "en-US",
                      {
                        style: "currency",
                        currency: "USD",
                        maximumFractionDigits: 0,
                      },
                    );
                    return formatted_value.startsWith("-")
                      ? `${ctx.dataset.label} -${formatted_value.substring(1)}`
                      : `${ctx.dataset.label} ${formatted_value}`;
                  }
                  return `${ctx.dataset.label} ${
                    ctx.parsed.y.toLocaleString("en-US", {
                      style: "decimal",
                      maximumFractionDigits: 0,
                    }) + " MWh"
                  }`;
                },
              },
            },
          },
          scales: {
            x: {
              grid: {
                display: false,
              },
              type: "category",
              stacked: true,
              max: 600, // TODO: hard to read with these many, maybe always roll up, not sure.
              ticks: {
                autoSkip: true,
                maxTicksLimit: 15,
              },
            },
            y_dollars: {
              title: {
                display: true,
                text: "Dollars ($)",
              },
              grid: {
                display: false,
              },
              position: "left",
              stacked: true,
              display: true,
              ticks: {
                callback: (value) => {
                  // $###,###.##
                  const formatted_value = value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    maximumFractionDigits: 0,
                  });
                  return formatted_value.startsWith("-")
                    ? `-${formatted_value.substring(1)}`
                    : formatted_value;
                },
              },
            },
            y_mwh: {
              display: !hideMwAxis,
              title: {
                display: true,
                text: "Megawatt Hour (MWh)",
              },
              grid: {
                display: false,
              },
              position: "right",
              stacked: false,
              ticks: {
                // ###,###
                callback: (value) => {
                  return (
                    value.toLocaleString("en-US", {
                      style: "decimal",
                    }) + " MWh"
                  );
                },
              },
            },
          },
        },
      },
    );
    return () => {
      summaryChart.destroy();
      detailChart.destroy();
    };
  }, [
    chartData,
    chartDensity,
    chartElementConfig,
    width,
    hideDatasets,
    hideMwAxis,
  ]);

  const toggleDatasets = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    setHideDatasets(event.target.checked);
  };

  const densityToggle = (
    <ToggleButtonGroup
      value={chartDensity}
      exclusive
      onChange={handleDensityChange}
      size="small"
      sx={{
        "& .MuiToggleButton-root": {
          color: externalLightTheme.color.secondary,
          "&.Mui-selected": {
            color: externalLightTheme.color.secondary,
          },
          padding: 0.8,
        },
      }}
    >
      <ToggleButton value="summary" size="small" sx={{ textTransform: "none" }}>
        Summary
      </ToggleButton>
      <ToggleButton value="detail" size="small" sx={{ textTransform: "none" }}>
        Detail
      </ToggleButton>
    </ToggleButtonGroup>
  );

  const ToggleAction = (
    <CheckboxInput
      label="Hide All"
      checked={hideDatasets}
      onChange={toggleDatasets}
    />
  );

  return (
    <Box className={cn("my-4")}>
      {chartDensity === "detail" ? (
        <ChartWrapper
          title="Pro Forma"
          primaryButton={primaryButton}
          secondaryButton={ToggleAction}
          displayToggle={showOnlySummary ? undefined : densityToggle}
        >
          <canvas id="detailChart" ref={detailChartRef} />
        </ChartWrapper>
      ) : chartDensity === "summary" ? (
        <ChartWrapper
          title="Pro Forma"
          primaryButton={primaryButton}
          secondaryButton={ToggleAction}
          displayToggle={showOnlySummary ? undefined : densityToggle}
        >
          <canvas id="summaryChart" ref={summaryChartRef} />
        </ChartWrapper>
      ) : null}
    </Box>
  );
}

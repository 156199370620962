import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DownloadIcon from "@mui/icons-material/Download";
import {
  DataGridPro,
  DataGridProProps,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";

import IconButton from "../icon-button";
import { IReportTable } from "../../interfaces";
import { cn, generateReportTableV2Data } from "../../utils/helpers";

interface IProps {
  data: IReportTable;
  groupExpandedDepth?: number;
  dataPw?: string;
  download?: () => Promise<void>;
  heightInPixels?: number;
  title?: string;
}

const ReportTableV2 = ({
  data,
  groupExpandedDepth = 0,
  dataPw,
  download,
  heightInPixels = 500,
  title,
}: IProps): JSX.Element => {
  const { rows, columns } = generateReportTableV2Data(data);

  const getTreeDataPath: DataGridProProps["getTreeDataPath"] = (row) => {
    return row.hierarchy;
  };

  const renderCustomToolbar = () => {
    if (!download) {
      return null;
    }

    return (
      <GridToolbarContainer>
        <div className={cn("flex justify-between w-full m-2")}>
          <Typography className="content-center" variant="h6" fontWeight="bold">
            {title}
          </Typography>
          <IconButton canOpenUpgrade onClick={download}>
            <DownloadIcon />
          </IconButton>
        </div>
      </GridToolbarContainer>
    );
  };

  const calculatedHeight = React.useMemo(() => {
    // Only calculate the height of the rows that are not nested
    const totalRowsHeightInPixels =
      rows.filter((row) => row.hierarchy.length === 1).length * 42 + 109; // 42 is the row height, 109 is the (grid toolbar height + column header height)

    return totalRowsHeightInPixels > heightInPixels
      ? `${heightInPixels}px`
      : "auto";
  }, [rows, heightInPixels]);

  return (
    <Box style={{ height: calculatedHeight }}>
      {rows.length ? (
        <DataGridPro
          columns={columns}
          rows={rows}
          density="compact"
          getTreeDataPath={getTreeDataPath}
          pinnedColumns={{ left: ["__tree_data_group__", "Total"] }}
          classes={{ columnHeaderTitle: cn("!font-bold text-[15px]") }}
          slots={{ toolbar: renderCustomToolbar }}
          columnHeaderHeight={70}
          rowHeight={60}
          groupingColDef={{
            headerName: "Name",
            minWidth: 300,
            hideDescendantCount: true,
          }}
          getCellClassName={(params) => {
            return params.colDef.field === "Total" ? cn("font-bold") : "";
          }}
          isGroupExpandedByDefault={(group) => {
            return group.depth === groupExpandedDepth;
          }}
          treeData
          hideFooter
          disableColumnMenu
          disableAutosize
          disableColumnResize
          disableColumnReorder
        />
      ) : (
        <Box className={cn("h-96 content-center text-center")}>
          <Typography
            variant="body1"
            className={cn("mt-[20px] p-4 text-[#666] ")}
            data-pw={dataPw}
          >
            There is no data in this report.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default React.memo(ReportTableV2);
